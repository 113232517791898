import { Link } from 'react-router-dom';

import { AuthContext } from 'context/authContext';

import { postCounter } from 'api/counters';
//UTILS
import config from 'utils/constants';
import { isFoundByKey } from 'utils/functions';

import { CheckIcon, BellIcon } from '@heroicons/react/solid';
//COMPONENTS
import Moment from 'components/common/Moment';

//CONTAINERS
import Avatar from 'containers/profils/Avatar';
import { useEffect, useState, useContext } from 'react';

//HOOOKS
import usePostCounter from 'hooks/usePostCounter';

const sizes = {
    avatar: '?w=120&h=120&c=true&q=80',
};

function CardProfilRoom({ user, room, isAnonymous, hiddenDate, canFollow, refetch, listingQueryKeys }) {
    const [authState] = useContext(AuthContext);
    const [dateIsHidden, setDateIsHidden] = useState(false);

    const { postCounterMutate } = usePostCounter();
    const [isSubscribed, setIsSubscribed] = useState(false);

    useEffect(() => {
        if (hiddenDate === true) {
            setDateIsHidden(true);
        } else {
            setDateIsHidden(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const subscribed = isFoundByKey(user?.counters, 'user', authState?.me?.id) && isFoundByKey(user?.counters, 'type', 'subscription');
        setIsSubscribed(subscribed);
    }, [authState?.me?.id, user?.counters]);

    const handleSubscribeToggle = () => {
        if (authState.isLogged) {
            //console.log(listingQueryKey);
            if (listingQueryKeys !== null || listingQueryKeys !== undefined) {
                postCounterMutate({
                    to: user?.id || user?._id,
                    type: 'subscription',
                    isListing: true,
                    listingQueryKeys: listingQueryKeys,
                });
                setIsSubscribed((prevState) => !prevState);
            } else {
                postCounter({ type: 'subscription', to: user?.id || user?._id })
                    .then((res) => {
                        setIsSubscribed((prevState) => !prevState);
                        refetch();
                    })
                    .catch((error) => {
                        console.error('Erreur lors de la souscription :', error);
                    });
            }

            //setIsSubscribed((prevState) => !prevState);
        } else {
            document.getElementById('button-login')?.click();
        }
    };

    /*  function handleSubscribe() {
        if (authState.isLogged) {
            postCounter({ type: 'subscription', to: user?._id }).then((res) => {
                refetch();
            });
        } else document.getElementById('button-login')?.click();
    } */

    return (
        <>
            <div className="relative w-full my-2">
                {isAnonymous !== true ? (
                    <div className="flex flex-row items-center w-full relative">
                        <Avatar user={user} className="flex-none relative w-20 mr-4" />
                        <Link
                            to={'/profil/' + user?.profile?.slug}
                            title={`Profil de ${user?.profile?.toSlug ? user?.profile?.toSlug : user?.profile?.pseudo}`}
                        >
                            <div className="w-auto grow flex flex-wrap flex-col">
                                <span className="text-sm md:text-l font-bold text-purpleSkills hover:text-blueMain">
                                    {user?.profile?.toSlug ? user?.profile?.toSlug : user?.profile?.pseudo}
                                </span>
                                <span className="text-sm font-normal text-graySkills">{user?.profile?.status}</span>
                                {room && dateIsHidden === false && (
                                    <span className="text-graySkills font-normal text-sm">
                                        <Moment date={room?.createdAt} fromNow />
                                    </span>
                                )}
                            </div>
                        </Link>
                        {authState?.me?.id !== user?.id && canFollow && (
                            <>
                                {user?.profile?.slug !== authState?.me?.profile?.slug && (
                                    <div className="absolute right-0">
                                        {/* <button
                                            onClick={() => handleSubscribe()}
                                            className={`h-8 flex gap-2 ${
                                                isFoundByKey(user?.counters, 'user', authState?.me?.id) &&
                                                isFoundByKey(user?.counters, 'type', 'subscription') &&
                                                authState.isLogged
                                                    ? 'linkCard red active'
                                                    : 'linkCard-purpleSkills'
                                            }`}
                                        >
                                            {isFoundByKey(user?.counters, 'user', authState?.me?.id) &&
                                            isFoundByKey(user?.counters, 'type', 'subscription') &&
                                            authState.isLogged ? (
                                                <>
                                                    <CheckIcon className="h-4 w-4" />
                                                    <span>Suivi</span>
                                                </>
                                            ) : (
                                                <>
                                                    <BellIcon className="h-4 w-4" />
                                                    <span>Suivre</span>
                                                </>
                                            )}
                                        </button> */}
                                        <button
                                            onClick={(e) => {
                                                e?.preventDefault();
                                                e?.stopPropagation();
                                                handleSubscribeToggle();
                                            }}
                                            className={`h-8 flex gap-2 ${isSubscribed ? 'linkCard red active' : 'linkCard-purpleSkills'}`}
                                        >
                                            {isSubscribed ? (
                                                <>
                                                    <CheckIcon className="h-4 w-4" />
                                                    <span>Suivi</span>
                                                </>
                                            ) : (
                                                <>
                                                    <BellIcon className="h-4 w-4" />
                                                    <span>Suivre</span>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ) : (
                    <div className="flex flex-row w-full relative">
                        <div className="flex-none relative w-14 h-auto mr-2">
                            <img className="rounded-full" src={`${config.publicDir}profil_anonyme.png` + sizes.avatar} alt={''} />
                        </div>
                        <div className="w-auto grow flex flex-wrap flex-col justify-center">
                            <span className="text-sm md:text-l font-bold text-purpleSkills hover:text-blueMain">Utilisateur anonyme</span>
                            {room && dateIsHidden === false && (
                                <span className="text-graySkills font-normal text-sm">
                                    <Moment date={room?.createdAt} fromNow />
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default CardProfilRoom;
