import { useEffect } from 'react';

//LIBS
import { createPortal } from 'react-dom';

//UTILS
import { isMobile } from 'utils/functions';

//STYLE
import 'components/common/modal.css';

//ICONS
import { XCircleIcon, ArrowCircleLeftIcon, ArrowCircleRightIcon } from '@heroicons/react/outline';

//COMPONENTS
import Button from 'components/common/Button';

function Modal(props) {
    const handleClickOverlay = () => {
        props?.onClose?.onClick();
    };

    useEffect(() => {
        if (props.open) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
    }, [props.open]);

    if (!props.open) return null;

    return createPortal(
        <div
            onClick={(e) => {
                if (props.onCloseOverlay) {
                    handleClickOverlay();
                }
            }}
            className={`modal ${props?.typeModal === 'ai' ? '' : ''} ${props.overflow ? 'overflow-y-auto py-12' : 'items-center'}`}
        >
            <div
                onClick={(e) => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                }}
                className={`${props.transparentBg ? 'modal-content-transparent' : props.aiBg ? 'modal-content-ai' : 'modal-content'} ${
                    props.noMaxWidth ? '' : 'modal-max-width'
                } ${isMobile() && props.overflow && 'overflow-y-auto'} ${!props.fullScreen ? '' : 'fullscreen'} ${
                    !props.modalWidth ? 'w-full md:w-8/12' : props.modalWidth
                }`}
            >
                {props.title && props?.type !== 'chatAi' && (
                    <div className={`${props?.noBorder ? 'modal-title-noBorder' : 'modal-title'} ${props?.titleCenter && "text-center"}`}>
                        <h2>{props.title && props.title}</h2>
                    </div>
                )}
                <section
                    className={`${props.transparentBg ? 'modal-main-transparent' : props?.noBorderNeed ? 'modal-main-noBorder' : 'modal-main'} ${!props?.modalHeight ? 'h-auto' : props?.modalHeight} ${
                        !props.title && 'rounded-2xl'
                    } ${props.fullScreen ? '!p-0' : props?.noBorderNeed ? 'px-4' : 'p-2 md:p-8'}`}
                >
                    {props.children}
                </section>
                {props.onClose?.onClick && (
                    <div className={`modal-close flex flex-row-reverse gap-1 ${props.overflow ? 'right-0' : '-right-4'}`}>
                        {!props?.isRegisterForm && (
                            <button className="h-10 w-10" onClick={props.onClose.onClick}>
                                <XCircleIcon className="h-8 w-8 block text-white" />
                            </button>
                        )}

                        {props.navigation && (
                            <>
                                <button className=" h-10 w-10" onClick={props.next}>
                                    <ArrowCircleRightIcon className="h-8 w-8 block text-white" />
                                </button>

                                <button className=" h-10 w-10" onClick={props.prev}>
                                    <ArrowCircleLeftIcon className="h-8 w-8 block text-white" />
                                </button>
                            </>
                        )}
                    </div>
                )}

                {(props.onClose || props.onConfirm) && !props.navigation && !props.noActions && !props?.isRegisterForm && (
                    <div className={`modal-actions ${!props.noBorder && 'border-t'}`}>
                        {props.onClose && !props.onClose.noButton && (
                            <Button
                                label={props.onClose.label ? props.onClose.label : 'Annuler'}
                                css="text-graySkills bg-white m-0 mr-2 button-border-gray rounded-full"
                                onClick={props.onClose.onClick}
                            />
                        )}
                        {props.onConfirm &&
                            (props?.type === 'chatAi' ? (
                                <button
                                    className="w-auto flex p-2 px-4 bg-black text-white font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]"
                                    disabled={props.onConfirm.disabled}
                                    onClick={() => {
                                        !props.onConfirm.disabled && props.onConfirm.onClick();
                                    }}
                                >
                                    <span>Confirmer</span>
                                </button>
                            ) : (
                                <Button
                                    label={props.onConfirm.label ? props.onConfirm.label : 'Valider'}
                                    css={`
                                        ${props.typeModal === 'ai' ? 'bg-[#E348FF]' : 'bg-blueMain'} ${props?.onConfirm?.class &&
                                        props?.onConfirm.class} text-white m-0 rounded-full hover:text-blueMain hover:bg-white
                                    `}
                                    disabled={props.onConfirm.disabled}
                                    onClick={() => {
                                        !props.onConfirm.disabled && props.onConfirm.onClick();
                                    }}
                                />
                            ))}
                    </div>
                )}
            </div>
        </div>,
        document.body
    );
}
export default Modal;
