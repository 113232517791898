import { Fragment, useEffect, useRef, useState } from 'react';

//API
//import { getServicesP } from 'api/services';
import { useInfiniteQuery } from 'react-query';

//COMPONENTS
import SectionTitle from 'components/common/SectionTitle';
//import ServicesTags from 'components/ServicesTags';
//import Subjects from 'components/common/nav/Subjects';
import SEO from 'components/Seo';

//CONTAINERS
import CardFrontService from 'containers/services/CardFrontService';

//HOOKS
import SideNav from '../components/common/nav/SideNav';
import UseIntersectionObserver from 'hooks/useIntersectionObserver';

//LAYOUT
import FullLayout from 'pages/layouts/Full';

//STYLES
import 'App.css';

//UTILS
import { getServicesPByTags } from '../api/services';
import { isMobile } from 'utils/functions';
import { PageContentFadingAnimation } from 'utils/animations';

function ListingServices(props) {
    const [start, setStart] = useState(false);
    let tagSelected = 'all';
    const page = useRef(0);
    const hasNextPage = useRef(false);

    const { data, fetchNextPage, isFetchingNextPage, refetch } = useInfiniteQuery(
        'infiniteServices',
        ({ pageParam = 0 }) => getServicesPByTags(`?p=${pageParam}`, tagSelected),
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.hasNextPage) {
                    hasNextPage.current = lastPage.hasNextPage;

                    return (page.current = lastPage.page);
                } else {
                    hasNextPage.current = false;
                    page.current = 0;
                }
                return;
            },
            enabled: !!start,
        }
    );

    //REFS
    const loadMoreButtonRef = useRef();

    //HOOKS
    UseIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: (isVisible) => {
            if (isVisible) fetchNextPage();
        },
        enabled: !!hasNextPage.current,
    });

    useEffect(() => {
        page.current = 0;
        setStart(true);
        //eslint-disable-next-line
    }, []);

    /* function handleTagsSort(tag) {
        tagSelected = tag;
        refetch();
    } */

    return (
        <>
            <SEO
                title={"Trouvez les meilleurs services professionnels sur Skillsmarket Marketplace"}
                description={"Parcourez les offres variées de Skillsmarket Marketplace et engagez les meilleurs professionnels pour répondre à vos besoins spécifiques. Découvrez de nouvelles opportunités commerciales et développez votre activité grâce à notre plateforme."}
                type="summary_large_image"
            />
            <FullLayout>
                <div className="container">
                    <div className="w-full flex flex-col sm:flex-row sm:gap-8 pb-4">
                        {!isMobile() && (
                            <div className="hidden sm:block sm:w-[12rem]">
                                <SideNav />
                            </div>
                        )}
                        <div className="w-full h-full">
                            <main>
                                <PageContentFadingAnimation duration={250}>
                                    <SectionTitle>
                                        <h1 className="h1 mx-0 my-2 mt-4 mb-2">
                                            <span className="text-purpleSkills">Marketplace</span>
                                        </h1>
                                    </SectionTitle>

                                    {/*
                                    <div className="my-2">
                                        <ServicesTags onClick={handleTagsSort} />
                                    </div>
                                    */}
                                    <div className="w-full m-auto xl:container mb-4">
                                        {/* <div className="flex flex-row flex-wrap justify-start w-12/12 gap-4"> */}
                                        <div
                                            className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 auto-cols-max gap-x-2 sm:gap-4"
                                            style={{ maxWidth: '100%' }}
                                        >
                                            <>
                                                {data?.pages && (
                                                    <>
                                                        {data?.pages?.map((pages, index) => (
                                                            <Fragment key={index}>
                                                                {pages.docs.map((service, j) => (
                                                                    <div className="mt-0 sm:pb-0 card card_profile w-full" key={j}>
                                                                        <CardFrontService service={service} refetch={refetch} />
                                                                    </div>
                                                                ))}
                                                            </Fragment>
                                                        ))}
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                    {hasNextPage.current && (
                                        <div className="text-center mt-4">
                                            <button
                                                ref={loadMoreButtonRef}
                                                className="button button-secondary"
                                                onClick={fetchNextPage}
                                                disabled={!hasNextPage.current || isFetchingNextPage}
                                            >
                                                {isFetchingNextPage ? 'Chargement...' : 'Voir plus de services'}
                                            </button>
                                        </div>
                                    )}
                                </PageContentFadingAnimation>
                            </main>
                        </div>
                    </div>
                </div>
            </FullLayout>
        </>
    );
}

export default ListingServices;
