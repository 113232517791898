import { useEffect, useCallback, useContext, useState } from 'react';
import { ReactTyped } from 'react-typed';

//API
import { getSubscribers } from 'api/users';
import { useQuery } from 'react-query';

//COMPONENTS
import SectionTitle from 'components/common/SectionTitle';
import SideNav from 'components/common/nav/SideNav';
import Tabs from 'components/tabs/Tabs';
import SEO from 'components/Seo';

//CONTAINERS
// import CardProfil from 'containers/profils/CardProfil';
import CardProfilSearch from 'containers/profils/CardProfilSearch';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';
//HOOKS
import userInfiniteSkillers from 'hooks/useInfiniteSkillers';
import useInfiniteMatchMaking from '../hooks/useInfiniteMatchMaking';
//LAYOUT
import ContainerLayout from 'pages/layouts/Container';

//LIBS
import { animated, useSpring } from 'react-spring';

//STYLES
import 'App.css';

//UTILS
import { isMobile } from 'utils/functions';
import { PageContentFadingAnimation } from 'utils/animations';

function Listing(props) {
    //CONTEXTS
    const [authState] = useContext(AuthContext);
    const [, formContextDispatch] = useContext(FormContext);
    const [fetchedTabs, setFetchedTabs] = useState({});
    const isTabFetched = (tabKey) => !!fetchedTabs[tabKey];

    const topSkillers = userInfiniteSkillers('top', true, false);
    const notLoggedSkillers = userInfiniteSkillers('top', false, false, true);
    const interestsSkillers = userInfiniteSkillers('interests', false, true);
    //const interestsSkillers = userInfinitSkillers('interests', isTabFetched('tab_interestsSkillers'));
    const infiniteNeedsMatchMaking = useInfiniteMatchMaking(true, false, false);
    const infiniteUsersMatchMaking = useInfiniteMatchMaking(false, true, false);
    const infiniteNeedsReceived = useInfiniteMatchMaking(false, false, true);

    const handleTabChange = (index) => {
        if (index && !fetchedTabs[index]) {
            setFetchedTabs((prev) => ({ ...prev, [index]: true }));
        }
    };

    //API
    const subscribers = useQuery(
        ['subscribers'],
        () => {
            return getSubscribers();
        },
        { enabled: authState.isLogged && isTabFetched('tab_mesAbonnés') }
    );

    const handleAddNeed = useCallback(() => {
        formContextDispatch({ type: 'addNeed' });
        /*   infiniteMatchMaking?.refetch(); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formContextDispatch]);

    const SwipeTabAnimation = useSpring({
        from: { opacity: 0, transform: 'translateX(100%)' },
        to: { opacity: 1, transform: 'translateX(0%)' },
        config: { duration: 250 },
    });

    //const tabInterestsSkillers = <>{interestsSkillers}</>;

    const tabTopSkillers = (
        <>
            <div className="flex justify-center sticky w-full z-10 cursor-pointer flex-col items-center mt-2 py-2 sm:py-6 rounded-3xl">
                <div className="relative mb-8 flex flex-col justify-center bg-slate-900 overflow-hidden">
                    <div className="text-center">
                        <div className="font-extrabold text-2xl md:text-3xl [text-wrap:balance] bg-clip-text text-purpleSkills bg-gradient-to-r from-slate-200/60 to-50% to-slate-200">
                            <span className="text-indigo-500 inline-flex flex-col h-[calc(theme(fontSize.2xl)*theme(lineHeight.tight))] md:h-[calc(theme(fontSize.3xl)*theme(lineHeight.tight))] overflow-hidden">
                                <ul className="block animate-text-slide-6 sm:animate-text-slide-6 leading-tight [&_li]:block text-center h-auto">
                                    <li>Montrez ce que vous savez faire</li>
                                    <li>Obtenez le job de vos rêves</li>
                                    {isMobile() ? (
                                        <li>Trouvez votre stage</li>
                                    ) : (
                                        <li>Trouvez votre alternance / stage</li>
                                    )}
                                    <li>Recrutez vos perles rares</li>
                                    <li>Trouvez vos clients</li>
                                    <li>Postez vos appels d'offres</li>
                                    <li aria-hidden="true">Proposez vos services</li>
                                </ul>
                            </span>
                        </div>
                    </div>
                    <p className="text-center mt-2 sm:mt-1 font-bold text-graySkills">
                        Postez vos besoins pour être connecté à ceux qui pourront y répondre !
                    </p>
                </div>
                <div className="w-[95%] sm:w-[80%]">
                    <button
                        className="shadow-blueMain/60 w-full"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            authState.isLogged ? handleAddNeed(e) : document.getElementById('button-login')?.click();
                        }}
                    >
                        {/* <PlusIcon className="w-6 h-6" /> */}
                        <span className="input_flat bg-white w-full text-center text-xs sm:text-base shadow-sm shadow-lightBlueSkills hover:shadow-xl hover:shadow-lightBlueSkills transition-shadow duration-400">
                            Je cherche{' '}
                            <ReactTyped
                                strings={[
                                    "un travail à Dubai pour m'expatrier",
                                    'un stage de développeur web à Pau',
                                    '3 serveurs pour une ouverture à Lille',
                                    'à faire connaitre mon agence de voyage',
                                    'un formateur en comptabilité à Bruxelles',
                                    'à lever des fonds',
                                ]}
                                typeSpeed={50}
                                backSpeed={20}
                                loop
                            />
                        </span>
                    </button>
                </div>
            </div>
            {notLoggedSkillers}
        </>
    );

    /* const tabSkillers = (
        <>
            {interestsSkillers}
            {topSkillers}
        </>
    ); */

    const tabSubscribers = (
        <>
            {subscribers?.data?.length > 0 ? (
                <animated.div style={SwipeTabAnimation} className="card_profile sm:mx-0">
                    <SectionTitle>
                        <h1 className="hidden sm:block h2 mx-0 my-2 mt-4 mb-2">
                            <span className="text-mainText">Mes abonnés</span>
                        </h1>
                    </SectionTitle>

                    {subscribers?.data?.map((counter, index) => (
                        <div key={index} className="my-2">
                            <CardProfilSearch user={counter.user} refetch={subscribers.refetch} />
                        </div>
                    ))}
                </animated.div>
            ) : (
                <animated.div style={SwipeTabAnimation} className="text-center text-sm text-gray-500 mt-4 mx-4 sm:mx-0">
                    <p>Vous n'avez pas d'abonné pour le moment</p>
                </animated.div>
            )}
        </>
    );

    const tabMatchMaking = (
        <>
            <div>
                <div className="flex justify-center sticky w-full z-10 cursor-pointer flex-col items-center mt-2 py-2 sm:py-6 rounded-3xl">
                    <div className="w-full relative mb-8 flex flex-col justify-center bg-slate-900 overflow-hidden">
                        <div className="w-full mx-auto ">
                            <div className="text-center">
                                <div className="font-extrabold text-2xl md:text-3xl [text-wrap:balance] bg-clip-text text-purpleSkills bg-gradient-to-r from-slate-200/60 to-50% to-slate-200">
                                    <span className="text-indigo-500 inline-flex flex-col h-[calc(theme(fontSize.2xl)*theme(lineHeight.tight))] md:h-[calc(theme(fontSize.3xl)*theme(lineHeight.tight))] overflow-hidden">
                                        <ul className="block animate-text-slide-6 sm:animate-text-slide-6 leading-tight [&_li]:block text-center h-auto">
                                            <li>Montrez ce que vous savez faire</li>
                                            <li>Obtenez le job de vos rêves</li>
                                            {isMobile() ? (
                                                <li>Trouvez votre stage</li>
                                            ) : (
                                                <li>Trouvez votre alternance / stage</li>
                                            )}
                                            <li>Recrutez vos perles rares</li>
                                            <li>Trouvez vos clients</li>
                                            <li>Postez vos appels d'offres</li>
                                            <li aria-hidden="true">Proposez vos services</li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <p className="text-center mt-2 sm:mt-1 font-bold text-graySkills">
                            Postez vos besoins pour être connecté à ceux qui pourront y répondre !
                        </p>
                    </div>
                    <div className="w-[95%] sm:w-[80%]">
                        <button
                            className="shadow-blueMain/60 w-full"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                authState.isLogged ? handleAddNeed(e) : document.getElementById('button-login')?.click();
                            }}
                        >
                            {/* <PlusIcon className="w-6 h-6" /> */}
                            <span className="input_flat bg-white w-full text-center text-xs sm:text-base shadow-sm shadow-lightBlueSkills hover:shadow-xl hover:shadow-lightBlueSkills transition-shadow duration-400">
                                Je cherche{' '}
                                <ReactTyped
                                    strings={[
                                        "un travail à Dubai pour m'expatrier",
                                        'un stage de développeur web à Pau',
                                        '3 serveurs pour une ouverture à Lille',
                                        'à faire connaitre mon agence de voyage',
                                        'un formateur en comptabilité à Bruxelles',
                                        'à lever des fonds',
                                    ]}
                                    typeSpeed={50}
                                    backSpeed={20}
                                    loop
                                />
                            </span>
                        </button>
                    </div>
                </div>
                <div className="mt-4">{infiniteNeedsReceived}</div>
                <div>{infiniteNeedsMatchMaking}</div>
                <div>{infiniteUsersMatchMaking}</div>
                <div className="">{interestsSkillers}</div>
                <div className="">{topSkillers}</div>
            </div>
        </>
    );

    const tabs = [];

    tabs['desktop'] = [
        { label: 'Matching', key: 'tab_matchMaking', content: tabMatchMaking, visible: authState.isLogged },
        //{ label: 'Suggestions', key: 'tab_interestsSkillers', content: tabSkillers, visible: authState.isLogged },
        //{ label: 'Suggestions', key: 'tab_interestsSkillers', content: tabInterestsSkillers, visible: interestsSkillers !== null },
        { label: 'Abonnés', key: 'tab_mesAbonnés', content: tabSubscribers, visible: authState.isLogged },
    ];

    tabs['mobile'] = [
        { label: 'Matching', key: 'tab_matchMaking', content: tabMatchMaking, visible: authState.isLogged },
        //{ label: 'Suggestions', key: 'tab_interestsSkillers', content: tabSkillers, visible: authState.isLogged },
        //{ label: 'Suggestions', key: 'tab_interestsSkillers', content: tabInterestsSkillers, visible: interestsSkillers !== null },
        //{ label: 'Top Skillers', key: 'tab_topSkillers', content: tabTopSkillers },
        { label: 'Abonnés', key: 'tab_mesAbonnés', content: tabSubscribers, visible: authState.isLogged },
    ];

    return (
        <>
            <SEO
                title={"Exploitez tout votre potentiel professionnel grâce à la puissance de l'IA sur Skillsmarket"}
                description={
                    "Découvrez comment l'IA révolutionnaire de Skillsmarket peut vous aider à libérer tout votre potentiel professionnel. Trouvez les opportunités correspondant à vos compétences et atteignez de nouveaux sommets grâce à la combinaison parfaite entre vous et l'IA."
                }
                type="summary_large_image"
            />
            <ContainerLayout>
                <PageContentFadingAnimation duration={250} className="container">
                    <div className="sm:grid sm:grid-cols-12 sm:gap-8 pb-4">
                        <div className="hidden sm:block sm:col-span-3">
                            <SideNav />
                        </div>
                        <div className="sm:col-span-9 lg:col-span-8">
                            {!isMobile() ? (
                                <>{authState.isLogged ? <Tabs tabs={tabs?.desktop} onTabChange={handleTabChange} /> : <>{tabTopSkillers}</>}</>
                            ) : (
                                <>{authState.isLogged ? <Tabs tabs={tabs?.mobile} onTabChange={handleTabChange} /> : <>{tabTopSkillers}</>}</>
                            )}
                        </div>
                    </div>
                </PageContentFadingAnimation>
            </ContainerLayout>
        </>
    );
}

export default Listing;
