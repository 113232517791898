import { useState, useEffect, useContext, useRef } from 'react';

import config from 'utils/constants';

import { AuthContext } from 'context/authContext';

//LIBS
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

//API
import { postAnswerTo, postAnswerToAnswer } from 'api/forum';
import { postAnswerTo as postAnswerToShoot, postAnswerToAnswer as postAnswerToAnswerShoot } from 'api/shoots';
import { getForumResponse } from 'api/clone';
//FORMS
import schema from 'components/forms/yup/answerTo';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import BuyCloneAi from 'components/common/BuyCloneAi';

//ICONS
import { PaperAirplaneIcon } from '@heroicons/react/solid';

//CONTAINERS
import UserLink from 'containers/profils/UserLink';

function AnswerTo(props) {
    const [authState] = useContext(AuthContext);

    const [openConfirmSubmit, setOpenConfirmSubmit] = useState(false);
    const [openUserLink, setOpenUserLink] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [aiInstruction, setAiInstruction] = useState(false);
    const [aiInstructionText, setAiInstructionText] = useState('');

    const answerRef = useRef(null);
    const userLinkRef = useRef(null);

    const [isMessageCloneExceeded, setMessageCloneExceeded] = useState(false);
    const [messageCloneData, setMessageCloneData] = useState(null);

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { ref, ...rest } = register('answerTo');
    const answer = useWatch({ control, name: 'answerTo' });

    function submitAnswer() {
        if (!openUserLink) handleSubmit(onSubmit)();
    }

    function onSubmit() {
        if (errors.length > 0) return false;

        setOpenConfirmSubmit(true);
    }

    function handleConfirm() {
        setOpenConfirmSubmit(false);
        document.getElementById(`buttonAnswer_${props.answer._id}`).classList.remove('hidden');

        const data = getValues();
        data.answerId = props.answer._id;

        let post = null;
        switch (props.type) {
            case 'answerRoom':
                post = postAnswerTo(data);
                break;
            case 'answerShoot':
                data.userTo = props.answer.user.id;
                post = postAnswerToShoot(data);
                break;
            case 'answerToRoom':
                data.parentAnswer = props.parentAnswer._id;
                post = postAnswerToAnswer(data);
                break;
            case 'answerToShoot':
                data.parentAnswer = props.parentAnswer._id;
                data.userTo = props.answer.user.id;
                post = postAnswerToAnswerShoot(data);
                break;
            default:
                break;
        }
        post.then((res) => {
            if (res.status === 200) {
                toast('Votre réponse a bien été postée', { type: 'success' });
                reset();
                props.closeAnswersTo();
                props.refetch();
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    }

    function onKeyDown(e) {
        if (e.key === '@') {
            setOpenUserLink(true);
        }
    }

    function onCloseUserLink(user) {
        if (user !== undefined) {
            let newAnswer = answer ?? '';
            newAnswer += '@' + user?.profile?.slug + ' ';
            setValue('answerTo', newAnswer);
        }
        answerRef.current.focus();
        setOpenUserLink(false);
    }

    async function generateResponse() {
        setAiInstruction(false);
        const data = getValues();
        data.forumId = props.item._id;
        data.answerId = props.answer._id;
        data.userId = authState?.me?.id;
        data.instructions = aiInstructionText;
        try {
            setWaiting(true);
            const getResponse = await getForumResponse(data);
            if (getResponse.status === 200) {
                let response = getResponse.data;
                setWaiting(false);
                setValue('answerTo', response);
            } else if (getResponse.status === 201) {
                setWaiting(false);
                setMessageCloneData(getResponse.data);
                setMessageCloneExceeded(true);
            } else {
                toast('Une erreur est survenue réessayer plus tard', { type: 'danger' });
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast('Une erreur est survenue réessayer plus tard', { type: 'danger' });
        }
    }

    useEffect(() => {
        if (openUserLink) {
            userLinkRef.current.focus();
        }
    }, [openUserLink]);

    return (
        <>
            <Modal
                title="Confirmer votre réponse"
                open={openConfirmSubmit}
                onClose={{
                    onClick: () => {
                        setOpenConfirmSubmit(false);
                    },
                }}
                onConfirm={{
                    label: 'Valider',
                    onClick: handleConfirm,
                }}
            >
                <p>Voulez-vous vraiment poster cette réponse ?</p>
            </Modal>
            <Modal
                title="Votre clone AI répond pour vous :"
                open={aiInstruction}
                onClose={{
                    onClick: () => {
                        setAiInstruction(false);
                    },
                }}
                onConfirm={{
                    label: 'Générer',
                    onClick: generateResponse,
                }}
            >
                <textarea
                    rows="4"
                    className="w-full bg-lightGraySkills h-auto max-h-28 sm:text-sm card-item-question rounded-xl p-2"
                    style={{ height: 'auto' }}
                    placeholder={`Donnez lui vos instructions pour une réponse qui vous ressemble`}
                    onChange={(e) => {
                        setAiInstructionText(e.target.value);
                    }}
                >
                </textarea>
            </Modal>
            {isMessageCloneExceeded && (
                <BuyCloneAi state={isMessageCloneExceeded} setState={setMessageCloneExceeded} data={messageCloneData} />
            )}
            <form onSubmit={(e) => e.preventDefault()}>
                {errors && errors['answerTo'] && <span className={`mt-0 text-sm text-danger-400`}>{errors['answerTo'].message}</span>}

                <div className="flex flex-row w-full h-auto py-4">
                    <div className="w-auto h-auto flex-auto border hover:border-blueMain mr-2 sm:mr-4 rounded-xl bg-lightGraySkills">
                        <div className="flex flex-row">
                            <div className={`p-2 basis-full relative ${waiting && "h-28"}`}>
                                { waiting ? (
                                    <span className='waiting-animation text-purpleSkills'>
                                    </span>
                                ) : (
                                    <textarea
                                        ref={(e) => {
                                            ref(e);
                                            answerRef.current = e;
                                        }}
                                        name="answerTo"
                                        id="answerTo_textarea"
                                        rows="4"
                                        autoresize=""
                                        className="w-full bg-lightGraySkills h-auto max-h-28 sm:text-sm card-item-question"
                                        errors={errors}
                                        {...rest}
                                        style={{ height: 'auto' }}
                                        placeholder={`Tapez votre réponse`}
                                        onKeyDown={onKeyDown}
                                    >
                                        {getValues('answerTo')}
                                    </textarea>
                                )}
                                <UserLink ref={userLinkRef} open={openUserLink} onClose={(user) => onCloseUserLink(user)} />
                            </div>
                        </div>
                    </div>
                    {/* top-2 md:right-2 */}
                    <div className="h-auto flex flex-col w-fit">
                        <div className="hidden sm:block text-center w-fit h-auto">
                            <div className={`hidden relative md:flex flex-col h-10 w-fit ${authState.isLogged && "gap-2"}`}>
                                {authState.isLogged && authState?.me?.clone && (
                                    <Button
                                        onClick={() => setAiInstruction(true)}
                                        css="w-full p-2 bg-black text-white font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]"
                                        label={'Réponse AI'}
                                    />
                                )}
                                <Button
                                    onClick={submitAnswer}
                                    css="shadow-none border border-blueMain text-white bg-blueMain px-4 flex justify-center items-center rounded-full hover:border-blueMain hover:bg-white hover:text-blueMain transition-colors duration-400"
                                    label={'Envoyer'}
                                />
                            </div>
                        </div>
                        <div className="sm:hidden text-center">
                            <div className={`relative flex flex-col h-auto w-fit ${authState.isLogged && "gap-2"}`}>
                                {authState.isLogged && authState?.me?.clone && (
                                    <button
                                        type="submit"
                                        onClick={() => setAiInstruction(true)}
                                        className="w-auto text-purpleSkills rounded-full"
                                    >
                                        <img className="w-8 h-8" src={`${config.publicDir}logo_ai_switch2.svg`} alt="ai_logo" />
                                    </button>
                                )}
                                <Button
                                    onClick={submitAnswer}
                                    css="button-small shadow-none border border-blueMain rounded-full text-white p-2 bg-blueMain"
                                    label={<PaperAirplaneIcon className="w-4 h-4" />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            {/*  <form onSubmit={handleSubmit(onSubmit)}>
                <textarea name="answerTo" className="input resize-none py-4" errors={errors} {...register("answerTo")} rows="3" style={{ height: "auto" }} placeholder={`Votre réponse à ${props.answer?.user?.profile?.pseudo}`}>
                    {getValues("answerTo")}
                </textarea>

                {errors && errors["answerTo"] && <span className={`mt-0 text-sm text-danger-400`}>{errors["answerTo"].message}</span>}
                <div className="m-auto text-center">
                    <Button onClick={handleSubmit} css="button-secondary button-small m-auto w-auto inline-block" label={"Poster votre réponse"} />
                </div>
            </form> */}
        </>
    );
}

export default AnswerTo;
