import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

//API
import { putProfile, putProfilePublish } from 'api/users';

//COMPONENTS
import FieldExperiences from 'components/forms/fields/Experiences';
import FieldFiles from 'components/forms/fields/Files';
import FieldInput from 'components/forms/fields/input';
import FieldPermis from 'components/forms/fields/Permis';
import FieldQualifications from 'components/forms/fields/Qualifications';
import FieldHobbies from 'components/forms/fields/Hobbies';
import FieldSelect from 'components/forms/fields/Select';
import Header from 'components/common/Header';
import profileOptions from 'components/forms/options/profile';
import ProgressBar from 'components/common/ProgressBar';
import Tags from 'components/forms/fields/Tags';
//import Textarea from 'components/forms/fields/Textarea';

//CONFIG
import config from 'utils/constants';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';

//FORMS
import schema from 'components/forms/yup/profile';

//HOOKS
import useCurrentForm from 'hooks/useForm';

//LIBS
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//STYLES
import 'components/forms/forms.css';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';
import TextareaCustom from './fields/TextareaCustom';
import FieldLanguages from './fields/Languages';
import { PlayIcon } from '@heroicons/react/solid';

function FormProfil() {
    const [readyToWatch, setReadyToWatch] = useState(false);
    const [presentationInitialValue, setPresentationInitialValue] = useState('');
    const [objectifsInitialValue, setObjectifsInitialValue] = useState('');
    const [tagsInitialValue, setTagsInitialValue] = useState([]);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [coverUrl, setCoverUrl] = useState(null);
    const [presentationVideoInitialValue, setPresentationVideoInitialValue] = useState([]);
    const [medias, setMedias] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [permis, setPermis] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [hobbies, setHobbies] = useState([]);

    const [progressBar, setProgressBar] = useState({ active: false, value: 0 });

    //CONTEXTS
    const [authState, , refresh] = useContext(AuthContext);
    const [formContextState, formContextDispatch] = useContext(FormContext);

    //REFS
    const currentForm = useRef(null);
    const seeLink = useRef(null);

    //HOOKS
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        getValues,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //DISPATCH
    const dispatchAction = useCallback(() => {
        switch (formContextState.action) {
            case 'save':
                try {
                    currentForm.current.click();
                } catch (err) {
                    console.log(err);
                }
                break;
            case 'tooglePublish':
                togglePublish();
                break;
            default:
        }
        // eslint-disable-next-line
    }, [formContextState.action]);

    //EFFECTS
    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
        }
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        dispatchAction();
    }, [dispatchAction]);

    function extractTextFromHtml(htmlString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        return doc.body.textContent || '';
    }

    useEffect(() => {
        const me = authState.me;

        if (me) {
            seeLink.current = '/profil/' + me.profile?.slug;
            formContextDispatch({ type: 'publish', status: true });

            setAvatarUrl(me.profile?.avatar?.url ? config.serverUrl + me.profile?.avatar?.url : '');
            setCoverUrl(me.profile?.cover?.url ? config.serverUrl + me.profile?.cover?.url : '');
            setValue('display', me.profile?.display);
            setValue('society', me.profile?.society);
            // setValue('pseudo', me.profile?.pseudo);
            setValue('status', me.profile?.status);
            setValue('firstname', me.profile?.firstname);
            setValue('lastname', me.profile?.lastname);
            setValue('email', me.auth?.email.trim());
            setValue('phone', me.profile?.phone);
            setValue('address', me.profile?.address);
            setValue('point', me.location?.coordinates?.join(','));

            setValue('video', me.profile?.video);
            setValue('presentationVideo', me.profile?.presentationVideo);

            setValue('presentation', me.profile?.presentation);
            setValue('objectifs', me.profile?.objectifs);

            setPresentationInitialValue(extractTextFromHtml(me.profile?.presentation));
            setObjectifsInitialValue(me.profile?.objectifs);
            setTagsInitialValue(me.profile?.tags);

            setValue('linkWebsite', me.profile?.linkWebsite);
            setValue('linkYoutube', me.profile?.linkYoutube);
            setValue('linkInstagram', me.profile?.linkInstagram);
            setValue('linkTiktok', me.profile?.linkTiktok);
            setValue('linkSnapchat', me.profile?.linkSnapchat);
            setValue('linkLinkedin', me.profile?.linkLinkedin);
            setValue('linkTwitter', me.profile?.linkTwitter);

            setValue('price', me.visio?.price || 0);
            setValue('isFreeFirst', me.visio?.isFreeFirst || false);

            if (me.profile?.medias) {
                me.profile.medias.forEach((element, index) => {
                    me.profile.medias[index] = { ...element, url: config.serverUrl + element.url };
                });
                setMedias(me.profile?.medias);
            }

            if (me.profile?.experiences) {
                setExperiences(me.profile.experiences);
            }
            if (me.profile?.permis) {
                setPermis(me.profile.permis);
            }
            if (me.profile?.languages) {
                setLanguages(me.profile.languages);
            }
            if (me.profile?.qualifications) {
                setQualifications(me.profile.qualifications);
            }
            if (me.profile?.hobbies) {
                setHobbies(me.profile.hobbies);
            }

            setTimeout(() => {
                setReadyToWatch(true);
            }, 1000);
        }
        //eslint-disable-next-line
    }, [authState]);

    useEffect(() => {
        let updatedPresentationVideoArray = [];

        if (authState?.me?.profile?.presentationVideo) {
            let presentationVideo = authState?.me?.profile?.presentationVideo;

            const presentationVideoArray = Array.isArray(presentationVideo) ? presentationVideo : [presentationVideo];
            updatedPresentationVideoArray = presentationVideoArray.map((video) => ({
                ...video,
                url: config.serverUrl + video.url,
            }));

            setPresentationVideoInitialValue(updatedPresentationVideoArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.me?.profile?.presentationVideo]);

    useCurrentForm(readyToWatch, watch);

    const onSubmit = (data) => {
        //console.log(data);
        if (errors.length > 0) return false;

        data.display = data.society === '' ? 'realnames' : data.display;

        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = null;
                item = data[k];

                if (k === 'tags' || k === 'experiences' || k === 'permis' || k === 'qualifications' || k === 'languages' || k === 'hobbies') {
                    item = JSON.stringify(data[k]);
                }
                if (k === 'avatar' || k === 'cover' || k === 'medias' || k === 'presentationVideo') {
                    let files = data[k];
                    for (let i = 0; i < files.length; i++) {
                        fd.append(k, files[i]);
                    }
                } else fd.append(k, item);
            }
        }

        const put = putProfile(fd, function (e) {
            let progress = (e.loaded / e.total) * 100;
            formContextDispatch({ type: 'progress', status: progress });
            setProgressBar({ ...progressBar, active: true, value: progress });
        });

        put.then((res) => {
            if (res.status === 200) {
                toast('Profil mis à jour', { type: 'success' });
                refresh();

                setTimeout(() => {
                    formContextDispatch({ type: 'change', status: false });
                    setTimeout(() => {
                        formContextDispatch({ type: 'progress', status: 0 });
                    }, 200);
                }, 100);
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    };

    const togglePublish = useCallback(() => {
        if (errors.length > 0) return false;

        const put = putProfilePublish();
        put.then((res) => {
            if (res.status === 200) {
                if (res.data.publish) toast('Profil publié', { type: 'success' });
                else {
                    toast('Profil dépublié', { type: 'success' });
                }

                refresh();

                formContextDispatch({
                    type: 'publish',
                    status: res.data.publish,
                    seeLink: seeLink.current,
                });
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
        //eslint-disable-next-line
    }, [formContextDispatch]);

    //OPTIONS
    function dispatchOptionsActions(action, name, value) {
        switch (action) {
            case 'onValidateOption':
                return onValidateOption(name, value);
            case 'onChangeOption':
                return onChangeOption(name, value);
            case 'onChangeOptionPseudo':
                return onChangeOptionPseudo(name, value);
            case 'onChangeOptionAddress':
                return onChangeOptionAddress(name, value);
            default:
        }
    }
    //FUNCTIONS OPTIONS
    function onChangeOption(name, value) {
        if (name === 'videoUrl') setValue('video', value);
    }
    function onChangeOptionPseudo(name, value) {
        setValue('pseudoOption', value);
    }
    function onChangeOptionAddress(name, value) {
        setValue(name, value);
    }
    function onValidateOption(name, value) {
        return true;
    }

    const buttonUploadVideo = (
        <div className="flex gap-2 text-graySkills my-2">
            <PlayIcon className="w-6 h-6" />
        </div>
    );

    return (
        <>
            <PageContentFadingAnimation duration={250}>
                <Header>
                    <h1 className="font-bold text-3xl text-purpleSkills py-4">Mon profil</h1>
                </Header>
                <form onSubmit={handleSubmit(onSubmit)} className="form mb-4 sm:mb-20" autoComplete="off" encType="multipart/form-data">
                    <ProgressBar progress={progressBar.value} />
                    <div className="flex flex-wrap lg:flex-nowrap w-full py-2 gap-2">
                        <div className="flex flex-col items-center w-full lg:w-1/3 mb-4">
                            <h1 className="text-sm text-graySkills">Image de profil*</h1>
                            <FieldFiles
                                name="avatar"
                                type="single_rounded"
                                label="Votre avatar"
                                multiple={false}
                                register={register}
                                initValue={avatarUrl}
                                onChange={(value) => {
                                    setValue('avatar', value);
                                }}
                            />
                        </div>
                        <div className="w-full lg:w-2/3">
                            <h1 className="text-sm text-graySkills">Image de couverture*</h1>
                            <FieldFiles
                                name="cover"
                                type="single"
                                label="Votre image de couverture"
                                multiple={false}
                                register={register}
                                initValue={coverUrl}
                                onChange={(value) => {
                                    setValue('cover', value);
                                }}
                            />
                        </div>
                    </div>

                    <h3 className="section_title">Mes informations</h3>
                    <div className="flex w-full gap-3">
                        <FieldInput placeholder="Prénom*" inputForm="flat" name="firstname" label={false} size="w-1/2" errors={errors} register={register} />
                        <FieldInput placeholder="Nom*" inputForm="flat" name="lastname" label={false} size="w-1/2" errors={errors} register={register} />
                    </div>
                    <div className="flex flex-col sm:flex-row w-full sm:gap-3">
                        {/* <FieldInput inputForm="floating" name="pseudo" label="Pseudo" errors={errors} register={register} /> */}
                        <FieldInput
                            className="mt-4"
                            placeholder="Job(s) (Cuisinier, artiste, formateur, Youtubeur...)"
                            inputForm="flat"
                            name="status"
                            size="w-full sm:w-1/2"
                            label={false}
                            errors={errors}
                            register={register}
                            maxLength={60}
                        />

                        <TextareaCustom
                            inputForm="flat"
                            label={false}
                            /* placeholder="Nous pourrons vous aider à les atteindre" */
                            placeholder="Quel est votre but professionnel ?"
                            name="objectifs"
                            className="input_flat p-4"
                            autoresize={true}
                            size="w-full sm:w-1/2"
                            rows={1}
                            errors={errors}
                            register={register}
                            initValue={objectifsInitialValue}
                            onChange={(e) => setValue('objectifs', e.target.value)}
                        />
                    </div>

                    <div className="md:flex w-full gap-3">
                        <FieldInput
                            className="mt-2 sm:mt-6"
                            placeholder="Société"
                            inputForm="flat"
                            name="society"
                            label={false}
                            errors={errors}
                            register={register}
                        />
                        <FieldSelect
                            className="mt-4 sm:mt-0"
                            inputForm="flat"
                            name="display"
                            label="Vous publiez en tant que*"
                            options={[
                                { label: 'Personne (Prénom NOM)', value: 'realnames' },
                                {
                                    label: 'Entreprise',
                                    value: 'society',
                                    display: getValues('society') !== '' ? true : false,
                                },
                            ]}
                            errors={errors}
                            register={register}
                        />
                    </div>
                    <div className="flex w-full gap-3 mt-4">
                        <FieldInput
                            disabled={true}
                            placeholder="Email"
                            inputForm="flat"
                            name="email"
                            label={false}
                            size="w-1/2"
                            errors={errors}
                            register={register}
                        />
                        <FieldInput
                            placeholder="Téléphone"
                            inputForm="flat"
                            name="phone"
                            size="w-1/2"
                            label={false}
                            maxLength={15}
                            errors={errors}
                            register={register}
                        />
                    </div>
                    <div className="w-full mb-4">
                        <h3 className="font-medium text-sm mt-4 text-graySkills">Vos tags*</h3>
                        <Tags
                            className="input_flat py-2 input_tags"
                            name="tags"
                            label=" "
                            register={register}
                            initValue={tagsInitialValue}
                            onChange={(value) => {
                                setValue('tags', value);
                            }}
                            countUsedTag={true}
                            readyToWatch={readyToWatch}
                            selected={true}
                        />
                    </div>

                    <div className="flex w-full gap-3 pb-2">
                        <FieldInput placeholder="URL site Internet" inputForm="flat" name="linkWebsite" label={false} errors={errors} register={register} />
                        <FieldInput
                            inputForm="flat"
                            placeholder="Adresse postale"
                            disabled={true}
                            name="address"
                            label={false}
                            depends="point"
                            errors={errors}
                            register={register}
                            option={profileOptions.address}
                            dispatchOptionsActions={dispatchOptionsActions}
                            getValues={getValues}
                            readyToWatch={readyToWatch}
                        />
                    </div>
                    {/* <div className="flex w-full gap-3">
                        <FieldInput
                            inputForm="flat"
                            placeholder="Vidéo de présentation"
                            name="video"
                            label={false}
                            errors={errors}
                            register={register}
                            option={profileOptions.video}
                            dispatchOptionsActions={dispatchOptionsActions}
                            getValues={getValues}
                            readyToWatch={readyToWatch}
                        />
                    </div> */}
                    <div className="w-full mb-6">
                        <h3 className="section_title">Vidéo de présentation</h3>

                        <div className="w-full">
                            <FieldFiles
                                name="presentationVideo"
                                label="Déposez ou cliquez ici pour uploader une image ou vidéo"
                                type="single_video"
                                multiple={false}
                                register={register}
                                oldFiles={presentationVideoInitialValue}
                                initValue={[]}
                                readyToWatch={readyToWatch}
                                onChange={(newFiles) => {
                                    setValue('presentationVideo', newFiles);
                                }}
                                button={buttonUploadVideo}
                                accepts={['video/mp4', 'video/quicktime']}
                                maxFileSize={500000000} // 500 Mo
                            />
                        </div>
                    </div>
                    <div className="flex w-full">
                        {/*  <Textarea
                            label="Présentation"
                            name="presentation"
                            errors={errors}
                            register={register}
                            initValue={presentationInitialValue}
                            onChange={(value) => setValue('presentation', value)}
                        /> */}
                        <TextareaCustom
                            inputForm="flat"
                            label={false}
                            /* placeholder="Nous pourrons vous aider à les atteindre"  */
                            placeholder="Votre biographie*"
                            name="presentation"
                            className="input_flat p-4"
                            autoresize={true}
                            size="w-full"
                            rows={4}
                            errors={errors}
                            register={register}
                            initValue={presentationInitialValue}
                            onChange={(e) => {
                                //console.log(e.target.value);
                                setValue('presentation', e.target.value);
                            }}
                        />
                    </div>

                    <h3 className="section_title">Permis, expériences professionnelles, qualifications, langues et hobbies</h3>
                    <div className="flex w-full gap-3 py-2">
                        <FieldExperiences
                            label="Intitulé du poste*"
                            name="experiences"
                            items={experiences}
                            errors={errors}
                            register={register}
                            onChange={(value) => {
                                setValue('experiences', value);
                            }}
                        />
                        {/* Les diplômes */}
                        {/*  Ecole*, Date de début, Date de fin, Diplôme, */}
                    </div>
                    <div className="flex w-full gap-3 py-2">
                        <FieldQualifications
                            label="Intitulé du diplôme*"
                            name="qualifications"
                            items={qualifications}
                            errors={errors}
                            register={register}
                            onChange={(value) => {
                                setValue('qualifications', value);
                            }}
                        />
                        {/* Les diplômes */}
                        {/*  Ecole*, Date de début, Date de fin, Diplôme, */}
                    </div>
                    <div className="flex w-full gap-3 py-2">
                        <FieldPermis
                            label="Quel est votre permis (B, bateau, CACES, moto, ...) ?*"
                            name="permis"
                            items={permis}
                            errors={errors}
                            register={register}
                            onChange={(value) => {
                                setValue('permis', value);
                            }}
                        />
                    </div>
                    <div className="flex w-full gap-3 py-2">
                        <FieldLanguages
                            label="Langue*"
                            name="languages"
                            items={languages}
                            errors={errors}
                            register={register}
                            onChange={(value) => {
                                setValue('languages', value);
                            }}
                        />
                    </div>
                    <div className="flex w-full gap-3 py-2 mb-4">
                        <FieldHobbies
                            label="Hobby*"
                            name="hobbies"
                            items={hobbies}
                            errors={errors}
                            register={register}
                            onChange={(value) => {
                                setValue('hobbies', value);
                            }}
                        />
                    </div>

                    <h3 className="section_title">Mes réseaux sociaux</h3>

                    <div className="flex mb-4 w-full gap-3">
                        <FieldInput
                            placeholder="URL Youtube"
                            inputForm="flat"
                            name="linkYoutube"
                            size="w-1/2"
                            label={false}
                            errors={errors}
                            register={register}
                        />
                        <FieldInput
                            placeholder="URL Instagram"
                            inputForm="flat"
                            name="linkInstagram"
                            size="w-1/2"
                            label={false}
                            errors={errors}
                            register={register}
                        />
                    </div>
                    <div className="flex mb-4 w-full gap-3">
                        <FieldInput
                            placeholder="URL TikTok"
                            inputForm="flat"
                            name="linkTiktok"
                            size="w-1/2"
                            label={false}
                            errors={errors}
                            register={register}
                        />
                        <FieldInput
                            placeholder="URL Snapchat"
                            inputForm="flat"
                            name="linkSnapchat"
                            size="w-1/2"
                            label={false}
                            errors={errors}
                            register={register}
                        />
                    </div>
                    <div className="flex w-full gap-3">
                        <FieldInput
                            placeholder="URL LinkedIn"
                            inputForm="flat"
                            name="linkLinkedin"
                            size="w-1/2"
                            label={false}
                            errors={errors}
                            register={register}
                        />
                        <FieldInput placeholder="URL X" inputForm="flat" name="linkTwitter" size="w-1/2" label={false} errors={errors} register={register} />
                    </div>

                    <h3 className="section_title">Ma visio-consultation</h3>
                    <FieldInput
                        placeholder="€ Prix horaire pour une heure de visio consultation"
                        inputForm="flat"
                        name="price"
                        label="€ Prix horaire pour une heure de visio consultation"
                        type="number"
                        min="0"
                        defaultValue={0}
                        errors={errors}
                        register={register}
                    />

                    <FieldInput
                        inputForm="checkbox"
                        name="isFreeFirst"
                        type="checkbox"
                        label="Offrir la première consultation gratuite"
                        errors={errors}
                        register={register}
                    />

                    <div className="w-full">
                        <h3 className="section_title">Mon portfolio</h3>

                        <FieldFiles
                            name="medias"
                            type="multiple"
                            label="Déposez ou cliquez ici pour uploader vos photos"
                            multiple={true}
                            register={register}
                            initValue={medias}
                            readyToWatch={readyToWatch}
                            onChange={(value) => {
                                setValue('medias', value);
                            }}
                            accepts={['image/png', 'image/jpeg', 'image/jpg']}
                        />
                    </div>

                    <input ref={currentForm} type="submit" className="hidden" />
                </form>
            </PageContentFadingAnimation>
        </>
    );
}

export default FormProfil;
