import { useState, useRef, forwardRef, useImperativeHandle, useContext, useEffect } from 'react';

//API
import { putSelectAnswer } from 'api/forum';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//ICONS
import { DotsVerticalIcon, CheckCircleIcon } from '@heroicons/react/outline';

//CONTAINERS
import AnswerTo from 'containers/answers/AnswerTo';
import Avatar from 'containers/profils/Avatar';
/* import OptionsMenu from 'containers/rooms/OptionsMenu'; */

//COMPONENTS
import Button from 'components/common/Button';
import Moment from 'components/common/Moment';
import Nl2br from 'components/common/Nl2br';
import { ChatAlt2Icon } from '@heroicons/react/outline';
import OptionsMenu from 'components/common/OptionsMenu';
import CountersReaction from 'containers/rooms/CountersReaction';
import ReactionIcons from 'components/ReactionIcons';
import { Link } from 'react-router-dom';

function Answers(props, ref) {
    const [dateSwitched, setDateSwitched] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(false);
    const [answers, setAnswers] = useState(props.item.answers);

    const refAnswer = useRef(null);
    const refTargetAnswerId = useRef(null);
    const executeScroll = () => refTargetAnswerId.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    const [authState] = useContext(AuthContext);

    useImperativeHandle(ref, () => ({
        switchDate: () => handleSwitchDate(),
    }));

    useEffect(() => {
        if (props.type === 'shoot') {
            props.item.answers = props.item.answers.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
            setAnswers(props.item.answers);
        } else {
            setAnswers(props.item.answers);
        }
        setSelectedAnswer(false);
        props.item.answers.forEach((answer) => {
            if (answer.selected) setSelectedAnswer(true);
            else {
                answer.answers.forEach((sub) => {
                    if (sub.selected) {
                        setSelectedAnswer(true);
                    }
                });
            }
        });
        //eslint-disable-next-line
    }, [props.item]);

    function closeOptionsMenu(answer) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${answer._id}`) {
                el.classList.add('hidden');
            }
        });
    }

    function handleOptionsMenu(answer) {
        closeOptionsMenu(answer);
        document.getElementById(`optionsMenu_${answer._id}`).classList.toggle('hidden');
    }

    function handleSwitchDate() {
        setDateSwitched(!dateSwitched);
    }

    //TODO: réafficher également le bouton "répondre" après avoir répondu
    function closeAnswersTo() {
        const els = document.getElementsByClassName('formAnswer');
        [].forEach.call(els, function (el) {
            el.classList.add('hidden');
        });
    }

    function handleAnswerTo(answer) {
        refAnswer.current = answer;
        closeAnswersTo();

        document.getElementById(`formAnswer_${answer._id}`).classList.toggle('hidden');

        const els = document.getElementsByClassName('buttonAnswer');
        [].forEach.call(els, function (el) {
            el.classList.remove('hidden');
        });

        document.getElementById(`buttonAnswer_${answer._id}`).classList.toggle('hidden');
    }

    function selectAnswer(answerId, type) {
        putSelectAnswer(answerId, type).then((data) => {
            props.refetch();
        });
    }

    function handleGoToAnswer(e, answer) {
        for (let i = 0; i < props.item.answers.length; i++) {
            for (let j = 0; j < props.item.answers[i].answers.length; j++) {
                if (props.item.answers[i].answers[j]._id === answer) {
                    const element = document.getElementById(`answer_${answer}`);
                    refTargetAnswerId.current = element;
                    executeScroll();
                }
            }
        }
    }

    function getUserFromAnswer(answer) {
        for (let i = 0; i < props.item.answers.length; i++) {
            for (let j = 0; j < props.item.answers[i].answers.length; j++) {
                if (props.item.answers[i].answers[j]._id === answer) {
                    return `En réponse à ${props.item.answers[i].answers[j].user?.profile.pseudo}`;
                }
            }
        }
        return;
    }

    function getNumberOfAnswers(answerId) {
        let count = 0;
        for (let i = 0; i < props.item.answers.length; i++) {
            for (let j = 0; j < props.item.answers[i].answers.length; j++) {
                if (props.item.answers[i].answers[j].to === answerId && props.item.answers[i].answers[j].deleted !== true) {
                    count++;
                }
            }
        }
        return count;
    }

    function getNumberOfAnswersRoom() {
        let count = 0;
        for (let i = 0; i < props.item.answers.length; i++) {
            if (props.item.answers[i].deleted !== true) {
                count++;
            }
        }
        return count;
    }

    return (
        <>
            {props.item.answers.length === 0 && (
                <div className="w-full mt-6">
                    <h2 className="font-bold text-purpleSkills">Réponses (0)</h2>
                </div>
            )}
            {props.item.answers && props.item.answers.length > 0 && (
                <div className="w-full mt-6">
                    <h2 className="font-bold text-purpleSkills">Réponses ({getNumberOfAnswersRoom()})</h2>
                    {/* <AnswerTo /> */}
                    <div className={`w-full mt-4 ${props?.type === 'shoot' && 'overflow-y-auto max-h-[58vh] sm:max-h-[68vh] px-2 pb-12'}`}>
                        {answers.map(
                            (answer, index) =>
                                !answer.deleted && (
                                    <div key={index} className={`flex flex-col gap-2 mt-2 pt-4 h-auto`}>
                                        <div className={`${answer.selected ? 'bg-blueSelect p-2 rounded-xl' : ''}`}>
                                            {answer.selected && <p className="text-blueMain text-xs pb-2">Réponse sélectionnée par l'auteur</p>}
                                            <div className={`flex w-full`}>
                                                <div className="basis-1/12 mt-2">
                                                    <Avatar user={answer?.user} className="w-12" />
                                                </div>
                                                <div className="flex flex-col basis-full">
                                                    <div
                                                        className={`${
                                                            answer.selected ? 'bg-white' : 'bg-white'
                                                        } rounded-r-3xl rounded-b-3xl px-4 pb-4 pt-2 mb-2`}
                                                    >
                                                        <div className="w-auto grow flex flex-wrap flex-col pb-4">
                                                            <div className="flex flex-row items-center">
                                                                <div className="grow flex flex-row gap-2 items-center justify-start relative flex-wrap">
                                                                    <Link
                                                                        to={'/profil/' + answer?.user?.profile?.slug}
                                                                        title={`Profil de ${
                                                                            answer?.user?.profile?.pseudo
                                                                                ? answer?.user?.profile?.pseudo
                                                                                : answer?.user?.profile?.society
                                                                        }`}
                                                                    >
                                                                        <span className="text-sm md:text-xl font-bold text-purpleSkills">
                                                                            {answer?.clone ? 'Clone AI de ' : ''}
                                                                            {answer?.user?.profile.pseudo}
                                                                        </span>
                                                                    </Link>

                                                                    <span className="text-xs text-gray-400">•</span>
                                                                    <span className="text-graySkills font-normal text-xs" onClick={handleSwitchDate}>
                                                                        {dateSwitched ? (
                                                                            <Moment date={answer?.createdAt} format={'DD MMM YYYY - HH:mm'} />
                                                                        ) : (
                                                                            <Moment date={answer?.createdAt} fromNow />
                                                                        )}
                                                                    </span>
                                                                    {answer.updated && !answer.deleted && (
                                                                        <>
                                                                            <span className="text-xs text-gray-400">•</span>
                                                                            <span className="text-xs text-warning-400 text-right md:text-left mr-2">
                                                                                Réponse modifiée
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                    {answer.deleted && (
                                                                        <>
                                                                            <div className="hidden sm:flex gap-2">
                                                                                <span className="text-xs text-gray-400">•</span>
                                                                                <span className="text-xs text-danger-400 text-right md:text-left mr-2">
                                                                                    Réponse supprimée par son auteur
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {/*   {!answer.deleted && (
                                                                <>
                                                                    <div>
                                                                        <CountersDepends type="answer" userTo={answer?.user?.id} refetch={props.room.refetch} to={answer?._id} counters={props.room?.data?.counters_answers} />
                                                                    </div>
                                                                </>
                                                            )} */}
                                                            </div>
                                                            <span className="text-xs font-normal text-purpleSkills">{answer?.user?.profile.status}</span>
                                                        </div>

                                                        {answer.answer && !answer.deleted && (
                                                            <div className="sm:text-sm pb-0 card-item-question">
                                                                <Nl2br str={answer.answer} />
                                                                {/* <div dangerouslySetInnerHTML={{ __html: answer.answer }} /> */}
                                                            </div>
                                                        )}
                                                        {answer.deleted && (
                                                            <>
                                                                <span className="sm:hidden text-sm text-danger-400">Réponse supprimée par son auteur</span>
                                                            </>
                                                        )}
                                                    </div>
                                                    <ReactionIcons
                                                        counters={props.item.counters_answers}
                                                        to={answer?._id}
                                                        targetType="answer"
                                                        type="reactions"
                                                    />
                                                    <div className="flex flex-col md:flex-row md:items-center">
                                                        <div className="flex flex-row w-full items-center gap-0 m-0">
                                                            {!answer.deleted && (
                                                                <>
                                                                    <div className="flex flex-row grow items-center">
                                                                        <div className="flex flex-row gap-1 items-center relative">
                                                                            {authState?.me?.id &&
                                                                                authState?.me?.id === props.item?.user?._id &&
                                                                                (!selectedAnswer || answer.selected) && (
                                                                                    <>
                                                                                        <div className="flex">
                                                                                            <Button
                                                                                                onClick={() => selectAnswer(answer._id, 'answer')}
                                                                                                label={<CheckCircleIcon className="w-5 h-5" />}
                                                                                                justIcon={true}
                                                                                                css={`
                                                                                                    ${answer.selected ? 'text-blue-300' : 'text-graySkills'}
                                                                                                `}
                                                                                            />
                                                                                        </div>
                                                                                        <span className="text-purpleSkills text-sm">•</span>
                                                                                    </>
                                                                                )}
                                                                            {/* <Button onClick={() => answerSelection(answer)} label={<CheckCircleIcon className="w-5 h-5" />} justIcon={true} css="text-gray-400 pb-1" /> */}
                                                                            <CountersReaction
                                                                                room={props.item}
                                                                                type="answer"
                                                                                refetch={props.refetch}
                                                                                to={answer?._id}
                                                                                userTo={answer?.user?.id}
                                                                                counters={props.item.counters_answers}
                                                                            />
                                                                            <span className="text-purpleSkills">|</span>
                                                                            {!props.shownFormAnswer &&
                                                                                authState.isLogged &&
                                                                                (props.canPost || !props.forSkillersSubscribed) &&
                                                                                !answer.deleted && (
                                                                                    <>
                                                                                        <div
                                                                                            className="buttonAnswer flex flex-row gap-2 justify-end"
                                                                                            id={`buttonAnswer_${answer._id}`}
                                                                                        >
                                                                                            <Button
                                                                                                onClick={() => handleAnswerTo(answer)}
                                                                                                label={`répondre`}
                                                                                                justIcon={true}
                                                                                                css="text-purpleSkills font-bold text-sm hover:text-blueMain"
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            <span className="text-purpleSkills text-sm">•</span>
                                                                            <div className="hidden sm:block">
                                                                                <Button
                                                                                    label={`${getNumberOfAnswers(answer?._id)} réponses`}
                                                                                    justIcon={true}
                                                                                    css="text-graySkills text-sm hover:text-blueMain"
                                                                                />
                                                                            </div>
                                                                            <div className="sm:hidden flex flex-row items-center">
                                                                                <span className="text-graySkills text-sm">
                                                                                    {getNumberOfAnswers(answer?._id)}
                                                                                </span>
                                                                                <Button
                                                                                    label={<ChatAlt2Icon className="w-5 h-5" />}
                                                                                    justIcon={true}
                                                                                    css="text-graySkills text-sm"
                                                                                />
                                                                            </div>
                                                                            <span className="text-purpleSkills text-sm">•</span>
                                                                            {/*    {!props.shownFormAnswer && authState.isLogged && !answer.deleted && (
                                                                            <div
                                                                                className="buttonAnswer flex flex-row gap-2 justify-end"
                                                                                id={`buttonAnswer_${answer._id}`}
                                                                            >
                                                                                <Button
                                                                                    onClick={() => handleAnswerTo(answer)}
                                                                                    label={`répondre`}
                                                                                    justIcon={true}
                                                                                    css="text-graySkills text-sm hover:text-blueMain"
                                                                                />
                                                                            </div>
                                                                        )} */}
                                                                            {!answer.deleted && (
                                                                                <>
                                                                                    <div className="relative flex">
                                                                                        <Button
                                                                                            onClick={() => handleOptionsMenu(answer)}
                                                                                            label={
                                                                                                <DotsVerticalIcon className="w-5 h-5 hover:text-purpleSkills" />
                                                                                            }
                                                                                            justIcon={true}
                                                                                            css="text-graySkills"
                                                                                        />
                                                                                        <OptionsMenu
                                                                                            answer={answer}
                                                                                            refresh={props.refetch}
                                                                                            type="answer"
                                                                                            itemType={props.type}
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {/*
                                                                <div>
                                                                    <CountersDepends
                                                                        type="answer"
                                                                        userTo={answer?.user?.id}
                                                                        refetch={props.room.refetch}
                                                                        to={answer?._id}
                                                                        counters={props.room?.data?.counters_answers}
                                                                    />
                                                                </div>
                                                                */}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="formAnswer hidden" id={`formAnswer_${answer._id}`}>
                                                        {props.type === 'room' && (
                                                            <AnswerTo
                                                                answer={answer}
                                                                item={props.item}
                                                                closeAnswersTo={closeAnswersTo}
                                                                type={'answerRoom'}
                                                                refetch={props.refetch}
                                                            />
                                                        )}
                                                        {props.type === 'shoot' && (
                                                            <AnswerTo
                                                                answer={answer}
                                                                item={props.item}
                                                                closeAnswersTo={closeAnswersTo}
                                                                type={'answerShoot'}
                                                                refetch={props.refetch}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {answer.answers.map(
                                            (sub, index2, arr) =>
                                                !sub.deleted && (
                                                    <div key={index2} id={`answer_${sub._id}`}>
                                                        <div
                                                            className={`flex flex-col justify-between my-0 ml-6 pt-2 ${
                                                                sub.selected ? 'bg-blue-50 p-2 rounded border-success-100' : ''
                                                            }`}
                                                        >
                                                            <div className="w-full">
                                                                {sub.selected && (
                                                                    <p className="text-blue-300 text-xs pb-2">Réponse sélectionnée par l'auteur</p>
                                                                )}
                                                            </div>
                                                            {sub?.to && (
                                                                <span
                                                                    className="text-graySkills text-sm hover:text-blueMain cursor-pointer mb-2"
                                                                    onClick={(e) => handleGoToAnswer(e, sub.to)}
                                                                >
                                                                    {getUserFromAnswer(sub.to)}
                                                                </span>
                                                            )}
                                                            <div className="flex w-full">
                                                                <div className="basis-1/12 mt-2">
                                                                    <Avatar user={sub?.user} className="w-10" />
                                                                </div>
                                                                <div className="flex flex-col basis-full">
                                                                    <div
                                                                        className={`${
                                                                            sub.selected ? 'bg-blueSelect' : 'bg-white'
                                                                        } rounded-r-3xl rounded-b-3xl px-4 pb-4 pt-2 mb-2`}
                                                                    >
                                                                        <div className="w-auto grow flex flex-wrap flex-col pb-4">
                                                                            <div className="flex flex-row items-center">
                                                                                <div className="grow flex flex-row gap-2 items-center justify-start relative flex-wrap">
                                                                                    <Link
                                                                                        to={'/profil/' + sub?.user?.profile?.slug}
                                                                                        title={`Profil de ${
                                                                                            sub?.user?.profile?.pseudo
                                                                                                ? sub?.user?.profile?.pseudo
                                                                                                : sub?.user?.profile?.society
                                                                                        }`}
                                                                                    >
                                                                                        <span className="text-sm md:text-xl font-bold text-purpleSkills">
                                                                                            {sub?.user?.profile.pseudo}
                                                                                        </span>
                                                                                    </Link>

                                                                                    <span className="text-xs text-gray-400">•</span>
                                                                                    <span
                                                                                        className="text-graySkills font-normal text-xs"
                                                                                        onClick={handleSwitchDate}
                                                                                    >
                                                                                        {dateSwitched ? (
                                                                                            <Moment date={sub.createdAt} format={'DD MMM YYYY - HH:mm'} />
                                                                                        ) : (
                                                                                            <Moment date={sub.createdAt} fromNow />
                                                                                        )}
                                                                                    </span>
                                                                                    {sub.updated && !sub.deleted && (
                                                                                        <>
                                                                                            <span className="text-xs text-gray-400">•</span>
                                                                                            <span className="text-xs text-warning-400 text-right md:text-left mr-2">
                                                                                                Réponse modifiée
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                    {sub.deleted && (
                                                                                        <>
                                                                                            <span className="text-xs text-gray-400">•</span>
                                                                                            <span className="text-xs text-danger-400 text-right md:text-left mr-2">
                                                                                                Réponse supprimée par son auteur
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </div>

                                                                                {/*  {!sub.deleted && (
                                                                            <>
                                                                                <div>
                                                                                    <CountersDepends type="subTo" userTo={sub?.user?.id} refetch={props.room.refetch} to={sub?._id} counters={props.room?.data?.counters_answersTo} />
                                                                                </div>
                                                                            </>
                                                                        )} */}
                                                                            </div>
                                                                            <span className="text-xs font-normal text-purpleSkills">
                                                                                {sub?.user?.profile.status}
                                                                            </span>
                                                                        </div>
                                                                        {!sub.deleted && (
                                                                            <div className="flex flex-row justify-between w-full">
                                                                                <p className="sm:text-sm pb-0 card-item-question">
                                                                                    <Nl2br str={sub.answer} />
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    {!sub.deleted && (
                                                                        <>
                                                                            <ReactionIcons
                                                                                counters={props.item?.counters_answersTo}
                                                                                to={sub?._id}
                                                                                targetType="answerTo"
                                                                                type="reactions"
                                                                            />
                                                                            <div className="flex flex-row gap-1 items-center justify-end">
                                                                                <div className="flex flex-row grow">
                                                                                    <div className="flex flex-row items-center gap-1 relative">
                                                                                        {authState?.me?.id &&
                                                                                            authState?.me?.id === props.item?.user?._id &&
                                                                                            (!selectedAnswer || sub.selected) && (
                                                                                                <>
                                                                                                    <div className="flex">
                                                                                                        <Button
                                                                                                            onClick={() => selectAnswer(sub._id, 'answerTo')}
                                                                                                            label={<CheckCircleIcon className="w-5 h-5" />}
                                                                                                            justIcon={true}
                                                                                                            css={`
                                                                                                                ${sub.selected
                                                                                                                    ? 'text-blue-300'
                                                                                                                    : 'text-graySkills'}
                                                                                                            `}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <span className="text-purpleSkills text-sm">•</span>
                                                                                                </>
                                                                                            )}
                                                                                        <CountersReaction
                                                                                            room={props.item}
                                                                                            type="answerTo"
                                                                                            refetch={props.refetch}
                                                                                            to={sub?._id}
                                                                                            userTo={sub?.user?.id}
                                                                                            counters={props.item?.counters_answersTo}
                                                                                        />
                                                                                        <span className="text-purpleSkills">|</span>
                                                                                        {!props.shownFormAnswer &&
                                                                                            authState.isLogged &&
                                                                                            (props.canPost || !props.forSkillersSubscribed) &&
                                                                                            !sub.deleted && (
                                                                                                <>
                                                                                                    <div
                                                                                                        className="buttonAnswer flex flex-row gap-2 justify-end"
                                                                                                        id={`buttonAnswer_${sub._id}`}
                                                                                                    >
                                                                                                        <Button
                                                                                                            onClick={() => handleAnswerTo(sub)}
                                                                                                            label={`répondre`}
                                                                                                            justIcon={true}
                                                                                                            css="text-purpleSkills font-bold text-sm hover:text-blueMain"
                                                                                                        />
                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        <span className="text-purpleSkills text-sm">•</span>
                                                                                        <div className="hidden sm:block">
                                                                                            <Button
                                                                                                label={`${getNumberOfAnswers(sub?._id)} réponses`}
                                                                                                justIcon={true}
                                                                                                css="text-graySkills text-sm hover:text-blueMain"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="sm:hidden flex flex-row items-center">
                                                                                            <span className="text-graySkills text-sm">
                                                                                                {getNumberOfAnswers(sub._id)}
                                                                                            </span>
                                                                                            <Button
                                                                                                label={<ChatAlt2Icon className="w-5 h-5" />}
                                                                                                justIcon={true}
                                                                                                css="text-graySkills text-sm"
                                                                                            />
                                                                                        </div>
                                                                                        <span className="text-purpleSkills text-sm">•</span>
                                                                                        <div className="relative flex">
                                                                                            <Button
                                                                                                onClick={() => handleOptionsMenu(sub)}
                                                                                                label={
                                                                                                    <DotsVerticalIcon className="w-5 h-5 hover:text-blueMain" />
                                                                                                }
                                                                                                justIcon={true}
                                                                                                css="text-graySkills"
                                                                                            />
                                                                                            <OptionsMenu
                                                                                                answer={sub}
                                                                                                refresh={props.refetch}
                                                                                                type="answerTo"
                                                                                                itemType={props.type}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/*
                                                                        <div>
                                                                            <CountersDepends
                                                                                type="subTo"
                                                                                userTo={sub?.user?.id}
                                                                                refetch={props.room.refetch}
                                                                                to={sub?._id}
                                                                                counters={props.room?.data?.counters_answersTo}
                                                                            />
                                                                        </div>
                                                                        */}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="formAnswer hidden" id={`formAnswer_${sub._id}`}>
                                                                {props.type === 'room' && (
                                                                    <AnswerTo
                                                                        answer={sub}
                                                                        item={props.item}
                                                                        closeAnswersTo={closeAnswersTo}
                                                                        type={'answerToRoom'}
                                                                        refetch={props.refetch}
                                                                        parentAnswer={answer}
                                                                    />
                                                                )}
                                                                {props.type === 'shoot' && (
                                                                    <AnswerTo
                                                                        answer={sub}
                                                                        item={props.item}
                                                                        closeAnswersTo={closeAnswersTo}
                                                                        type={'answerToShoot'}
                                                                        refetch={props.refetch}
                                                                        parentAnswer={answer}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                    </div>
                                )
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default forwardRef(Answers);
