import { useRef, useContext } from 'react';

import Avatar from 'containers/profils/Avatar';
//COMPONENTS
import Modal from 'components/common/Modal';
import Buy from 'components/Buy';

import { XCircleIcon } from '@heroicons/react/outline';

import { AuthContext } from 'context/authContext';
import { isMobile } from 'utils/functions';
import { toast } from 'react-toastify';


export default function BuyCloneAi(props) {

    const [authState] = useContext(AuthContext);
    const refBuy = useRef(null);

    function handleBuy() {
        let isSubscribed = false;
        if (authState?.me?.plans && authState?.me?.plans.length > 0) {
            if (authState?.me?.plans.some((plan) => plan.type === "chatAi" && plan.status === "succeeded")) {
                isSubscribed = true;
            }
        }
        if (!isSubscribed) {
            refBuy.current.buy();
        } else {
            toast("Vous êtes déjà abonné à Clone AI infinite", { type: 'infos' });
        }
            
    }

    return (
        <>
            <Modal
                open={props.state}
                typeModal="ai"
                noBorder={true}
                /* onConfirm={
                    !isLogged
                        ? {
                              label: 'Valider',
                              onClick: handleLogin,
                          }
                        : false
                } */
            >
                <div className='relative px-4 sm:p-2'>
                    {props?.data && (
                        <p className="text-mainText text-sm">Limite d'utilisation gratuite atteinte</p>
                    )}
                    <h2 className={`${isMobile() ? "text-2xl" : "text-4xl"} font-bold text-[#852ebb] mb-4`}>Passez à Clone AI infinite</h2>
                    <div className="absolute -top-8 -right-2 sm:-right-8">
                        <div className="">
                            <XCircleIcon
                                className={`w-10 h-10 text-black hover:text-red hover:cursor-pointer`}
                                onClick={() => props.setState(false)}
                            />
                        </div>
                    </div>
                    <div className={`text-mainText mt-4 flex ${isMobile() ? "flex-col" : "flex-row"} gap-6 mb-6`}>
                        <div className={`self-center ${isMobile() ? "w-24 h-24" : "w-1/3"}`}>
                            <Avatar gamification={false} user={authState?.me} className={`rounded-full mt-0 border-solid border-2 border-[#b235fe] object-cover`} />
                        </div>
                        <div className={`${isMobile() ? "w-full" : "w-2/3"}`}>
                            <p className="font-bold text-[#852ebb]">Inclus en illimité :</p>
                            <ul className="list-disc list-inside marker:text-[#b235fe] pl-3 text-sm">
                                <li>
                                    <span className="font-bold">Chat mon clone AI</span> : il vous aide au quotidien.
                                </li>
                                <li>
                                    <span className="font-bold">Reponses AI</span> : il commente et répond pour vous dans les news et le forum pro.
                                </li>
                                <li>
                                    <span className="font-bold">AI round</span> : il négocie pour vous avec les clones AI de vos cibles.
                                </li>
                                <li>
                                    <span className="font-bold">Boost</span> de 100 points de référencement.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p className="text-center text-[#852ebb] mb-4 font-bold ">9€ / mois sans engagement</p>
                    <div className="flex flex-row justify-center">
                        <button
                            className="w-auto flex p-2 px-4 bg-black text-white font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]"
                            onClick={() => {
                                handleBuy();
                            }}
                        >
                            <span>Passez à infinite</span>
                        </button>
                        <Buy ref={refBuy} type="chatAi" item={"data"} setState={props.setState}/>
                    </div>
                </div>
            </Modal>
        </>
    );
}