import API from './axios.config';
import authHeader from "services/Auth.Header";

async function createClone() {
    const response = await API.post('clone/create', {}, { headers: authHeader() });
    return response;
}

async function reCreateClone() {
    const response = await API.post('clone/recreate', {}, { headers: authHeader() });
    return response;
}

async function updateClone() {
    const response = await API.post('clone/update', {}, { headers: authHeader() });
    return response;
};

async function personalCloneChat() {
    const response = await API.post('clone/personal/chat', { headers: authHeader() });
    return response.data;
}

async function postCloneDialogue(data) {
    const response = await API.post('clone/conversation', data, { headers: authHeader() });
    return response.data;
}

async function toggleAiRound() {
    const response = await API.put('clone/toggleAiRound', {}, { headers: authHeader() });
    return response;
}

async function personalCloneConversation() {
    const response = await API.get('clone/personal/conversation', { headers: authHeader() });
    return response.data;
}

async function getMatchingClone() {
    const response = await API.get("clone/matching", { headers: authHeader() });
    return response;
}

async function getCloneByMe() {
    const response = await API.get("clone/me", { headers: authHeader() });
    return response.data;
}

async function getForumAutoAnswer(forumId, question, userId) {
    const response = await API.post('clone/forumAutoAnswer', {forumId: forumId, question: question, user: userId}, { headers: authHeader() });
    return response;
}

async function getForumResponse(data) {
    const response = await API.post('clone/forumAiGenerateResponseForUser', {data}, { headers: authHeader() });
    return response;
}

export {
    createClone,
    reCreateClone,
    updateClone,
    personalCloneChat,
    toggleAiRound,
    personalCloneConversation,
    postCloneDialogue,
    getMatchingClone,
    getCloneByMe,
    getForumAutoAnswer,
    getForumResponse,
};