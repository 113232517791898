import { useCallback, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';

//API
import { getNewNotifications } from 'api/notifications';

//LIBS
import { useNavigate } from 'react-router-dom';

//CONTEXTS
import { SocketContext } from 'context/socketContext';
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';

//COMPONENTS
import Button from 'components/common/Button';

//ICONS
import { BellIcon } from '@heroicons/react/solid';

function Notifications() {
    const navigate = useNavigate();

    const socket = useContext(SocketContext);
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);

    //API
    const newNotifications = useQuery('newnotifications', getNewNotifications);

    useEffect(() => {
        socket.on('notificationsRefresh', () => {
            newNotifications.refetch();
        });
        return () => {
            socket.off('notificationsRefresh');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (newNotifications.isSuccess) {
            store.set({ type: 'add', key: 'notifications', value: newNotifications.data });
        }
        //eslint-disable-next-line
    }, [newNotifications.data]);

    const handleGoToNotifications = useCallback(() => {
        navigate('/dashboard/notifications');
    }, [navigate]);

    return (
        <div className="button-badge relative">
            <Button
                label={<BellIcon className="w-5 h-5 mx-auto" />}
                justIcon={true}
                css="w-8 h-8 bg-graySkills rounded-full text-white hover:bg-blueMain"
                onClick={authState.isLogged ? handleGoToNotifications : undefined}
            />
            {authState.isLogged && newNotifications.isSuccess && (
                <>
                    {newNotifications.data.length > 0 && (
                        <div className="flex justify-center items-center text-white text-xs font-semibold absolute -top-2 -right-1 bg-warning-600 p-1 h-5 w-5 rounded-full">
                            <span>{newNotifications.data.length}</span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default Notifications;
