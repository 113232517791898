import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from 'components/forms/yup/permis';
import { PlusIcon, XCircleIcon } from '@heroicons/react/solid';
import FieldInput from 'components/forms/fields/input';
import useSafeState from 'hooks/useSafeState';
import Modal from 'components/common/Modal';

function FieldPermis(props) {
    const [items, setItems] = useState(props.items);
    const [modalPermis, setModalPermis] = useSafeState(false);
    const [readyToWatch, setReadyToWatch] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    function handleDelete(index, _id) {
        let newPermis;
        if (_id) {
            newPermis = items.filter((permis) => permis._id !== _id);
        } else {
            newPermis = [...items];
            newPermis.splice(index, 1);
        }
        setItems(newPermis);
        props.onChange(newPermis);
    }

    function submit() {
        handleSubmit(onSubmit)();
    }

    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readyToWatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setReadyToWatch(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const onSubmit = (data) => {
        if (errors.length > 0) return false;
        setModalPermis(false);

        let newPermis = [
            ...items,
            {
                permisType: data.permisType,
                vehicle: data.vehicle,
            },
        ];

        setItems(newPermis);
        props.onChange(newPermis);

        setValue('permisType', data.permisType);
        setValue('vehicle', data.vehicle);

        const timer = setTimeout(() => {
            setReadyToWatch(true);
        }, 1000);

        return () => clearTimeout(timer);
    };

    useEffect(() => {
        setItems(props.items);
    }, [props.items]);

    function handlePermis(e) {
        e.preventDefault();
        setModalPermis(true);
    }

    return (
        <>
            <Modal
                open={modalPermis}
                title="Ajouter un permis"
                onClose={{
                    onClick: () => setModalPermis(false),
                }}
                onConfirm={{
                    label: 'Valider',
                    onClick: submit,
                }}
            >
                <div>
                    <div className="pb-2">
                        <FieldInput label={`${props.label ? props.label : 'Titre*'}`} name="permisType" size="w-full" errors={errors} register={register} />
                    </div>
                    <div className="flex items-center gap-3">
                        <input type="checkbox" id="vehicle" name="vehicle" {...register('vehicle')} />
                        <label className="text-sm" htmlFor="vehicle">
                            Je possède un véhicule
                        </label>
                    </div>
                </div>
            </Modal>
            <div className="w-full">
                <button className={`flex ${props.className ? props.className : ''} linkCard text-purpleSkills gap-2`} type="button" onClick={handlePermis}>
                    <PlusIcon className={`${props.iconClassName ? props.iconClassName : 'w-4 h-4'}`} />
                    <span>Ajouter un permis</span>
                </button>
                <div className="flex flex-col mt-2 gap-2">
                    {items?.map((permis, index) => (
                        <div key={index} className="p-2 flex flex-col bg-blancSkills rounded">
                            <div className="flex justify-between w-full">
                                <div className="w-full flex flex-col">
                                    <p className="text-purpleSkills font-bold">Permis</p>
                                    <p className="text-purpleSkills text-sm">{permis.permisType}</p>
                                    <p className="text-purpleSkills text-sm">Véhicule : {permis.vehicle === true ? 'oui' : 'non'}</p>
                                </div>
                                <div className="cursor-pointer">
                                    <XCircleIcon onClick={() => handleDelete(index, permis._id)} className="m-2 w-5 h-5 text-graySkills" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default FieldPermis;
