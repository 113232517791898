import { useRef, useEffect, useState, Fragment } from 'react';

//LIBS
import _ from 'lodash';
import { /* Link, */ useLocation } from 'react-router-dom';
import { Swiper } from 'swiper/react';

//STYLES
import './tabsShoot.css';
import useSwipe from 'hooks/useSwipe';

//UTILS
import { isMobile } from 'utils/functions';
//import config from 'utils/constants';

function TabsShoot(props) {
    const refTabs = useRef(null);
    const swipeContainerRef = useRef(null);

    const { direction } = useSwipe({ ref: swipeContainerRef, threshold: 90 });
    const location = useLocation();

    const [allTabs, setAllTabs] = useState(props.tabs);
    const [currentTab, setCurrentTab] = useState(props.initPosition ?? 0);

    function handleClick(e, tab) {
        e.stopPropagation();
        e.preventDefault();

        if (tab?.url) {
            window.history.replaceState(null, null, `${tab.url}`);
        }
        const items = refTabs.current.getElementsByClassName('Tabs_mobile_shoot_item');
        _.forEach(items, (item) => {
            item.classList.remove('active');
        });
        moveSlider(e.target.parentNode);

        if (typeof props?.refetchFav === 'function') {
            props?.refetchFav();
        }
    }

    function handleSwipe(direction) {
        const items = refTabs.current.getElementsByClassName('Tabs_mobile_shoot_item');
        const arrItems = Array.from(items);

        arrItems.forEach((item) => {
            if (item.classList.contains('active')) {
                setCurrentTab(arrItems.indexOf(item));
            }
        });

        if (currentTab > 0 && direction === 'right') {
            items[currentTab].classList.remove('active');
            moveSlider(items[currentTab - 1]);
            if (isMobile()) {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        } else if (currentTab < items.length - 1 && direction === 'left') {
            items[currentTab].classList.remove('active');
            moveSlider(items[currentTab + 1]);
            if (isMobile()) {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }
        
        if (typeof props?.refetchFav === 'function') {
            props?.refetchFav();
        }
    }

    function moveSlider(elem) {
        elem.classList.add('active');
        const slider = getChildNodeIndex(elem);
        const width = parseInt(getComputedStyle(elem).width, 10);

        const sliderElem = refTabs.current.getElementsByClassName('Tabs_mobile_shoot_slider');

        sliderElem[0].style.left = slider.left + 'px';
        sliderElem[0].style.width = width + 'px';
    }

    function getChildNodeIndex(elem) {
        let left = 0;
        let position = 0;
        while ((elem = elem.previousSibling) != null) {
            if (elem.nodeType !== Node.TEXT_NODE) {
                const style = getComputedStyle(elem);

                left += parseFloat(style.width, 10);
                position++;
            }
        }

        setCurrentTab(position);
        return { position, left };
    }

    function init(tabPosition) {
        const items = refTabs.current.getElementsByClassName('Tabs_mobile_shoot_item');

        _.forEach(items, (item, k) => {
            if (k === tabPosition) {
                const timeout = setTimeout(() => {
                    moveSlider(item);
                    clearTimeout(timeout);
                }, 300);
            }
            /* {direction ? `${direction}` : 'Swipe'} */
        });
    }

    useEffect(() => {
        if (props.tabs) {
            _.remove(props.tabs, (n) => {
                return n.visible === false;
            });
            setAllTabs(props.tabs);
        }
        //eslint-disable-next-line
    }, [props.tabs]);

    useEffect(() => {
        if (allTabs.length !== props.tabs.length) init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, [allTabs]);

    useEffect(() => {
        init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCurrentTab(0);
        init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, [location.key]);

    useEffect(() => {
        if (direction) handleSwipe(direction);
        //eslint-disable-next-line
    }, [direction]);

    const absoluteTabs = `w-auto  left-[25%] absolute transition-all duration-300 ease-out top-[0px]`;

    return (
        <>
                <div className='asbolute top-0 left-0 right-0 flex flex-row text-center h-auto p-2'>
                    {/* <div className={`block z-20`}>
                        <Link to={isMobile() ? '/home' : '/'}>
                            <img src={`${config.publicDir}logo_blanc.png`} alt="logo" className="h-8 w-auto" />
                        </Link>
                    </div> */}
                    <div className={`${isMobile() ? absoluteTabs : 'block pt-2'} md:mx-auto bg-transparent z-10`}>
                        <ul className="Tabs_mobile_shoot text-sm flex overflow-x-auto scrollbar-hide" ref={refTabs}>
                            {allTabs
                                ?.filter((i) => i.visible !== false)
                                ?.map((tab, k) => (
                                    <Fragment key={k}>
                                        {tab.visible !== false && (
                                            <li className="Tabs_mobile_shoot_item">
                                                <a className="relative" href={`#${tab.key}`} onClick={(e) => handleClick(e, tab)}>
                                                    {tab.label}
                                                </a>
                                            </li>
                                        )}
                                    </Fragment>
                                ))}
                            <li className="Tabs_mobile_shoot_slider" role="presentation"></li>
                        </ul>
                    </div>
                </div>

                <div className="absolute top-0 left-0 w-full h-full" ref={swipeContainerRef}>
                    {allTabs
                        ?.filter((i) => i.visible !== false)
                        ?.map((tab, k) => (
                            <Fragment key={k}>
                                   {(tab.visible !== false && tab.key !== "tab_favoris") ? (
                                     <>
                                        {k === currentTab && <div className="Tabs_mobile_shoot_content h-full">{tab.content}</div>}
                                     </>
                                   ) : (
                                        <>
                                            <Swiper
                                                style={{ height: '100%' }}
                                                direction="vertical"
                                                slidesPerView="1"
                                                mousewheel={true}
                                                pagination={{ clickable: true }}
                                                scrollbar={{ draggable: true }}
                                                autoHeight={true}
                                            >
                                                {tab.content}
                                            </Swiper>
                                        </>
                                   )}
                            </Fragment>
                        ))}
                </div>
          
        </>
    );
}

export default TabsShoot;
