import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

//API
import { getSimilarRooms } from 'api/search';
import { getSingleRoom, getUsersByTagsRoom } from 'api/forum';
import { useQuery } from 'react-query';
import { getForumAutoAnswer } from 'api/clone';
import { postCounter } from 'api/counters';
//COMPONENTS
import SideNav from 'components/common/nav/SideNav';
import SEO from 'components/Seo';
import ShareOptions from 'components/share/ShareOptions';

//CONTAINERS
import AnswerForm from 'containers/answers/AnswerForm';
import Answers from 'containers/answers/Answers';
import HeadRoom from 'containers/rooms/HeadRoom';
import HeadRoomMobile from 'containers/rooms/HeadRoomMobile';
import JobRoom from 'containers/rooms/JobRoom';
import PoleEmploiHeadRoom from 'containers/rooms/PoleEmploiHeadRoom';
import PoleEmploiJobRoom from 'containers/rooms/PoleEmploiJobRoom';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { SocketContext } from 'context/socketContext';
import { StoreContext } from 'context/storeContext';

//LAYOUT
import RoomLayout from 'pages/layouts/Room';

//LIBS
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//STYLES
import 'App.css';
import 'containers/rooms/room.css';

//UTILS
import { uuid4, cutString, formatTitle } from 'utils/functions';
import { PageContentFadingAnimation } from 'utils/animations';
import useHasCounter from '../../hooks/useHasCounter';

function SingleRoom() {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [authState] = useContext(AuthContext);
    const socket = useContext(SocketContext);
    const store = useContext(StoreContext);

    const [counters, setCounters] = useState([]);

    const currentRoomId = useRef(null);
    const currentMe = useRef(null);
    const refConnected = useRef(null);

    const [autoAnswer, setAutoAnswer] = useState(false);
    const [autoAnswerNotif, setAutoAnswerNotif] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);

    const [shareModal, setShareModal] = useState(false);

    //API
    const room = useQuery(
        ['room', params.slug],
        (params) => {
            return getSingleRoom(params.queryKey[1]);
        },
        { enabled: params?.slug ? true : false, staleTime: 0 }
    );

    const isJobApply = useHasCounter(room?.data?.counters, 'job_apply', room?.data?._id);

    const usersByTagsRoom = useQuery(
        ['usersByTagsRoom', room?.data?.id],
        () => {
            return getUsersByTagsRoom(room?.data?.id);
        },
        { enabled: room?.data?.id && room?.data?.id !== undefined ? true : false, staleTime: 0 }
    );

    const similar = useQuery(['similarRooms', params.slug], () => getSimilarRooms(room.data.id), {
        enabled: room?.data?.id && room?.data?.id !== undefined ? true : false,
        staleTime: 0,
    });

    const initSocket = useCallback(() => {
        socket.on('roomConnect', (data) => {
            currentRoomId.current = data.roomId;
            setTimeout(() => {
                if (refConnected.current) refConnected.current.refetch();
                room.refetch();
            }, 500);
        });
        socket.on('roomLogout', () => {
            room.refetch();
            if (refConnected.current) refConnected.current.refetch();
        });
        socket.on('userConnect', () => {
            room.refetch();
            if (refConnected.current) refConnected.current.refetch();
        });
        socket.on('userLogout', () => {
            leaveRoom();
            if (refConnected.current) refConnected.current.refetch();
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsAdmin(authState.isLogged && authState?.me?.id === room?.data?.user?._id ? true : false);
        //eslint-disable-next-line
    }, [authState.me, room.data]);

    function jobApply() {
        if (room) {
            postCounter({ type: 'job_apply', to: room?.data?._id }).then((res) => {
                room.refetch();
            });
        }
    }

    useEffect(() => {
        return () => {
            store.set({ type: 'del', key: 'currentTaggedUsersRoom' });
            store.set({ type: 'del', key: 'currentSimilarRoom' });
            store.set({ type: 'del', key: 'currentConnectedUsersRoom' });

            leaveRoom();
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        initSocket();
        store.set({ type: 'del', key: 'currentTaggedUsersRoom' });
        store.set({ type: 'del', key: 'currentSimilarRoom' });
        store.set({ type: 'del', key: 'currentConnectedUsersRoom' });

        return () => {
            leaveRoom();
        };
        //eslint-disable-next-line
    }, [, location]);

    useEffect(() => {
        if (room.isSuccess && room.data) {
            setCounters(room.data.counters_answers);

            store.set({
                type: 'add',
                key: 'currentConnectedUsersRoom',
                value: room.data?.connexions,
            });
        }
        //eslint-disable-next-line
    }, [room.data]);

    useEffect(() => {
        if (usersByTagsRoom.isSuccess && usersByTagsRoom.data) {
            store.set({ type: 'add', key: 'currentTaggedUsersRoom', value: usersByTagsRoom.data });
        }
        //eslint-disable-next-line
    }, [usersByTagsRoom.data]);

    useEffect(() => {
        if (similar.isSuccess && similar.data) {
            store.set({ type: 'add', key: 'currentSimilarRoom', value: similar.data });
        }
        //eslint-disable-next-line
    }, [similar.data]);

    useEffect(() => {
        if (
            room.isSuccess &&
            room.data &&
            room.data?.user?._id === authState?.me?.id &&
            room.data?.type === 'question' &&
            !room.data?.anonymousQuestion &&
            !room.data?.aiResponded &&
            room.data?.user?.clone
        ) {
            if (!autoAnswer || localStorage.getItem('autoAnswerClone')) {
                if (!autoAnswerNotif) {
                    toast('Nous allons répondre à votre question par IA, merci de patienter quelques secondes...', { type: 'info' });
                    setAutoAnswerNotif(true);
                }
                const fetchData = async () => {
                    const data = await getForumAutoAnswer(room.data?.id, room.data?.question, room.data?.user?._id);
                    let promise = Promise.resolve(data);
                    promise.then((res) => {
                        if (res.status === 200) {
                            room.refetch();
                            toast('Votre réponse automatique par IA a été ajoutée !', { type: 'success' });
                        }
                        setAutoAnswer(true);
                    });
                };
                localStorage.setItem('autoAnswerClone', true);
                fetchData();
                localStorage.removeItem('autoAnswerClone');
            }
        }
        //eslint-disable-next-line
    }, [room.data]);

    useEffect(() => {
        location.state?.shareModal &&
            setTimeout(() => {
                setShareModal(true);
            }, 1000);
        //eslint-disable-next-line
    }, []);

    useLayoutEffect(() => {
        if (room.isSuccess) {
            currentRoomId.current = room?.data?.id;
            socket.send('joinRoom', { id: room?.data?.id });

            if (authState.me && authState.isLogged) {
                currentMe.current = authState.me;
                socket.send('roomConnect', {
                    id: room?.data?.id,
                    pseudo: authState?.me?.profile?.pseudo,
                    userId: authState?.me?.id,
                });
            }
            if (!authState.isLogged) {
                socket.send('roomConnect', { id: room?.data?.id, anonymeId: uuid4() });
            }
        }
        //eslint-disable-next-line
    }, [authState.me, room.isSuccess]);

    function leaveRoom() {
        room.remove();
        // reset();
        // setOpenConfirmSubmit(false);

        if (currentRoomId.current && currentMe.current)
            socket.send('roomLogout', {
                id: currentRoomId.current,
                pseudo: currentMe.current?.profile?.pseudo,
            });
        else socket.send('roomLogout', { id: currentRoomId.current });

        makeOff();
    }

    function makeOff() {
        socket.off('userLogout');
        socket.off('roomConnect');
        socket.off('roomLogout');
    }

    function handleGoBack() {
        leaveRoom();
        const prevPath = location?.state?.background?.pathname;
        if (prevPath) {
            navigate(prevPath);
        } else {
            navigate('/');
        }
    }

    const handleOpenLogin = useCallback(() => {
        document.getElementById('button-login')?.click();
    }, []);

    return (
        <>
            <RoomLayout>
                {room?.isSuccess && (
                    <SEO
                        title={formatTitle(room?.data?.question)}
                        description={cutString(room?.data?.description, 150)}
                        type="article"
                        name={room?.data?.user?.profile?.pseudo}
                        image={room?.data?.medias[0]?.url}
                    />
                )}
                {location.state?.shareModal === true && shareModal && (
                    <ShareOptions url={location.pathname} title={"Wow 😍 ! Partage ta publication !"} isOpen={shareModal}/>
                )}
                <main>
                    <div className="container">
                        <HeadRoomMobile room={room?.data} refetch={room.refetch} goBack={() => handleGoBack()} />
                        <div className="grid grid-cols-12 gap-7 pb-4 flex-col-reverse md:flex-col">
                            <div className="col-span-12 md:col-span-3 order-last md:order-first">
                                <SideNav />
                            </div>
                            <PageContentFadingAnimation duration={225} className="col-span-12 md:col-span-7">
                                {room?.data?.type === 'job' && room?.data?.poleEmploi?.refId ? (
                                    <>
                                        <PoleEmploiHeadRoom room={room} goBack={() => handleGoBack()} />
                                        <PoleEmploiJobRoom room={room} />
                                    </>
                                ) : (
                                    <>
                                        <div className="sm:pb-4">
                                            <HeadRoom room={room} goBack={() => handleGoBack()} />
                                        </div>
                                        {room?.data?.type === 'job' && <JobRoom room={room} />}
                                    </>
                                )}
                                <div
                                    className={`${
                                        room?.data?.type === 'job'
                                            ? 'relative py-2 w-full bg-transparent'
                                            : 'fixed md:relative -mx-4 px-2 md:px-0 md:mx-0 py-2 w-full md:w-auto md:pb-0 bg-white md:bg-transparent bottom-0 z-10'
                                    }`}
                                >
                                    <AnswerForm
                                        item={room.data}
                                        refetch={room.refetch}
                                        type={'room'}
                                        options={{ placeholder: room?.data?.type === 'job' ? 'Réagissez à cette offre' : null }}
                                    />
                                </div>
                                <div className="sm:mb-20">
                                    {room.data?.answers && <Answers item={room.data} refetch={room.refetch} counters={counters} type={'room'} />}
                                </div>

                                {room.data?.type === 'job' && (
                                    <div className="md:hidden fixed bottom-0 w-full h-auto bg-white rounded-t-3xl -mx-4 px-2 shadow-2xl z-20">
                                        <>
                                            <div className="w-full h-full flex flex-row justify-center items-center py-2">
                                                {!isAdmin && (
                                                    <div className="flex flex-col justify-start md:justify-center">
                                                        <button
                                                            className={`linkCardApplyJobModal px-8 text-base shadow-xl shadow-lightBlueSkills ${
                                                                isJobApply ? 'active' : ''
                                                            }`}
                                                            onClick={
                                                                room.data?.user
                                                                    ? //go to poleEmploi.url
                                                                      () => {
                                                                          authState.isLogged ? jobApply() : handleOpenLogin();
                                                                      }
                                                                    : () =>
                                                                          window.open(
                                                                              room.data?.poleEmploi?.partenaire?.url
                                                                                  ? room.data?.poleEmploi?.partenaire?.url
                                                                                  : room.data?.poleEmploi?.url,
                                                                              '_blank'
                                                                          )
                                                            }
                                                        >
                                                            {!isJobApply ? (
                                                                room.data?.user ? (
                                                                    <>Postuler avec votre profil</>
                                                                ) : (
                                                                    <>Postuler</>
                                                                )
                                                            ) : (
                                                                <>J'ai postulé</>
                                                            )}
                                                        </button>
                                                    </div>
                                                )}

                                                {/*  {isAdmin && listUsersJobApply?.length > 0 && (
                                            <div>
                                                <button className={`linkCardApplyJob purple mb-4`} onClick={() => setModalList(true)}>
                                                    Consulter la liste des réponses à votre offre ( {listUsersJobApply?.length} )
                                                </button>
                                            </div>
                                        )} */}
                                            </div>
                                        </>
                                    </div>
                                )}
                            </PageContentFadingAnimation>
                        </div>
                    </div>
                </main>
            </RoomLayout>
        </>
    );
}

export default SingleRoom;
