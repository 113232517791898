import { useRef, useEffect, useState, useContext } from 'react';

//LIBS
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
//UTILS
import config from 'utils/constants';
import { cutString } from 'utils/functions';

//LAYOUT
import ServiceLayout from '../layouts/Service';

//CONTEXTS
import { StoreContext } from 'context/storeContext';

//API
import { useQuery } from 'react-query';
import { getSingleService } from 'api/services';
import { getUser } from 'api/users';
import { getSimilarServices } from 'api/search';

//CONTAINERS
//import CardServiceSmall from 'containers/services/CardServiceSmall';
//import Tabs from 'components/tabs/Tabs';
import CardUserSmall from 'containers/services/CardUserSmall';
import SeeMore from 'components/common/SeeMore';
//COMPONENTS
import SideNav from 'components/common/nav/SideNav';
import Image from 'components/common/Image';
import Buy from 'components/Buy';
import Share from 'components/share/Share';
import Button from 'components/common/Button';

import CardPortfolio from 'containers/profils/CardPortfolio';
//ICONS
import {
    ArrowNarrowLeftIcon,
    CalendarIcon,
    ClockIcon,
    DotsVerticalIcon,
    HeartIcon,
    // DotsHorizontalIcon,
    LocationMarkerIcon,
    ShoppingBagIcon,
} from '@heroicons/react/solid';
import OptionsMenu from '../../components/common/OptionsMenu';
//import CardFrontService from 'containers/services/CardFrontService';
import CustomSlider from '../../components/slider/CustomSlider';
import CardFrontService from '../../containers/services/CardFrontService';
import { isMobile, isSmartphone } from '../../utils/functions';
import Avatar from '../../containers/profils/Avatar';
import { ShareIcon } from '@heroicons/react/outline';
import SEO from 'components/Seo';

const sizes = {
    avatar: '?w=250&h=250&c=true&q=95',
    cover: '?w=900&q=95',
    coverMobile: '?w=800&h=260&c=true&q=80',
};

function SingleService() {
    const store = useContext(StoreContext);
    //const [sections, setSections] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [similaireLength, setSimilaireLength] = useState(6);
    const [medias, setMedias] = useState([]);

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const refBuy = useRef(null);

    //API
    const service = useQuery(
        ['service', params.slug],
        (params) => {
            return getSingleService(params.queryKey[1]);
        },
        { enabled: params?.slug ? true : false }
    );
    const user = useQuery(
        ['user', service?.data?.user?.profile?.slug],
        (params) => {
            return getUser(params.queryKey[1]);
        },
        { enabled: service?.data?.user?.profile?.slug ? true : false }
    );

    const similar = useQuery(['similarServices', params.slug], () => getSimilarServices(service.data.id), {
        enabled: service?.data?.id ? true : false,
    });

    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }

    useEffect(() => {
        performance.mark('mark_start');
        performance.measure('measure_start', 'mark_start');
        //run every 10 seconds
        const interval = setInterval(() => {
            // eslint-disable-next-line no-unused-vars
            let time = millisToMinutesAndSeconds(performance.now());
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (service.isFetched && !service.isSuccess && service.data === undefined) navigate('/');
    }, [navigate, service, service.isFetched]);

    useEffect(() => {
        if (user.isSuccess && user?.data?.id) {
            /*    logger.log(user?.data?.id);
             */
            store.set({ type: 'del', key: 'currentUser' });
            store.set({ type: 'del', key: 'currentUserService' });

            if (user.data?.profile?.medias) {
                user.data.profile.medias.forEach((element, index) => {
                    user.data.profile.medias[index] = { ...element, url: element.url };
                    store.set({
                        type: 'add',
                        key: 'currentUser',
                        value: { user: user.data, medias: user.data.profile.medias },
                    });
                });
            }

            if (user.data) {
                store.set({ type: 'add', key: 'currentUserService', value: { user: user.data } });
            }
        }
        //eslint-disable-next-line
    }, [user.data]);

    useEffect(() => {
        if (similar.isSuccess && similar.data) {
            //store.set({type: 'ready'});
            //store.set({ type: 'add', key: 'navbar_subjects', value: similar.data });

            store.set({ type: 'add', key: 'currentSimilarService', value: similar.data });
            /*  const sectionSimilarServices = (
                <>
                    {similar?.data?.map((similarService, key) => (
                        <CardServiceSmall key={key} service={similarService} />
                    ))}
                </>
            );
 */
            /* setSections([
                {
                    key: 'similarServices',
                    title: 'Services similaires',
                    content: sectionSimilarServices,
                },
            ]);
        } else {
            setSections([]); */
        }
        //eslint-disable-next-line
    }, [similar.data]);

    useEffect(() => {
        return () => {
            store.set({ type: 'del', key: 'currentUser' });
            store.set({ type: 'del', key: 'currentUserService' });
            store.set({ type: 'del', key: 'currentSimilarService' });
        };
        //eslint-disable-next-line
    }, []);

    function closeOptionsMenu(service) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${service.id}`) {
                el.classList.add('hidden');
            }
        });
    }

    function handleOptionsMenu(service) {
        closeOptionsMenu(service);
        document.getElementById(`optionsMenu_${service.id}`).classList.toggle('hidden');
    }

    function openDiscussion() {
        store.set({ type: 'add', key: 'openChat', value: { open: true, userId: user.data?.id } });
    }

    // eslint-disable-next-line no-unused-vars
    function handleShowMoreSimilarServices(e, show) {
        e.preventDefault();
        switch (show) {
            case 'all':
                setSimilaireLength(similar?.data?.length);
                break;
            case 'less':
                setSimilaireLength(6);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (service.data?.medias) {
            const sortedMedias = [...service.data.medias].sort((a, b) => {
                if (a.isFirst && !b.isFirst) return -1;
                if (!a.isFirst && b.isFirst) return 1;
                return 0;
            });

            setMedias(sortedMedias);
        }
    }, [service.data]);

    const tabBasic = (
        <>
            <div>Test</div>
        </>
    );

    const tabStandard = (
        <>
            <div>qsdqsd</div>
        </>
    );

    const tabPremium = (
        <>
            <div>qsdqsd</div>
        </>
    );

    // eslint-disable-next-line no-unused-vars
    const tabs = [
        { label: 'Basic', key: 'tab_basic', content: tabBasic },
        { label: 'Standard', key: 'tab_standard', content: tabStandard },
        { label: 'Premium', key: 'tab_premium', content: tabPremium },
    ];

    //cut string after 150 characters

    return (
        <>
            <SEO
                title={service?.data?.title}
                description={cutString(service?.data?.description, 150)}
                type="article"
                name={service?.data?.user?.profile?.pseudo}
                image={service?.data?.medias[0]?.url}
            />
            <ServiceLayout>
                <div className="App">
                    <main className="container-fluid">
                        <div className="w-full flex flex-col-reverse lg:flex-row gap-7 mb-24 sm:pb-4">
                            {/* <div className="col-span-12 md:col-span-3 order-last md:order-first"> */}
                            <div className="w-full lg:w-[10rem]">
                                <SideNav />
                            </div>
                            <div className="w-full sm:mb-24 lg:mb-0">
                                <div className="w-full h-full">
                                    <>
                                        {/* <div className='w-full h-full col-span-5 mt-6'>
                                            <div className='w-full h-full'>
                                                <div className="flex justify-end gap-2 relative">
                                                    <Button
                                                        onClick={() => handleOptionsMenu(service?.data)}
                                                        label={<DotsVerticalIcon className="w-7 h-7 hover:text-blueMain" />}
                                                        justIcon={true}
                                                         css="text-graySkills"
                                                    />
                                                    <div className="relative -top-3">
                                                        <OptionsMenu service={service?.data} user={service?.data?.user} type="service" />
                                                    </div>
                                                </div>
                                            </div>
                                         </div> */}
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col main-service w-full h-full">
                                                <div className="grid grid-cols-12 lg:gap-4">
                                                    <div className="relative w-full h-full col-span-12 lg:col-span-5 order-2">
                                                        <div
                                                            className={`sticky sm:top-80 p-1 w-full lg:mt-4 bg-white rounded-3xl shadow-sm shadow-lightBlueSkills hover:shadow-xl hover:shadow-lightBlueSkills transition-shadow duration-400`}
                                                        >
                                                            <div className="flex justify-end gap-2 relative mt-2 mb-2">
                                                                <Share color={'text-[#2e3e5c]'} url={location.pathname} />
                                                                <Button
                                                                    onClick={() => handleOptionsMenu(service?.data)}
                                                                    label={<DotsVerticalIcon className="w-6 h-6 text-[#2e3e5c] hover:text-blueMain" />}
                                                                    justIcon={true}
                                                                    css="text-graySkills"
                                                                />
                                                                <div className="relative -top-3">
                                                                    <OptionsMenu service={service?.data} user={service?.data?.user} type="service" />
                                                                </div>
                                                            </div>
                                                            <div className="px-4">
                                                                <div className="w-full">
                                                                    <h1 className="font-bold break-words text-xl text-purpleSkills">{service.data?.title}</h1>
                                                                </div>
                                                                <div className="flex items-center justify-between pb-4">
                                                                    {/* <h1>1 min video edition</h1> */}
                                                                    {!!service.data?.type && (
                                                                        <span className="font-bold text-lg text-[#2e3e5c]">
                                                                            <span>
                                                                                {service.data?.type}
                                                                                {!!service.data?.price ? ` à ${service.data?.price}€` : ''}
                                                                            </span>
                                                                        </span>
                                                                    )}
                                                                    {/*  {!!service.data?.price && (
                                                                        <span className='font-bold text-lg text-[#2e3e5c]'>{service.data?.price}€</span>
                                                                    )} */}
                                                                </div>

                                                                {(!!service.data?.time && service.data?.timeType) ||
                                                                !!service.data?.place ||
                                                                (!!service.data?.sessions && service.data?.sessions?.length > 0) ? (
                                                                    <>
                                                                        {((!!service.data?.time && service.data?.timeType) || !!service.data?.place) && (
                                                                            <div className="flex flex-row gap-2 mb-1">
                                                                                {!!service.data.time && service.data.timeType && (
                                                                                    <div className="flex flew-row gap-1 text-[#2e3e5c]">
                                                                                        <ClockIcon className="w-5 h-5 relative" />
                                                                                        <span className="text-sm">
                                                                                            <span className="">{service.data.time} </span>
                                                                                            {service.data.timeType}
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                                {!!service.data.place && (
                                                                                    <div className="flex flew-row gap-1 text-[#2e3e5c]">
                                                                                        <LocationMarkerIcon className="w-5 h-5 relative" />
                                                                                        <span className="text-sm">
                                                                                            <span>{service.data.place}</span>
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {!!service.data.sessions && (
                                                                            <>
                                                                                {service.data.sessions.map((session, key) => (
                                                                                    <div key={key} className="flex flew-row gap-1 text-[#2e3e5c] mb-1">
                                                                                        <CalendarIcon className="w-5 h-5 relative" />
                                                                                        <span className="text-sm">
                                                                                            <span>
                                                                                                {session.date} à {session.time.replace(':', 'h')}
                                                                                            </span>
                                                                                        </span>
                                                                                    </div>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                            <div className="pt-4 pb-3 px-2 w-full h-auto">
                                                                {!!service.data?.price ? (
                                                                    <>
                                                                        <div className="px-2">
                                                                            <>
                                                                                <div className="flex flex-row justify-center">
                                                                                    <button
                                                                                        className="flex items-center justify-center gap-2 linkCardShop"
                                                                                        onClick={() => {
                                                                                            refBuy.current.buy();
                                                                                        }}
                                                                                    >
                                                                                        <ShoppingBagIcon className="w-6 h-6" />
                                                                                        <span>Acheter le service à {service.data.price} €</span>
                                                                                    </button>
                                                                                    <Buy ref={refBuy} type="service" item={service.data} />
                                                                                </div>
                                                                                {/* <Share url={location.pathname} /> */}
                                                                                {/* <div className="flex"> */}
                                                                                {/*     <button */}
                                                                                {/*         className="text-graySkills" */}
                                                                                {/*         onClick={() => { */}
                                                                                {/*             return; */}
                                                                                {/*         }} */}
                                                                                {/*     > */}
                                                                                {/*         <DotsHorizontalIcon className="w-5 h-5" /> */}
                                                                                {/*     </button> */}
                                                                                {/* </div> */}
                                                                            </>
                                                                        </div>
                                                                        {/* <div className=""> */}
                                                                        {/* <p className="text-purpleSkills text-xs">Achat bientôt disponible</p> */}
                                                                        {/* </div> */}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {/* <div className=" flex gap-4 items-center">
                                                                            <>
                                                                                <div className="flex flex-row justify-center">
                                                                                    <button
                                                                                        className="flex items-center gap-2 linkCardShop"
                                                                                        onClick={() => { refBuy.current.buy();}}
                                                                                    >
                                                                                        <ShoppingBagIcon className="w-5 h-5" />
                                                                                        <span onClick={() => openDiscussion()}>Demander un devis</span>
                                                                                    </button>
                                                                                    <Buy ref={refBuy} type="service" item={service.data} /> 
                                                                                </div>
                                                                                <Share url={location.pathname} />
                                                                            </>
                                                                        </div> */}
                                                                        <div className="w-full">
                                                                            <>
                                                                                <div className="flex flex-row justify-center">
                                                                                    <button
                                                                                        className="flex justify-center items-center gap-2 linkCardShop"
                                                                                        onClick={() => openDiscussion()}
                                                                                    >
                                                                                        <ShoppingBagIcon className="w-6 h-6" />
                                                                                        <span>Demander un devis</span>
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="w-full px-2 pb-2">
                                                                <button className="flex items-center linkCardContact" onClick={() => openDiscussion()}>
                                                                    <Avatar className="flex w-10 h-10" gamification={false} user={service?.data?.user} />
                                                                    <span className="flex-grow text-center">Contactez-moi</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-12 lg:col-span-7 order-first mb-8 mt-1 lg:mb-14">
                                                        {service.isSuccess && (
                                                            <>
                                                                {service.isSuccess && !medias.length > 0 && (
                                                                    <div className="banner -mx-2 sm:mt-6 sm:mx-0">
                                                                        <div className="relative w-full">
                                                                            <button
                                                                                className="sm:hidden absolute w-10 h-10 left-2 top-2 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills z-10 text-white flex items-center justify-center"
                                                                                onClick={() => navigate(-1)}
                                                                            >
                                                                                <ArrowNarrowLeftIcon className="w-6 h-6" />
                                                                            </button>
                                                                            <button
                                                                                className="sm:hidden absolute w-10 h-10 right-14 top-2 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills z-10 text-white flex items-center justify-center"
                                                                                onClick={() => {}}
                                                                            >
                                                                                <ShareIcon className="w-5 h-5" />
                                                                            </button>
                                                                            <button
                                                                                className="sm:hidden absolute w-10 h-10 right-2 top-2 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills z-10 text-white flex items-center justify-center"
                                                                                onClick={() => {}}
                                                                            >
                                                                                <HeartIcon className="w-5 h-5" />
                                                                            </button>
                                                                            {service.data?.cover?.url ? (
                                                                                <Image
                                                                                    src={service.data?.cover?.url + sizes.cover}
                                                                                    dynamic
                                                                                    alt={service.data?.title}
                                                                                    loading="false"
                                                                                    className={`w-full h-[12rem] sm:h-[14rem] relative ${
                                                                                        isMobile() ? '' : 'mt-8 sm:mt-0'
                                                                                    } sm:rounded-3xl object-cover`}
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    className={`w-full sm:rounded-3xl h-[12rem] sm:h-[14rem] relative ${
                                                                                        isMobile() ? '' : 'mt-8 sm:mt-0'
                                                                                    }`}
                                                                                    src={`${config.publicDir}banniere_skillsdefault2.png` + sizes.cover}
                                                                                    alt={service.data?.title}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className="flex flex-col-reverse sm:flex-col">
                                                                    {service.isSuccess && (
                                                                        <>
                                                                            <div className="w-full h-full flex flex-col-reverse sm:flex-col">
                                                                                <div className="flex gap-1 mt-2 sm:mt-6 sm:mb-6">
                                                                                    {/* <div className="flex flex-col w-full">
                                                                                        <div className=''>
                                                                                            <h1 className="font-bold break-words text-xl md:text-[1.70rem] text-purpleSkills">{service.data?.title}</h1>
                                                                                        </div>
                                                                                        <div className="flex flex-wrap -mx-2">
                                                                                            {service.data?.tags?.map((skill, index) => (
                                                                                                <Link to={`/search?q=${skill.name}`} key={index} className="link_basic mb-1">
                                                                                                    {skill.name}
                                                                                                </Link>
                                                                                            ))}
                                                                                        </div> 
                                                                                    </div> */}

                                                                                    <div className="w-full">
                                                                                        <h1 className="font-bold break-words text-xl md:text-[1.70rem] text-purpleSkills">
                                                                                            {service.data?.title}
                                                                                        </h1>
                                                                                        {/*  {!!service.data.type && (          
                                                                                            <span className="font-bold text-sm sm:text-lg text-graySkills">
                                                                                                <span>{service.data.type}</span>
                                                                                            </span>
                                                                                        )} */}
                                                                                    </div>
                                                                                    {/*  <div className="relative">
                                                                                        <Button
                                                                                            onClick={() => handleOptionsMenu(service?.data)}
                                                                                            label={<DotsVerticalIcon className="w-6 h-6 sm:w-7 sm:h-7 hover:text-blueMain" />}
                                                                                            justIcon={true}
                                                                                            css="text-graySkills"
                                                                                        />
                                                                                        <div className="relative -top-3">
                                                                                            <OptionsMenu service={service?.data} user={service?.data?.user} type="service" />
                                                                                        </div>
                                                                                    </div>  */}
                                                                                </div>
                                                                                <div className="-mx-2 sm:mx-2 px-2 sm:px-0 border-b sm:border-none">
                                                                                    <CardUserSmall banner={false} user={service?.data?.user} />
                                                                                </div>
                                                                            </div>
                                                                            {medias.length > 0 && (
                                                                                <div className="-mx-2 sm:mx-2">
                                                                                    <button
                                                                                        className="sm:hidden absolute w-10 h-10 left-2 top-2 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills z-10 text-white flex items-center justify-center"
                                                                                        onClick={() => navigate(-1)}
                                                                                    >
                                                                                        <ArrowNarrowLeftIcon className="w-6 h-6" />
                                                                                    </button>
                                                                                    <button
                                                                                        className="sm:hidden absolute w-10 h-10 right-14 top-2 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills z-10 text-white flex items-center justify-center"
                                                                                        onClick={() => {}}
                                                                                    >
                                                                                        <ShareIcon className="w-5 h-5" />
                                                                                    </button>
                                                                                    <button
                                                                                        className="sm:hidden absolute w-10 h-10 right-2 top-2 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills z-10 text-white flex items-center justify-center"
                                                                                        onClick={() => {}}
                                                                                    >
                                                                                        <HeartIcon className="w-5 h-5" />
                                                                                    </button>
                                                                                    <CustomSlider
                                                                                        width={'w-full h-80 sm:h-auto'}
                                                                                        user={service?.data?.user}
                                                                                        medias={medias}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {/*  <div className="">
                                                                    <CardUserSmall banner={false} user={service?.data?.user} />
                                                                </div>  */}
                                                                {/* {medias.length > 0 && (
                                                                    <CustomSlider width={"w-full"} user={service?.data?.user} medias={medias} />
                                                                )} */}
                                                                <div className="w-full items-center sm:mb-6">
                                                                    <div className="flex flex-col gap-2 mt-2">
                                                                        {/*  <div className="col-start-1">
                                                                            {!!service.data.type && (
                                                                                <div className="flex flew-row text-graySkills">
                                                                                    <span className="font-bold text-sm: sm:text-lg ">
                                                                                        <span>{service.data.type}</span>
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </div> */}
                                                                        {/* {(!!service.data.time && service.data.timeType) 
                                                                            || !!service.data.place 
                                                                            || (!!service.data.sessions && service.data.sessions.length > 0)
                                                                        ? (
                                                                            <div className="flex flex-col justify-start md:flex-row gap-4 mb-4">
                                                                                {!!service.data.time && service.data.timeType && (
                                                                                    <div className="flex flew-row gap-1 text-graySkills">
                                                                                        <ClockIcon className="w-5 h-5 relative" />
                                                                                        <span className="text-sm">
                                                                                            <span className="">{service.data.time} </span>
                                                                                            {service.data.timeType}
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                                {!!service.data.place && (
                                                                                    <div className="flex flew-row gap-1 text-graySkills">
                                                                                        <LocationMarkerIcon className="w-5 h-5 relative" />
                                                                                        <span className="text-sm">
                                                                                            <span>{service.data.place}</span>
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                                {!!service.data.sessions && (
                                                                                    <>
                                                                                        {service.data.sessions.map((session, key) => (
                                                                                            <div key={key} className="flex flew-row gap-1 text-graySkills">
                                                                                                <CalendarIcon className="w-5 h-5 relative" />
                                                                                                <span className="text-sm">
                                                                                                    <span>
                                                                                                        {session.date} à {session.time.replace(':', 'h')}
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        ) : null } */}
                                                                        {/*  {!!service.data.public && (
                                                                            <div className="flex flew-row text-graySkills">
                                                                                <UserGroupIcon className="w-4 h-4 relative top-0.5 mr-1" />
                                                                                <span className="text-sm">
                                                                                    <span>{service.data.public}</span>
                                                                                </span>
                                                                            </div>
                                                                        )} */}
                                                                        {/*  {!!service.data.funding && (
                                                                            <div className="flex flew-row text-graySkills">
                                                                                <CurrencyEuroIcon className="w-4 h-4 relative top-0.5 mr-1" />
                                                                                <span className="text-sm">
                                                                                    <span>{service.data.funding}</span>
                                                                                </span>
                                                                            </div>
                                                                        )} */}
                                                                        {/*  {!!service.data?.description && (         
                                                                            <div
                                                                                className="content mt-4"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: service.data?.description ?? service.data?.programme,
                                                                                }}
                                                                            />
                                                                           
                                                                                    
                                                                        )} */}
                                                                        {isSmartphone() ? (
                                                                            <>
                                                                                {!!service.data?.description && (
                                                                                    <>
                                                                                        <SeeMore
                                                                                            height={'h-[10rem]'}
                                                                                            className={'text-sm leading-relaxed'}
                                                                                            string={service.data?.description ?? service.data?.programme}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {!medias.length > 0 && !!service?.data?.video ? (
                                                                                    <SeeMore
                                                                                        height={'h-[10rem]'}
                                                                                        className={'text-sm leading-relaxed'}
                                                                                        string={service.data?.description ?? service.data?.programme}
                                                                                    />
                                                                                ) : (
                                                                                    <div
                                                                                        className="text-sm leading-relaxed card-item-question-expanded"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: service.data?.description ?? service.data?.programme,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {!!service.data?.video && (
                                                                    <div className="relative aspect-video mt-2 mb-8 lg:mt-0 md:w-full p-0 md:h-92">
                                                                        <ReactPlayer
                                                                            url={service.data?.video}
                                                                            width="100%"
                                                                            height="100%"
                                                                            origin={window.location.origin}
                                                                            className="rounded-3xl overflow-hidden aspect-video"
                                                                            pip
                                                                            controls
                                                                        />
                                                                    </div>
                                                                )}
                                                                {user.isSuccess && (
                                                                    <div className="hidden col-span-12 lg:col-span-7 order-last w-full lg:flex flex-col gap-8">
                                                                        <div className="">
                                                                            <h1 className="font-bold text-xl text-purpleSkills">À propos du Vendeur</h1>
                                                                            <div className="-mx-2 sm:mx-2 sm:px-0 border-b sm:border-none">
                                                                                <CardUserSmall banner={false} user={service?.data?.user} />
                                                                            </div>
                                                                            {user.data?.profile?.presentation && (
                                                                                <>
                                                                                    {/* {isSmartphone() ? (
                                                                                        <SeeMore className={"text-sm leading-relaxed"} string={user.data.profile.presentation} /> 
                                                                                    ) : (
                                                                                        <div
                                                                                            className="text-sm leading-relaxed"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: user.data.profile.presentation,
                                                                                            }}
                                                                                        />
                                                                                    )} */}
                                                                                    {isSmartphone() ? (
                                                                                        <>
                                                                                            {!!service.data?.description && (
                                                                                                <>
                                                                                                    <SeeMore
                                                                                                        height={'h-[10rem]'}
                                                                                                        className={'text-sm leading-relaxed'}
                                                                                                        string={user.data?.profile?.presentation}
                                                                                                    />
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {!medias.length > 0 && !!service?.data?.video ? (
                                                                                                <SeeMore
                                                                                                    height={'h-[10rem]'}
                                                                                                    className={'text-sm leading-relaxed'}
                                                                                                    string={user.data?.profile?.presentation}
                                                                                                />
                                                                                            ) : (
                                                                                                <div
                                                                                                    className="text-sm whitespace-pre-wrap leading-relaxed card-item-question-expanded w-full"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: user.data?.profile?.presentation,
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        {user.data?.profile?.medias?.length > 0 && (
                                                                            <div className="">
                                                                                <h1 className="font-bold text-xl text-purpleSkills mb-2">Portfolio</h1>
                                                                                <div className="grid grid-cols-4 gap-1">
                                                                                    {user.data?.profile?.medias?.slice(0, 8).map((media, index) => (
                                                                                        <CardPortfolio key={index} media={media} user={user.data} />
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {similar?.data && similar?.data?.length > 0 && (
                                                                            <>
                                                                                <div className="similar-service w-full h-full">
                                                                                    <h2 className="font-bold text-xl text-purpleSkills">Services Similaires</h2>
                                                                                    <div
                                                                                        className="grid grid-cols-2 md:grid-cols-3 auto-cols-max gap-x-4 gap-2"
                                                                                        style={{ maxWidth: '100%' }}
                                                                                    >
                                                                                        {similar?.data?.slice(0, similaireLength).map((similarService, key) => (
                                                                                            <div className="card card_profile w-full" key={key}>
                                                                                                <CardFrontService service={similarService} />
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                    {similar?.data?.length > 6 && (
                                                                                        <>
                                                                                            <div className="flex justify-center mt-4">
                                                                                                {similaireLength <= 6 ? (
                                                                                                    <button
                                                                                                        className="hover:text-blueMain text-purpleSkills font-bold cursor-pointer text-right text-sm"
                                                                                                        onClick={(e) => handleShowMoreSimilarServices(e, 'all')}
                                                                                                    >
                                                                                                        Voir plus
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <button
                                                                                                        className="hover:text-blueMain text-purpleSkills font-bold cursor-pointer text-right text-sm"
                                                                                                        onClick={(e) =>
                                                                                                            handleShowMoreSimilarServices(e, 'less')
                                                                                                        }
                                                                                                    >
                                                                                                        Voir moins
                                                                                                    </button>
                                                                                                )}
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                    {user.isSuccess && (
                                                        <div className="flex col-span-12 lg:col-span-7 order-last w-full lg:hidden flex-col gap-8 mt-8">
                                                            <div className="">
                                                                <h1 className="font-bold text-xl text-purpleSkills">À propos du Vendeur</h1>
                                                                <div className="mx-2 sm:px-0 border-b sm:border-none mb-2">
                                                                    <CardUserSmall banner={false} user={service?.data?.user} />
                                                                </div>
                                                                {user.data?.profile?.presentation && (
                                                                    <>
                                                                        {isSmartphone() ? (
                                                                            <>
                                                                                {!!service.data?.description && (
                                                                                    <>
                                                                                        <SeeMore
                                                                                            height={'h-[10rem]'}
                                                                                            className={'text-sm leading-relaxed'}
                                                                                            string={user.data?.profile?.presentation}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {!medias.length > 0 && !!service?.data?.video ? (
                                                                                    <SeeMore
                                                                                        height={'h-[10rem]'}
                                                                                        className={'text-sm leading-relaxed'}
                                                                                        string={user.data?.profile?.presentation}
                                                                                    />
                                                                                ) : (
                                                                                    <div
                                                                                        className="text-sm whitespace-pre-wrap leading-relaxed card-item-question-expanded w-full"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: user.data?.profile?.presentation,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                            {user.isSuccess && user.data?.profile?.medias?.length > 0 && (
                                                                <div className="">
                                                                    <h1 className="font-bold text-xl text-purpleSkills mb-2">Portfolio</h1>
                                                                    <div className="grid grid-cols-4 gap-1">
                                                                        {user.data?.profile?.medias?.slice(0, 8).map((media, index) => (
                                                                            <CardPortfolio key={index} media={media} user={user.data} />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {similar?.data && similar?.data?.length > 0 && (
                                                                <>
                                                                    <div className="similar-service w-full h-full">
                                                                        <h2 className="font-bold text-xl text-purpleSkills">Services Similaires</h2>
                                                                        <div
                                                                            className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-2"
                                                                            style={{ maxWidth: '100%' }}
                                                                        >
                                                                            {similar?.data?.slice(0, similaireLength).map((similarService, key) => (
                                                                                <div className="card card_profile w-full" key={key}>
                                                                                    <CardFrontService service={similarService} />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        {similar?.data?.length > 6 && (
                                                                            <>
                                                                                <div className="flex justify-center mt-4">
                                                                                    {similaireLength <= 6 ? (
                                                                                        <button
                                                                                            className="hover:text-blueMain text-purpleSkills font-bold cursor-pointer text-right text-sm"
                                                                                            onClick={(e) => handleShowMoreSimilarServices(e, 'all')}
                                                                                        >
                                                                                            Voir plus
                                                                                        </button>
                                                                                    ) : (
                                                                                        <button
                                                                                            className="hover:text-blueMain text-purpleSkills font-bold cursor-pointer text-right text-sm"
                                                                                            onClick={(e) => handleShowMoreSimilarServices(e, 'less')}
                                                                                        >
                                                                                            Voir moins
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                    {/* <div className='w-full h-full col-span-5 mt-6'>
                                                        <div className='w-full h-full'>
                                                            <div className="flex justify-end gap-2 relative">
                                                                <Button
                                                                    onClick={() => handleOptionsMenu(service?.data)}
                                                                    label={<DotsVerticalIcon className="w-7 h-7 hover:text-blueMain" />}
                                                                    justIcon={true}
                                                                    css="text-graySkills"
                                                                />
                                                                <div className="relative -top-3">
                                                                    <OptionsMenu service={service?.data} user={service?.data?.user} type="service" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  */}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className="md:hidden -mx-2 fixed bottom-0 w-full h-auto bg-white rounded-t-3xl shadow-2xl z-20">
                            {!!service.data?.price ? (
                                <div className="flex items-center gap-2 justify-around py-3 px-2">
                                    <div className="flex-1">
                                        <button
                                            className="h-10 gap-2 inline-flex items-center justify-center border rounded-3xl w-full px-1 bg-blancSkills text-purpleSkills font-bold"
                                            onClick={() => openDiscussion()}
                                        >
                                            <Avatar className="flex w-8 h-8" gamification={false} user={service?.data?.user} />
                                            <span className="flex-grow text-center">Contactez-moi</span>
                                        </button>
                                    </div>
                                    <div className="flex-1">
                                        <button
                                            className="h-10 gap-2 inline-flex items-center justify-center border rounded-3xl w-full px-2 pl-2 bg-blueMain text-white font-bold"
                                            onClick={() => {
                                                refBuy.current.buy();
                                            }}
                                        >
                                            <ShoppingBagIcon className="flex w-6 h-6" />
                                            <span className="flex-grow text-center">Acheter à {service.data.price} €</span>
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex items-center justify-center py-3 px-2">
                                    <div className="">
                                        <button
                                            className="h-10 gap-2 inline-flex items-center justify-center border rounded-3xl w-auto px-2 pl-2 bg-blueMain text-white font-bold"
                                            onClick={() => openDiscussion()}
                                        >
                                            <ShoppingBagIcon className="flex w-6 h-6" />
                                            <span className="flex-grow text-center">Demander le prix</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </main>
                </div>
            </ServiceLayout>
        </>
    );
}

export default SingleService;
