import { useCallback, useState, useRef, useContext, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import config from 'utils/constants';
//UTILS
import { isMobile } from 'utils/functions';
import { countByKey } from 'utils/functions';
//LIBS
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';

//API
//import { getNotifications } from 'api/discussions';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';

//HOOKS
//import UseAuth from 'hooks/useAuth';
//import useNotifications from 'hooks/useNotifications';

//ICONS
import { UserIcon, StarIcon } from '@heroicons/react/solid';

//COMPONENTS
import Button from 'components/common/Button';
import Notifications from 'components/common/Notifications';
import Modal from 'components/common/Modal';
import MainSearchBar from './mainSearch/MainSearchBar';
import FormConnexion from 'components/forms/Connexion';
//import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';
import Image from './Image';
import SearchFiltersV2 from 'containers/search/SearchFiltersV2';
import BuyCloneAi from 'components/common/BuyCloneAi';
import Avatar from '../../containers/profils/Avatar';
import FieldSelect from 'components/forms/fields/Select';

const sizes = {
    avatar: '?w=60&c=true&q=80',
};

function MainNavSearch(props) {
    //const location = useLocation();
    const [isConnexionOpen, setIsConnexionOpen] = useState(false);
    const [openedMenu, setOpenedMenu] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [isPlanOpen, setIsPlanOpen] = useState(false);
    const [isAlreadyPremium, setIsAlreadyPremium] = useState(false);
    const [modalConfirmLabel, setModalConfirmLabel] = useState('Valider');

    const [isJobPage, setIsJobPage] = useState(false);
    const [job_contrat, setJobContrat] = useState('Tous les contrats');
    const [job_durer, setJobDurer] = useState('Toutes les durées');

    const {
        register,
        //handleSubmit,
        //watch,
        formState: { errors },
        //setValue,
        //getValues,
    } = useForm();

    //CONTEXTS
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);

    //HOOKS
    const navigate = useNavigate();
    const location = useLocation();
    //const { logout } = UseAuth();
    //const discussionNotifications = useNotifications(getNotifications);

    //REFS
    const loginRef = useRef(null);
    const timeout = useRef(null);

    /*  const [isHomeLocation, setIsHomeLocation] = useState(false); */
    /*  const [isSearchLocation, setIsSearchLocation] = useState(false); */

    const handleOpenConnexion = useCallback((e) => {
        e.preventDefault();
        setOpenedMenu(false);
        setIsConnexionOpen(true);
        setIsLogged(false);
    }, []);

    const handleOpenSignup = useCallback((e) => {
        e.preventDefault();
        setOpenedMenu(false);
        setIsConnexionOpen(true);
        setIsLogged(false);

        timeout.current = setTimeout(() => {
            loginRef.current.openSignup();
        }, 200);

        return () => clearTimeout(timeout.current);
    }, []);

    /*  const handleOpenChat = useCallback(() => {
        store.set({ type: 'add', key: 'discussionType', value: 'standard' });
        store.set({ type: 'add', key: 'openChat', value: { open: true } });
    }, [store]); */

    const handleLogin = useCallback(() => {
        loginRef.current.login();
    }, []);

    const handleGoToProfile = useCallback(() => {
        navigate('/dashboard/profil');
    }, [navigate]);

    const handleGoToSearch = useCallback(() => {
        if (location.pathname !== '/search') {
            navigate('/search');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    /*  useEffect(() => {
        if (location.pathname === '/') {
            setIsHomeLocation(true);
        } else {
            setIsHomeLocation(false);
        }
    }, [location.pathname]); */

    useEffect(() => {
        if (authState.isLogged) setIsConnexionOpen(false);
        if (authState?.me?.plans && authState?.me?.plans.length > 0) {
            if (authState?.me?.plans.some((plan) => plan.type === 'chatAi' && plan.status === 'succeeded')) {
                setIsAlreadyPremium(true);
            }
        }
        //eslint-disable-next-line
    }, [authState.isLogged]);

    useEffect(() => {
        store.set({ type: 'add', key: 'isConnexionOpen', value: isConnexionOpen });
        //eslint-disable-next-line
    }, [isConnexionOpen]);

    useEffect(() => {
        if (location.pathname === '/jobs') {
            setIsJobPage(true);
        }
        //eslint-disable-next-line
    }, [location.pathname]);

    /*   useEffect(() => {
        setIsSearchLocation(location.pathname === '/search' ? true : false);
    }, [location.pathname]);
 */
    const openMenu = useCallback(() => {
        setOpenedMenu(openedMenu ? false : true);
        //eslint-disable-next-line
    }, [openedMenu]);

    /*   const handleLogout = useCallback(() => {
        setOpenedMenu(false);
        logout();
        //eslint-disable-next-line
    }, []); */
    function closeJobDetail() {
        const currentState = window.history.state;

        if (currentState && currentState.originalUrl) {
            window.history.replaceState({}, '', currentState.originalUrl);
        }
        store.set({ type: 'del', key: 'openJobDetail' });
    }

    function onLogged(status) {
        setIsLogged(status);
    }

    function filterJobsByContrat(filter) {
        let contract;
        switch (filter) {
            case 'CDI':
                setJobContrat('CDI');
                contract = 'CDI';
                break;
            case 'CDD':
                setJobContrat('CDD');
                contract = 'CDD';
                break;
            case 'MIS':
                setJobContrat('MIS / Intérim');
                contract = 'MSI';
                break;
            case 'Stage':
                setJobContrat('Stage');
                contract = 'Stage';
                break;
            case 'Alternance':
                setJobContrat('Alternance');
                contract = 'Alternance';
                break;
            case 'Freelance':
                setJobContrat('Freelance');
                contract = 'Freelance';
                break;
            default:
                setJobContrat('Tous les contrats');
                break;
        }
        navigate(`/jobs${location?.state?.search ? '?q=' + location?.state?.search : ''}`, {
            state: {
                search: location?.state?.search ? location?.state?.search : '',
                place: location?.state?.place,
                contract: contract,
                type: location?.state?.type,
            },
            replace: true,
        });
    }

    function filterJobsByDurer(filter) {
        let duration;
        switch (filter) {
            case 'TempsPlein':
                setJobDurer('Temps plein');
                duration = 'Temps plein';
                break;
            case 'TempsPartiel':
                setJobDurer('Temps partiel');
                duration = 'Temps partiel';
                break;
            default:
                setJobDurer('Toutes les durées');
                break;
        }
        navigate(`/jobs${location?.state?.search ? '?q=' + location?.state?.search : ''}`, {
            state: {
                search: location?.state?.search ? location?.state?.search : '',
                place: location?.state?.place,
                contract: location?.state?.contract,
                type: duration,
            },
            replace: true,
        });
    }

    return (
        <>
            {isPlanOpen && <BuyCloneAi state={isPlanOpen} setState={setIsPlanOpen} />}
            <button id="button-login" className="hidden" onClick={handleOpenConnexion}></button>
            <button id="button-signup" className="hidden" onClick={handleOpenSignup}></button>
            <nav className="container mx-auto z-20 px-2 sm:px-4">
                {/* <div className="flex flex-row justify-between items-center md:flex-none w-auto"> */}
                <div className={`container`}>
                    <div className={`hidden sm:grid grid-cols-12 gap-8 pt-3`}>
                        <div className="col-span-3" onClick={closeJobDetail}>
                            <Link to={isMobile() ? '/home' : '/'}>
                                <img src={`${config.publicDir}logo_skillsmobile.png`} alt="logo" className="h-10" />
                            </Link>
                        </div>
                        <div className={`col-span-9 flex flex-col gap-2`}>
                            <div className={`flex flex-row justify-between gap-6 w-full`}>
                                <div
                                    className="w-full"
                                    onClick={() => {
                                        if (location.pathname !== '/jobs') {
                                            handleGoToSearch();
                                        }
                                    }}
                                >
                                    <MainSearchBar isInNavBar={true} />
                                </div>

                                <div className={`flex flex-row ${!props?.isSearchLocation && 'items-center'}`}>
                                    {authState.isLogged && (
                                        <>
                                            {/*  <div className="pr-2">
                                                <div className="relative">
                                                    <Button
                                                        label={<ChatIcon className="w-5 h-5 mx-auto" />}
                                                        justIcon={true}
                                                        css="w-8 h-8 bg-graySkills rounded-full text-white hover:bg-blueMain"
                                                        onClick={handleOpenChat}
                                                    />
                                                    {discussionNotifications && (
                                                        <div className="flex justify-center items-center text-white text-xs font-semibold absolute -top-2 -right-1 bg-warning-600 p-1 h-5 w-5 rounded-full">
                                                            <span>{discussionNotifications}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div> */}
                                            <div className="pr-2">
                                                <Notifications />
                                            </div>
                                        </>
                                    )}

                                    <Button
                                        label={
                                            authState.isLogged && authState?.me?.profile ? (
                                                <>
                                                    {authState.me?.profile?.avatar?.url ? (
                                                        <Image
                                                            src={authState.me?.profile?.avatar?.url + sizes.avatar}
                                                            alt={authState.me?.profile?.pseudo}
                                                            loading="false"
                                                            dynamic
                                                            className="rounded-full"
                                                        />
                                                    ) : (
                                                        <img
                                                            className="rounded-full"
                                                            src={`${config.publicDir}skillsLogo.jpg`}
                                                            alt={authState.me?.profile?.pseudo}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <UserIcon className="w-5 h-5 mx-auto" />
                                            )
                                        }
                                        justIcon={true}
                                        css="w-8 h-8 bg-graySkills rounded-full text-white"
                                        onClick={openMenu}
                                    />
                                </div>
                            </div>
                            {props?.isSearchLocation && <SearchFiltersV2 onRefectch={props?.onRefectch} onFilter={props?.onFilter} />}
                        </div>
                    </div>
                    {isJobPage && (
                        <div className={`sm:grid grid-cols-12 gap-8`}>
                            <div className="pt-2 col-start-4 col-end-9 flex flex-row justify-between gap-4 w-full">
                                <FieldSelect
                                    inputForm="rounded"
                                    className="px-0 w-1/3"
                                    name="job_contrat"
                                    label={false}
                                    onChange={(e) => filterJobsByContrat(e.target.value)}
                                    value={job_contrat}
                                    options={[
                                        { label: 'Tous les contrats', value: 'ALL' },
                                        { label: 'CDI', value: 'CDI' },
                                        { label: 'CDD', value: 'CDD' },
                                        { label: 'MSI / Intérimaire', value: 'MSI' },
                                        { label: 'Stage', value: 'Stage' },
                                        { label: 'Alternance', value: 'Alternance' },
                                        { label: 'Freelance', value: 'Freelance' },
                                    ]}
                                    register={register}
                                    errors={errors}
                                />
                                <FieldSelect
                                    inputForm="rounded"
                                    className="px-0 w-1/3"
                                    name="job_durer"
                                    label={false}
                                    onChange={(e) => filterJobsByDurer(e.target.value)}
                                    value={job_durer}
                                    options={[
                                        { label: 'Temps plein ou partiel', value: 'ALL' },
                                        { label: 'Temps plein', value: 'TempsPlein' },
                                        { label: 'Temps Partiel', value: 'TempsPartiel' },
                                    ]}
                                    register={register}
                                    errors={errors}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {openedMenu && (
                    <div
                        onClick={() => setOpenedMenu(false)}
                        className={`fixed z-[999] top-0 left-0 w-full h-full backdrop-filter backdrop-blur-sm bg-[#00000077]`}
                    >
                        <div className="fixed top-0 right-0 bg-white w-64 h-screen flex-col justify-end shadow-2xl">
                            <ul className="w-auto h-full flex flex-col p-8 text-right text-gray-500 font-bold text-xl">
                                {authState.isLogged ? (
                                    <>
                                        <li className="ml-2 border-b mb-8 pb-4 text-right">
                                            <div className="w-auto flex flex-col gap-1">
                                                <div
                                                    onClick={props?.handleOpenUserMenu}
                                                    className="flex flex-col items-end gap-2"
                                                    /* to={`/profil/${authState.me?.profile?.slug}`} */
                                                >
                                                    <Avatar className={'w-20'} user={authState?.me} gamification={true} />
                                                    <span className="text-lg">
                                                        {authState?.me?.profile?.toSlug ? authState?.me?.profile?.toSlug : authState?.me?.profile?.pseudo}
                                                    </span>
                                                </div>
                                                <Link onClick={props?.handleOpenUserMenu} to={`/skillers`}>
                                                    <span className="text-sm">{countByKey(authState?.me?.counters, 'type', 'subscription')} Abonné(s)</span>
                                                </Link>
                                            </div>
                                        </li>

                                        <li className="ml-2 mb-8">
                                            <div>
                                                <button
                                                    className="text-gold-500 inline-flex"
                                                    onClick={() => {
                                                        if (isAlreadyPremium) {
                                                            navigate('/dashboard/params/account');
                                                        } else {
                                                            setIsPlanOpen(true);
                                                        }
                                                    }}
                                                >
                                                    <StarIcon className="w-5 h-5 mx-auto self-center" />
                                                    <span>Offre premium</span>
                                                </button>
                                            </div>
                                        </li>
                                        <li className="my-2 ml-2 text-gray-800 hover:text-blueMain">
                                            <Link to={`/profil/${authState.me?.profile?.slug}`}>Voir mon profil</Link>
                                        </li>
                                        <li className="my-2 ml-2 text-gray-800 hover:text-blueMain">
                                            <button onClick={handleGoToProfile}>Modifier mon profil</button>
                                        </li>
                                    </>
                                ) : (
                                    <li className="my-4 ml-2 text-center text-gray-800 hover:text-blueMain">
                                        <button onClick={handleOpenConnexion}>Connexion / Inscription</button>
                                    </li>
                                )}

                                {authState.isLogged && (
                                    <>
                                        <li className="my-2 ml-2 text-gray-800 hover:text-blueMain">
                                            <Link to="/dashboard/needs">Mes besoins</Link>
                                        </li>
                                        <li className="my-2 ml-2 text-gray-800 hover:text-blueMain">
                                            <Link to="/dashboard/services">Ma Boutique</Link>
                                        </li>
                                        <li className="my-2 ml-2 text-gray-800 hover:text-blueMain">
                                            <Link to="/dashboard/subscribers">Mes abonnés</Link>
                                        </li>
                                        <li className="my-2 ml-2 text-gray-800 hover:text-blueMain">
                                            <Link to="/dashboard/shoots">Mes shoots</Link>
                                        </li>
                                        <li className="my-2 ml-2 text-gray-800 hover:text-blueMain">
                                            <Link to="/dashboard/visios/asked">Mes visios</Link>
                                        </li>
                                        <li className="my-2 ml-2 text-gray-800 hover:text-blueMain">
                                            <Link to="/dashboard/notifications">Notifications</Link>
                                        </li>

                                        <li className="my-4 ml-2 pt-12 text-gray-800 hover:text-blueMain mt-auto border-t">
                                            <Link to="/dashboard/params/account">Paramètres</Link>
                                        </li>
                                        {/*  <li className="my-1 ml-2 hover:text-blueMain">
                                                <button onClick={handleLogout}>Déconnexion</button>
                                            </li> */}
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                )}
            </nav>
            <Modal
                /*   title={`${!isLogged ? 'Connexion à SkillsMarket' : ''}`} */
                open={isConnexionOpen}
                onClose={{
                    onClick: () => {
                        setIsConnexionOpen(false);
                    },
                }}
                onConfirm={
                    !isLogged
                        ? {
                              label: modalConfirmLabel,
                              class: 'font-bold',
                              onClick: handleLogin,
                          }
                        : false
                }
            >
                <FormConnexion
                    ref={loginRef}
                    onLogged={() => setIsConnexionOpen(false)}
                    isLogged={(status) => onLogged(status)}
                    modalConfirmLabel={(label) => setModalConfirmLabel(label)}
                />
            </Modal>
        </>
    );
}
export default MainNavSearch;
