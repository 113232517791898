import { Fragment } from 'react';

function FieldSelect(props) {
    function handleFocus() {}

    return (
        <div className={`${props.size ? props.size : 'w-full'} ${props.className ?  props.className : ""} ${props.inputForm === 'floating' ? 'z-0 group' : ''} relative`}>
            <div className="relative" onClick={handleFocus}>
                {props.inputForm === 'floating' && (
                    <>
                        <div className="my-4">
                            <label
                                htmlFor={`floating_${props.name}`}
                                className={`${props.option !== undefined && 'peer-placeholder-shown:pl-10'} input_labelFloating`}
                            >
                                {props.label}
                            </label>
                            <select
                                {...props.register(props.name)}
                                id={`floating_${props.name}`}
                                defaultValue={props.defaultValue || props.options[0].value}
                                className="block py-2.5 px-0 pl-4 w-full text-sm text-purpleSkills bg-transparent border-0 border-b border-graySkills appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-purpleSkills peer"
                            >
                                {props?.options?.map((option, key) => (
                                    <Fragment key={key}>
                                        <option value={option.value} disabled={option.display === false ?? 'disabled'}>
                                            {option.label}
                                        </option>
                                    </Fragment>
                                ))}
                            </select>
                        </div>
                    </>
                )}
                {props.inputForm === 'rounded' && (
                    <>
                        <label htmlFor={`rounded_${props.name}`} className={``}>
                            {props.label}
                        </label>
                        <select
                            {...props.register(props.name)}
                            defaultValue={props.defaultValue || props.options[0].value}
                            onChange={props?.onChange}
                            id={`rounded_${props.name}`}
                            className={`block py-2.5 px-0 pl-4 w-full text-sm text-mainText border-r-[16px] border-transparent rounded-3xl bg-lightGraySkills focus:outline-none focus:ring-0`}
                        >
                            {props?.options?.map((option, key) => (
                                <Fragment key={key}>
                                    <option value={option.value} disabled={option.display === false ?? 'disabled'}>
                                        {option.label}
                                    </option>
                                </Fragment>
                            ))}
                        </select>
                    </>
                )}
                {props.inputForm === 'flat' && (
                    <>
                        <label htmlFor={`flat_${props.name}`} className={`font-medium text-sm text-graySkills`}>
                            {props.label}
                        </label>
                        <select
                            {...props.register(props.name)}
                            defaultValue={props.defaultValue || props.options[0].value}
                            onChange={props?.onChange}
                            id={`flat_${props.name}`}
                            className={`block p-4 w-full font-bold text-sm border-r-[16px] border-transparent text-mainText rounded-lg bg-blancSkills focus:outline-none focus:ring-0`}
                        >
                            {props?.options?.map((option, key) => (
                                <Fragment key={key}>
                                    <option value={option.value} disabled={option.display === false ?? 'disabled'}>
                                        {option.label}
                                    </option>
                                </Fragment>
                            ))}
                        </select>
                    </>
                )}
            </div>
        </div>
    );
}

export default FieldSelect;
