import { Fragment, useCallback, useEffect, useState } from 'react';

//UTILS
import config from 'utils/constants';
import InAllLayouts from 'pages/layouts/InAllLayouts';
import { isMobile } from 'utils/functions';
//import MainNavMobile from 'components/common/MainNavMobile';
import MainSearchBar from 'components/common/mainSearch/MainSearchBar';

//LIBS
import { Link, useNavigate } from 'react-router-dom';
import MainNavSearch from 'components/common/MainNavSearch';
//COMPONENTS
import { /* useSpring, */ animated } from 'react-spring';
//HOOKS
import useScroll from 'hooks/useScroll';
import Banner from 'components/common/Banner';
import useNotifications from 'hooks/useNotifications';
//API
import { getNotifications } from 'api/discussions';
//CONTEXTS
import { useContext } from 'react';
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
// eslint-disable-next-line no-unused-vars
import { BellIcon, ChatIcon } from '@heroicons/react/solid';
import { isSmartphone } from '../../utils/functions';
import Image from '../../components/common/Image';
import MainNavUser from '../../components/common/MainNavUser';
import CategoriesServices from '../../components/common/nav/CategoriesServices';

function ServiceLayout({ children }) {
    const [openedUserMenu, setOpenedUserMenu] = useState(false);
    const [isServicesPage, setIsServicesPage] = useState(false);
    //HOOKS
    const scrollDirection = useScroll();
    const navigate = useNavigate();
    const [authState] = useContext(AuthContext);

    //contexts
    const store = useContext(StoreContext);
    const discussionNotifications = useNotifications(getNotifications);

    const handleOpenUserMenu = useCallback(() => {
        setOpenedUserMenu(openedUserMenu ? false : true);
    }, [openedUserMenu]);

    useEffect(() => {
        if (openedUserMenu) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
    }, [openedUserMenu]);

    useEffect(() => {
        if (window.location.pathname.includes('/service')) {
            setIsServicesPage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*  const openCloseSlideAnimationContent = useSpring({
        transform: openedUserMenu ? `translateX(16rem)` : 'translateX(0rem)',
        config: {
            duration: 225,
        },
    }); */

    const handleGoToNotifications = useCallback(() => {
        if (authState?.isLogged) navigate('/dashboard/notifications');
        else document.getElementById('button-login')?.click();
        //eslint-disable-next-line
    }, [authState, navigate]);

    const handleGoToSearch = useCallback(() => {
        navigate('/search');
    }, [navigate]);

    /* const handleGoToCloneAI = useCallback(() => {
        navigate('/dashboard/clone-ai');
    }, [navigate]); */

    return (
        <Fragment>
            <InAllLayouts />
            <MainNavUser handleOpenUserMenu={handleOpenUserMenu} isOpen={openedUserMenu} />
            <div className={`hidden sm:block container-fluid mx-auto w-full sticky z-20  h-auto min-h-16 px-4 pb-2 bg-white top-0`}>
                <MainNavSearch />
                {isServicesPage && <CategoriesServices />}
            </div>

            <animated.div /* style={openCloseSlideAnimationContent} */ className="flex flex-col min-h-screen container-fluid">
                <div className="flex-grow">
                    <div
                        className={`sm:hidden fixed w-full -top-[65px] sm:top-16 z-20 pt-2 px-4 bg-white transition-all duration-300 ease-out ${
                            scrollDirection === 'down' ? 'top-0' : '-top-[65px]'
                        }`}
                    >
                        <div className="flex justify-between items-center gap-2 pb-2">
                            <div className="w-auto flex">
                                {isSmartphone() ? (
                                    <>
                                        {authState.isLogged ? (
                                            <button id="button-profil" onClick={handleOpenUserMenu} className="">
                                                <div className="text-purpleSkills font-semibold text-sm flex flex-col items-center w-auto">
                                                    <div>
                                                        {authState.me?.profile?.avatar?.url ? (
                                                            <Image
                                                                src={authState.me?.profile?.avatar?.url}
                                                                alt={authState.me?.profile?.pseudo}
                                                                loading="false"
                                                                className="rounded-full w-8 h-8"
                                                            />
                                                        ) : (
                                                            <img
                                                                className="rounded-full w-8 h-8"
                                                                src={`${config.publicDir}skillsLogo.jpg`}
                                                                alt={authState.me?.profile?.pseudo}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </button>
                                        ) : (
                                            <Link to={isMobile() ? '/home' : '/'}>
                                                <img src={`${config.publicDir}logo_skillsmobile.png`} alt="logo" className="h-8 w-auto" />
                                            </Link>
                                        )}
                                    </>
                                ) : (
                                    <Link to={isMobile() ? '/home' : '/'}>
                                        <img src={`${config.publicDir}logo_skillsmobile.png`} alt="logo" className="h-8 w-auto" />
                                    </Link>
                                )}
                            </div>
                            <div className="flex gap-4 w-3/4">
                                <div className="w-full" onClick={handleGoToSearch}>
                                    <MainSearchBar isInNavBar={true} />
                                </div>
                            </div>
                            <div className="w-7 flex">
                                <button id="button-notifications" onClick={handleGoToNotifications}>
                                    <div className="relative text-graySkills text-sm flex flex-col items-center w-auto">
                                        <div className="">
                                            {authState.isLogged && (
                                                <>
                                                    {(store.get('notifications')?.length > 0 || discussionNotifications) && (
                                                        <>
                                                            <div className="sm:hidden flex justify-center items-center text-white text-xs font-semibold absolute -top-2 -right-3 bg-red p-1 h-5 w-5 rounded-full">
                                                                <span>{discussionNotifications + store.get('notifications').length}</span>
                                                            </div>
                                                            {store.get('notifications')?.length > 0 && (
                                                                <div className="hidden sm:flex justify-center items-center text-white text-xs font-semibold absolute -top-2 -right-3 bg-red p-1 h-5 w-5 rounded-full">
                                                                    <span>{store.get('notifications').length}</span>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            <ChatIcon className="block w-7 h-7" />
                                            {/* <BellIcon className="block w-6 h-6" /> */}
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {isServicesPage && <CategoriesServices />}
                    </div>
                    <div className={`container-service relative mx-auto px-2 sm:px-4 ${isMobile() && 'mt-8'}`}>
                        <div className="w-full">{children}</div>
                    </div>
                </div>
                {!authState.isLogged && (
                    <>
                        <div className={`z-20 sticky sm:bottom-0 transition-all duration-300 ease-out bottom-[68px]`}>
                            <Banner />
                        </div>
                    </>
                )}
                {/* <MainNavMobile /> */}
            </animated.div>
            {/* {authState.isLogged && (
                <button onClick={handleGoToCloneAI} className={`sm:hidden z-10 fixed bottom-20 right-4 w-12 cursor-pointer`}>
                    <img src={`${config.publicDir}logo_ai_mobile.svg`} alt="ai_logo" />
                </button>
            )} */}
        </Fragment>
    );
}

export default ServiceLayout;
