import { useEffect, useContext, useState, useRef, createRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
//CONFIG
import config from 'utils/constants';
//LIBS
import _ from 'lodash';
//CSS
import './dicussion.css';
//API
import { useQuery } from 'react-query';
import { getDiscussions, resetCounter } from 'api/discussions';
import { /* createClone, reCreateClone, */ getMatchingClone, getCloneByMe, toggleAiRound } from 'api/clone';
import { getConversationsClone, getOpportunities } from 'api/cloneDiscussions';
//import { getDiscussionsClone } from 'api/cloneDiscussions';
//CONTEXTS
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
import { SocketContext } from 'context/socketContext';
import { CloneContext } from 'context/cloneContext';
//ICONS
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';

//LIBS
import { toast } from 'react-toastify';
//COMPONENTS
import ChatRoom from 'containers/chat/ChatRoom';
import Avatar from 'containers/profils/Avatar';
import TabsMessagerie from '../../tabs/TabsMessagerie';
import BuyCloneAi from 'components/common/BuyCloneAi';
//CONTAINERS
import CardProfil from 'containers/discussions/CardProfil';
import CardProfilClone from '../../../containers/discussions/CardProfilClone';
//UTILS
import { isMobile } from '../../../utils/functions';
import FormAiTrainSteps from '../../forms/formAiSteps/aiTrainSteps';

function Discussion() {
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);
    const socket = useContext(SocketContext);
    const [cloneState, cloneDispatch] = useContext(CloneContext);
    const { dialogueStates } = cloneState;

    const [discussionType, setDiscussionType] = useState('standard');
    const location = useLocation();
    const navigate = useNavigate();
    const discussionRef = useRef();
    const meRef = useRef();
    const notificationsRef = useRef({});

    const refs = useRef([]);

    const [opened, setOpened] = useState(false);
    const [focused, setFocused] = useState(null);
    const [notifications, setNotifications] = useState(0);

    const [isToggle, setIsToggle] = useState(store.get('toggleDiscussion') || false);

    const [cloneId, setCloneId] = useState(null);
    /* const [cloneCreationState, setCloneCreationState] = useState(false) */
    const [openAiTrain, setOpenAiTrain] = useState(false);
    const [isPlanOpen, setIsPlanOpen] = useState(false);
    const [isAlreadyPremium, setIsAlreadyPremium] = useState(false);
    //API
    const discussions = useQuery(['discussions'], getDiscussions, { enabled: false });
    //const matchingClone = useQuery(['matchingClone'], getMatchingClone, { enabled: startMatching });

    const clone = useQuery(['clone'], getCloneByMe, { enabled: false });
    const cloneConversations = useQuery(['cloneConversations'], getConversationsClone, { enabled: false });
    const myOpportunities = useQuery(['opportunities'], getOpportunities, { enabled: false });
    /*  const [cloneIsCreating, setCloneIsCreating] = useState(false);
    const [cloneWorking, setCloneWorking] = useState(false); */

    useEffect(() => {
        if (authState.isLogged && clone.data) {
            setCloneId(clone.data.id);
            //if user as plan premium
            if (authState?.me?.plans && authState?.me?.plans.length > 0) {
                if (authState?.me?.plans.some((plan) => plan.type === 'chatAi' && plan.status === 'succeeded')) {
                    setIsAlreadyPremium(true);
                }
            }
        } else {
            setCloneId(false);
        }
    }, [clone.data, authState]);

    useEffect(() => {
        // Liste des chemins pour lesquels isToggle doit être true
        const pathsWhereToggleIsTrue = ['/jobs', '/service/', '/services', '/dashboard/profil', '/dashboard/services'];
        const isPathMatch = pathsWhereToggleIsTrue.some((path) => location.pathname.startsWith(path));

        if (isPathMatch) {
            store.set({ type: 'add', key: 'toggleDiscussion', value: isPathMatch });
            setIsToggle(isPathMatch);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    function createDiscussionId(userFrom) {
        const users = [{ user: authState?.me?.id }, { user: userFrom }];
        const sortedUsers = _.sortBy(users, ['user']);
        const id = [];
        sortedUsers.forEach((user) => {
            id.push(user.user);
        });
        discussionRef.current = _.join(id, '.');
    }

    function createCloneDiscussionId(cloneFrom) {
        if (cloneFrom === clone.data?.id) {
            const users = [authState?.me?.id, clone.data?.id];
            discussionRef.current = _.join(users, '.');
        } else {
            const clones = [clone.data?.id, cloneFrom];
            discussionRef.current = _.join(clones, '.');
        }
    }

    function addCounter(key, counter) {
        notificationsRef.current = { ...notificationsRef.current, [key]: counter };
        setNotifications(notificationsRef.current);
    }

    function socketInit() {
        socket.on('join', (data) => {
            if (data?.messages && data.discussionId === discussionRef.current) {
                // setClosedDiscussion(false);
                const current = _.find(refs.current, { id: discussionRef.current });
                current.ref.current?.setMessages(data.messages);
                discussions.refetch();
            }
        });
        socket.on('joinClone', (data) => {
            if (data?.messages && data.discussionId === discussionRef.current) {
                // setClosedDiscussion(false);
                const current = _.find(refs.current, { id: discussionRef.current });
                if (data.type === 'cloneConversation' && cloneId) {
                    cloneConversations.refetch();
                    current.ref?.current?.setConversations(data.messages);
                } else if (data.type === 'cloneOpportunities' && cloneId) {
                    myOpportunities.refetch();
                    current.ref?.current?.setOpportunities(data.messages);
                } else {
                    current.ref.current?.setMessages(data.messages);
                }
            }
        });
        socket.on('cloneDiscussionSuccess', (data) => {
            cloneConversations.refetch();
        });
        socket.on('chatLeave', (data) => {
            // console.log('someone leave chat', data);
        });
        socket.on('sendMessage', (data) => {
            if (data.message.userId !== meRef.current.id && data.message.mine === false && _.find(refs.current, { id: data.discussionId })) {
                const current = _.find(refs.current, { id: data.discussionId });
                current.ref.current.addMessage(data.message);
            }
        });
        socket.on('sendMessageClone', (data) => {
            if (data.message.userId !== meRef.current.id && data.message.mine === false && _.find(refs.current, { id: data.discussionId })) {
                const current = _.find(refs.current, { id: data.discussionId });
                current.ref.current.addMessage(data.message);
            }
        });
    }

    function resetAllCounters(discussionId) {
        if (discussionType === 'standard') {
            resetCounter(discussionId);
            socket.send('notificationsRefresh');
        }
    }

    function socketLeave() {
        socket.send('chatLeave', { id: discussionRef.current });
        socket.off('discussionNotifications');
        socket.off('opportunitiesNotifications');
        socket.off('join');
        socket.off('joinClone');
        socket.off('cloneDiscussionSuccess');
        socket.off('chatLeave');
        socket.off('sendMessage');
        socket.off('sendMessageClone');
    }

    function handleOpenDiscussion(e, userFrom) {
        if (e) e.preventDefault();
        store.set({ type: 'add', key: 'openChat', value: { open: true, userId: userFrom.id, chatType: 'standard' } });
    }

    //OUVRIR LA CONVERSATION ENTRE LE CLONE ET L UTILISATEUR
    function handleCloneOpenDiscussion(e, cloneId, type) {
        if (e) e.preventDefault();
        store.set({ type: 'add', key: 'discussionType', value: 'ai' });
        store.set({ type: 'add', key: 'openChat', value: { open: true, cloneId: cloneId, chatType: 'ai', type: type } });
    }
    function openDiscussion(userFrom) {
        createDiscussionId(userFrom);

        const compactRefs = _.uniqBy(
            [...refs.current, { ref: createRef(null), id: discussionRef.current, userFrom: userFrom, chatType: 'standard', open: true }],
            'id'
        );
        refs.current = compactRefs;
        socket.send('join', { id: discussionRef.current });
        setFocused(discussionRef.current);

        resetAllCounters(discussionRef.current);
    }

    function openCloneDiscussion(cloneFrom, type, open) {
        createCloneDiscussionId(cloneFrom);

        const compactRefs = _.uniqBy(
            [...refs.current, { ref: createRef(null), id: discussionRef.current, cloneFrom: cloneFrom, chatType: 'ai', type: type, open: open }],
            'id'
        );
        refs.current = compactRefs;
        //resetAllCounters(discussionRef.current);
        socket.send('joinClone', { id: discussionRef.current, userId: authState.me?.id, type: type });
        setFocused(discussionRef.current);
    }

    function openCloneOpportunitiesDiscussion(cloneFrom, type, open) {
        const id = [cloneFrom, clone.data?.id, 'cloneOpportunities'];
        discussionRef.current = _.join(id, '.');

        const compactRefs = _.uniqBy(
            [...refs.current, { ref: createRef(null), id: discussionRef.current, cloneFrom: cloneFrom, chatType: 'ai', type: type, open: open }],
            'id'
        );
        refs.current = compactRefs;
        //resetAllCounters(discussionRef.current);
        socket.send('joinClone', { id: discussionRef.current, userId: authState.me?.id, type: type });
        setFocused(discussionRef.current);
    }

    function createCloneDiscussion(cloneFrom, type) {
        const clones = [clone.data?.id, cloneFrom];
        const discussionId = _.join(clones, '.');
        socket.send('createCloneDiscussion', { id: discussionId, userId: authState.me?.id, type: type });
    }

    function chatAddMessage(value, discussionId, chatType) {
        const currentChatRef = _.find(refs.current, { id: discussionId });
        currentChatRef.ref.current.addMessage(value);

        if (chatType === 'ai') {
            socket.send('sendMessageClone', { id: discussionId, message: value });
        } else {
            socket.send('sendMessage', { id: discussionId, message: value });
        }
    }

    const openDiscussions = useCallback(() => {
        if (authState.isLogged) {
            if (location.pathname !== '/inscription-clone-ai') {
                store.set({ type: 'add', key: 'discussionType', value: 'standard' });
                store.set({ type: 'add', key: 'openChat', value: { open: true } });
            } else {
                store.set({ type: 'add', key: 'discussionType', value: 'ai' });
                store.set({ type: 'add', key: 'openChat', value: { open: true } });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, store]);

    useEffect(() => {
        if (authState.isLogged && !isMobile()) {
            openDiscussions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState]);

    function closeDiscussions() {
        setOpened(false);
        setFocused(null);
        refs.current = [];
        store.set({ type: 'del', key: 'openChat' });
        if (location.pathname.includes('dashboard/notifications')) {
            store.set({ type: 'add', key: 'discussionType', value: 'standard' });
        }
    }

    const handleGoToHome = useCallback(() => {
        setOpened(false);
        setFocused(null);
        refs.current = [];
        store.set({ type: 'del', key: 'openChat' });
        navigate(isMobile() ? '/home' : '/');
        //eslint-disable-next-line
    }, [authState, navigate]);

    function onFocus(discussionId, chatType) {
        discussionRef.current = discussionId;
        setFocused(discussionId);
        if (chatType !== 'ai') {
            resetAllCounters(discussionId);
        }
    }

    function onChatClose(discussionId) {
        const newRefs = _.reject(refs.current, { id: discussionId });
        refs.current = newRefs;
        const first = _.first(refs.current);

        if (first) {
            refs.current.map((discussion) => {
                setFocused(discussion.id);
                discussionRef.current = discussion.id;

                return store.set({
                    type: 'add',
                    key: 'openChat',
                    value: { open: true, userId: discussion.userFrom, cloneId: discussion.cloneFrom, chatType: discussion.chatType, type: discussion.type },
                });
            });
        } else {
            setFocused(null);
            refs.current = [];
            discussionRef.current = null;
            store.set({ type: 'del', key: 'openChat' });
        }
    }

    function toggle(e) {
        e?.preventDefault();
        const currentToggleState = store.get('toggleDiscussion') || false;
        store.set({ type: 'add', key: 'toggleDiscussion', value: !currentToggleState });
        setIsToggle(!currentToggleState);
    }

    function handleAiTrain() {
        setOpenAiTrain(true);
    }

    useEffect(() => {
        //CLONE CREATE
        socket.on('startCloneCreationProcess', () => {
            cloneDispatch({ type: 'setCloneCreationState', cloneCreationState: true });
        });
        socket.on('cloneCreated', (msg) => {
            cloneDispatch({ type: 'setCloneCreationState', cloneCreationState: false });
            toast('Votre clone a été créée', { type: 'success' });
            clone.refetch();
        });

        socket.on('cloneExists', (msg) => {
            cloneDispatch({ type: 'setCloneCreationState', cloneCreationState: false });
            toast('Votre clone existe déjà', { type: 'error' });
            clone.refetch();
        });

        socket.on('errorCloneCreated', (msg) => {
            console.log('errorCloneCreated');
            cloneDispatch({ type: 'setCloneCreationState', cloneCreationState: false });
            toast('Une erreur est survenue', { type: 'error' });
        });

        //DIALOGUE
        socket.on('cloneDialogueStarted', (data) => {
            console.log('clone dialogue start');
            cloneConversations.refetch();
            cloneDispatch({
                type: 'setCloneDialogueState',
                discussionId: data?.discussionId,
                cloneDialogueState: true,
            });
        });
        socket.on('cloneDialogueFinished', (data) => {
            console.log('clone dialogue finished');
            cloneConversations.refetch();
            cloneDispatch({
                type: 'setCloneDialogueState',
                discussionId: data?.discussionId,
                cloneDialogueState: false,
            });
        });
        socket.on('cloneDialogueSuccess', (data) => {
            console.log('clone dialogue success');
            const current = _.find(refs.current, { id: data.discussionId });
            if (current) {
                current.ref?.current?.setConversations(data.messages);
            }
        });

        // Assurez-vous de vous désinscrire des événements lorsque le composant est démonté
        return () => {
            socket.off('startCloneCreationProcess');
            socket.off('cloneCreated');
            socket.off('cloneDialogueStarted');
            socket.off('cloneDialogueFinished');
            socket.off('cloneDialogueSuccess');
            socket.off('cloneExists');
            socket.off('error');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    useEffect(() => {
        if (!opened) {
            discussionRef.current = null;
        }
    }, [opened]);

    function switchDiscussionType() {
        setDiscussionType(discussionType === 'standard' ? 'ai' : 'standard');
        store.set({ type: 'add', key: 'discussionType', value: discussionType === 'standard' ? 'ai' : 'standard' });
    }

    const handleGoToCloneInscription = useCallback(() => {
        if (authState.me) {
            navigate(`/inscription-clone-ai`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, navigate]);

    useEffect(() => {
        const type = store.get('discussionType');
        if (type !== null) {
            setDiscussionType(type);
        }
    }, [store]);

    useEffect(() => {
        if (authState?.me?.id) {
            socket.send('discussionNotifications', { user: authState.me.id });
            socket.on('discussionNotifications', () => {
                /*     console.log("discussion notification") */
                discussions.refetch();
                if (cloneId) {
                    cloneConversations.refetch();
                    myOpportunities.refetch();
                }

                socket.send('notificationsRefresh');
            });
            if (opened) {
                meRef.current = authState?.me;
                discussions?.refetch();
                if (authState?.me?.clone) {
                    cloneConversations?.refetch();
                    myOpportunities?.refetch();
                    clone?.refetch();
                }
            }
        }
        //eslint-disable-next-line
    }, [authState.me, opened]);

    useEffect(() => {
        if (opened) {
            notificationsRef.current = store.get('discussion_notifications');
        }
        //eslint-disable-next-line
    }, [location.key, discussions.isSuccess, opened]);

    useEffect(() => {
        if (discussions.data && authState.me) {
            _.forEach(discussions.data, (discussion) => {
                //eslint-disable-next-line
                const unreaded = discussion?.user1?.id === authState.me?.id ? discussion.unreaded1 : discussion.unreaded2;
                addCounter(discussion.discussionId, unreaded);
            });
        }
        /*  if (discussionsClone.data && authState.me) {
            _.forEach(discussionsClone.data, (discussion) => {
                //eslint-disable-next-line
                const unreaded = discussion?.user.id === authState.me?.id ? discussion.unreaded1 : discussion.unreaded2;
                addCounter(discussion.discussionId, unreaded);
            });
        } */
        //eslint-disable-next-line
    }, [discussions.data, authState.me]);

    useEffect(() => {
        if (cloneConversations.data && authState.me && cloneId) {
            _.forEach(cloneConversations.data, (conversation) => {
                //eslint-disable-next-line
                /*  const unreaded = discussion?.user.id === authState.me?.id ? discussion.unreaded1 : discussion.unreaded2;
                addCounter(discussion.discussionId, unreaded); */
            });
        }
        //eslint-disable-next-line
    }, [cloneConversations.data, authState.me]);

    useEffect(() => {
        socketInit();
        // discussions.refetch();
        return () => {
            socketLeave();
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (store.get('openChat') !== null) {
            setOpened(store.get('openChat').open ? true : false);
            if (authState.me) {
                if (discussionType === 'ai') {
                    if (cloneId) {
                        cloneConversations.refetch();
                        myOpportunities.refetch();
                    }

                    if (cloneId && clone?.data?.isAiRoundEnabled === true) {
                        handleMatch();
                    }
                    if (store.get('openChat').cloneId) {
                        if (store.get('openChat').type === 'cloneDiscussion') {
                            openCloneDiscussion(store.get('openChat').cloneId, 'cloneDiscussion', true);
                        } else if (store.get('openChat').type === 'cloneOpportunities') {
                            openCloneOpportunitiesDiscussion(store.get('openChat').cloneId, 'cloneOpportunities', true);
                        } else {
                            openCloneDiscussion(store.get('openChat').cloneId, 'cloneConversation', true);
                        }
                    }
                } else {
                    discussions.refetch();
                    if (store.get('openChat').userId) {
                        openDiscussion(store.get('openChat').userId);
                    }
                }
            }
        }
        //eslint-disable-next-line
    }, [store, discussionType, authState]);

    const setBodyHeight = () => {
        const element = document.getElementById('discussion');
        if (element) {
            element.style.height = `${window.innerHeight}px`;
        }
    };

    useEffect(() => {
        if (isMobile()) {
            setBodyHeight(); // Set initial height

            window.addEventListener('resize', setBodyHeight); // Update height whenever the window size changes

            return () => window.removeEventListener('resize', setBodyHeight); // Cleanup
        }
    }, []);

    const handleMatch = async () => {
        try {
            //console.log('handleMatch');
            const responseMatching = await getMatchingClone();
            //console.log('responseMatching', responseMatching);
            switch (responseMatching.status) {
                case 200:
                    //console.log('Received matching data', responseMatching.data);
                    responseMatching.data.forEach((clone, index) => {
                        if (clone._id !== cloneId) {
                            //createCloneDiscussion(clone.id, false)
                            createCloneDiscussion(clone._id, 'cloneConversation');
                            //openCloneDiscussion(clone.id, "cloneConversation", false)
                        }
                    });
                    //AI ROUND CLONE ENABLE
                    const responseToggle = await toggleAiRound();

                    if (responseToggle.status === 200) {
                        clone.refetch();
                    } else {
                        console.error('Failed to change AI Round state', responseToggle);
                    }
                    break;
                case 400:
                    console.error('Content cannot be empty');
                    break;
                case 401:
                    console.error('No users or clones found or User not found', responseMatching.error);
                    break;
                case 500:
                    console.error('An error occurred while retrieving the chat', responseMatching.message);
                    break;
                default:
                    console.error('Unexpected status code', responseMatching);
                    break;
            }
        } catch (error) {
            switch (error.response.status) {
                case 400:
                    console.error('Content cannot be empty');
                    break;
                case 401:
                    toast("Nous n'avons pas trouvé de clone correspondant à votre profil", { type: 'infos' });
                    break;
                case 500:
                    console.error('An error occurred while retrieving the chat', error.response.message);
                    break;
                default:
                    console.error('Unexpected status code', error.response);
                    break;
            }
        }
    };

    const cloneDialogue = (id, userId, userTo) => {
        socket.send('aiRoundLaunch', { id: id, userId: userId, userTo: userTo });
    };

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const tabMatchs = (
        <div className="discussions_list flex flex-col w-full px-0 h-full overflow-y-auto">
            {cloneId && (
                <>
                    {cloneConversations.isSuccess && cloneConversations.data?.length !== 0 && discussionType === 'ai' ? (
                        <div className="grow overflow-hidden pb-5">
                            <div className="discussions_list w-full px-0 overflow-auto">
                                {cloneConversations.data?.map((conversation, index) => (
                                    <div
                                        key={index}
                                        className={`${
                                            discussionRef.current === conversation.discussionId ? 'bg-black text-white' : 'hover:bg-black text-white'
                                        } py-2 border-b border-white`}
                                    >
                                        <div className="px-2 flex items-center justify-between">
                                            <CardProfilClone
                                                isFocus={focused === conversation.discussionId && true}
                                                discussionId={conversation.discussionId}
                                                notifications={notifications}
                                                user={conversation.user}
                                                clone={conversation.clone}
                                                clone2={conversation.clone2}
                                                onClick={(e) => handleCloneOpenDiscussion(e, conversation.clone2.id, 'cloneConversation')}
                                            />
                                            {conversation.isAiRound && (
                                                <button
                                                    className="w-36 p-2 bg-black font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]"
                                                    onClick={(e) => {
                                                        handleCloneOpenDiscussion(e, conversation.clone2.id, 'cloneConversation');
                                                        cloneDialogue(conversation.discussionId, conversation.user, conversation.clone2?.user?.id);
                                                    }}
                                                >
                                                    AI Round
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <>
                            {clone?.data?.isAiRoundEnabled !== true && (
                                <div className="grow justify-center flex flex-col text-center p-2 ">
                                    <div className="text-white font-extrabold text-3xl">
                                        <h1 className="drop-shadow-2xl">Activez "AI Round"</h1>
                                    </div>
                                    <div className="flex flex-col text-white">
                                        <span>Laissez votre clone négocier pour</span>
                                        <span>vous 24/7 auprès des clones de</span>
                                        <span>vos cibles</span>
                                    </div>
                                    <div className="w-full flex justify-center py-2">
                                        <button
                                            className={`w-80 border-y-2 border-x-8 border-[#BB7EFF] bg-black hover:bg-[#b235fe] button font-bold text-white text-3xl py-3 rounded-full`}
                                            onClick={() => {
                                                handleMatch();
                                            }}
                                        >
                                            Activer
                                        </button>
                                    </div>
                                    <div className="mt-4">
                                        <button className="text-white font-bold" role="link" onClick={() => openInNewTab('https://clone.ai.skillsmarket.fr/')}>
                                            En savoir plus
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            {cloneId === false && (
                <>
                    {cloneState.cloneCreationState !== true ? (
                        <>
                            <div className="w-full flex justify-center py-2 mt-12">
                                <button
                                    className={`w-80 border-y-2 border-x-8 border-[#BB7EFF] bg-black hover:bg-[#b235fe] button font-bold text-white text-3xl py-3 rounded-full`}
                                    onClick={() => handleGoToCloneInscription()}
                                >
                                    Créer mon clone
                                </button>
                            </div>
                            <div className="text-white text-center mt-6">
                                <span>
                                    Découvrez la puissance de votre<br></br>double numérique à votre service, qui<br></br>négocie et défend vos intérêts pour
                                    <br></br>atteindre vos objectifs 24/7
                                </span>
                                {/* <span>vous 24/7 auprès des clones de</span>
                                <span>vos cibles</span> */}
                            </div>
                            <div className="mt-4 text-center">
                                <button className="text-white font-bold" role="link" onClick={() => openInNewTab('https://clone.ai.skillsmarket.fr/')}>
                                    En savoir plus
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className={`hover:bg-black text-white py-2 border-b font-bold text-xl border-white cursor-pointer`}>
                            <div className="px-2">
                                <span>Nous fabriquons votre Clone AI...</span>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );

    const tabOpportunities = (
        <div className="discussions_list flex flex-col w-full px-0 h-full overflow-y-auto">
            {myOpportunities.isSuccess && myOpportunities.data?.length !== 0 && discussionType === 'ai' ? (
                <div className="grow overflow-hidden pb-5">
                    <div className="discussions_list w-full px-0 overflow-auto">
                        {myOpportunities.data?.map((opportunity, index) => (
                            <div
                                key={index}
                                className={`${
                                    discussionRef.current === `${opportunity.clone2}.${opportunity.clone}.cloneOpportunities`
                                        ? 'bg-black text-white'
                                        : 'hover:bg-black text-white'
                                } py-2 border-b border-white`}
                            >
                                <div className="px-2 flex items-center justify-between">
                                    <CardProfilClone
                                        isFocus={focused === `${opportunity.clone2}.${opportunity.clone}.cloneOpportunities` && true}
                                        discussionId={`${opportunity.clone2}.${opportunity.clone}.cloneOpportunities`}
                                        /* notifications={notifications} */
                                        user={opportunity.userOffensif}
                                        onClick={(e) => {
                                            handleCloneOpenDiscussion(e, opportunity.clone2, 'cloneOpportunities');
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="flex flex-col justify-center text-center p-2 h-full">
                    <div className="text-white font-extrabold text-3xl">
                        <h1>
                            Vous n'avez pas encore<br></br>reçu d'opportunités
                        </h1>
                    </div>
                    <div className="text-white  mt-4">
                        <span>
                            Votre clone défend vos intérêts, filtre<br></br>les demandes et ne vous présente<br></br>ici que les meilleures opportunités
                            <br></br>négociés pour vous.
                        </span>
                    </div>
                </div>
            )}
        </div>
    );

    const labelTabs = (label) => {
        return (
            <>
                <span>{label}</span>
            </>
        );
    };

    const tabs = [
        { label: labelTabs('Matchs'), key: 'tab_matchs', content: tabMatchs },
        { label: labelTabs('Opportunités reçues'), key: 'tab_opportunities', content: tabOpportunities },
    ];

    if (!opened || !authState.isLogged) return null;

    return createPortal(
        <>
            {isPlanOpen && <BuyCloneAi state={isPlanOpen} setState={setIsPlanOpen} />}
            <FormAiTrainSteps openAiTrain={openAiTrain} setOpenAiTrain={setOpenAiTrain} />
            <div
                className={`${
                    discussionRef.current ? '' : 'hidden sm:block'
                } dicussion fixed bottom-0 right-0 sm:top-auto sm:bottom-5 sm:right-2 w-full h-full sm:w-auto sm:h-auto z-50 sm:z-10`}
            >
                <div className={` h-full sm:h-auto sm:flex sm:flex-row-reverse sm:gap-4 sm:items-end`}>
                    <div
                        className={`hidden sm:block w-full sm:w-96 ${`${!isToggle ? `h-full sm:h-[70vh]` : 'h-[70px]'}`} ${
                            discussionType === 'standard' ? 'discussion-container' : 'ai-container'
                        } sm:rounded-[30px] sm:shadow`}
                    >
                        <div className="flex flex-col sm:flex-row flex-nowrap h-full w-full">
                            <div className="flex flex-col h-full w-full">
                                <div
                                    className={`${discussionType === 'standard' ? 'header-discussion' : 'header-ai'} flex justify-between gap-2 ${
                                        isToggle ? 'h-full ' : discussionType === 'standard' ? 'border-b border-gray-100' : ''
                                    } items-center text-graySkills ${isToggle ? 'sm:rounded-[30px]' : 'sm:rounded-t-[30px]'}`}
                                >
                                    {discussionType !== 'ai' && (
                                        <span className="hidden sm:block mt-0 py-2 px-2 relative">
                                            <Avatar
                                                gamification={false}
                                                user={authState?.me}
                                                className={`${isToggle ? 'w-14 h-14' : 'w-16 h-16'} rounded-full mt-0`}
                                            />
                                        </span>
                                    )}

                                    <div onClick={(e) => toggle(e)} className="flex cursor-pointer grow justify-center">
                                        <div className="flex items-center">
                                            {discussionType === 'standard' ? (
                                                <span
                                                    className={`flex text-center justify-center grow w-full h-full mt-0 py-2 px-2 relative font-bold text-purpleSkills ${
                                                        isToggle ? 'text-2xl' : 'text-2xl'
                                                    }`}
                                                >
                                                    Messagerie
                                                </span>
                                            ) : (
                                                <div
                                                    style={{ fontFamily: '-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,sans-serif' }}
                                                    className={`flex text-center justify-center grow w-full h-full mt-0 relative font-bold ${
                                                        isToggle ? 'text-4xl' : 'text-4xl'
                                                    }`}
                                                    /* className={`flex text-center justify-center grow w-full h-full mt-0 py-2 px-2 relative font-bold ${isToggle ? 'text-4xl' : 'text-4xl'}`} */
                                                >
                                                    {/* <span>Clone</span><span style={{marginLeft: '5px', background: 'linear-gradient(150deg, #E348FF 0%, #2600FC 100%)', WebkitBackgroundClip: 'text', color: 'transparent'}}>AI</span> */}
                                                    <span className={`w-64`}>
                                                        <img src={`${config.publicDir}logo_ai_transparent.png`} alt="ai_logo" />
                                                    </span>
                                                </div>
                                            )}
                                            {!isToggle ? (
                                                <ChevronDownIcon
                                                    className="cursor-pointer w-6 h-6 hover:text-blueMain hover:bg-blancSkills rounded-full"
                                                    onClick={toggle}
                                                />
                                            ) : (
                                                <ChevronUpIcon
                                                    className="cursor-pointer w-6 h-6 hover:text-blueMain hover:bg-blancSkills rounded-full"
                                                    onClick={toggle}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {discussionType === 'standard' && (
                                        <div className={`inline-block cursor-pointer ${isToggle ? 'w-12' : 'w-12'} mr-4`} onClick={switchDiscussionType}>
                                            <img src={`${config.publicDir}logo_ai_switch2.svg`} alt="ai_logo" />
                                        </div>
                                    )}
                                    {discussionType === 'ai' && (
                                        <div className={`inline-block cursor-pointer ${isToggle ? 'w-12' : 'w-12'} mr-4`} onClick={switchDiscussionType}>
                                            <img src={`${config.publicDir}logo_messagerie_switch2.svg`} alt="messagerie_logo" />
                                        </div>
                                    )}
                                </div>
                                {!isToggle && (
                                    <>
                                        {discussions.isSuccess && discussionType === 'standard' && (
                                            <div className="flex flex-col w-full h-full overflow-y-auto">
                                                <div className="grow overflow-hidden pb-5">
                                                    <div className="discussions_list w-full px-0 overflow-auto">
                                                        {discussions?.data?.map((discussion, index) => (
                                                            <div
                                                                key={index}
                                                                className={`${
                                                                    discussionRef.current === discussion.discussionId ? 'bg-gray-100' : 'hover:bg-blancSkills'
                                                                } py-2 border-b border-gray-100`}
                                                            >
                                                                <div className="px-2">
                                                                    <CardProfil
                                                                        isFocus={focused === discussion.discussionId && true}
                                                                        discussionId={discussion.discussionId}
                                                                        notifications={notifications}
                                                                        user={discussion?.user1?.id === meRef.current?.id ? discussion.user2 : discussion.user1}
                                                                        onClick={(e) =>
                                                                            handleOpenDiscussion(
                                                                                e,
                                                                                discussion?.user1?.id === meRef.current.id
                                                                                    ? discussion?.user2
                                                                                    : discussion.user1
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className={`px-5 mb-2`}>
                                                    <div
                                                        className={`${
                                                            discussionRef.current === `${authState.me?.id}.${cloneId}`
                                                                ? 'text-white bg-[#b235fe]'
                                                                : 'hover:bg-[#b235fe] text-white bg-black'
                                                        } px-1 py-1 flex items-center justify-between rounded-full`}
                                                    >
                                                        <CardProfilClone
                                                            isFocus={focused === `${authState.me?.id}.${cloneId}` && true}
                                                            discussionId={`${authState.me?.id}.${cloneId}`}
                                                            notifications={notifications}
                                                            user={authState.me}
                                                            clone={clone?.data}
                                                            onClick={(e) => {
                                                                if (cloneId) {
                                                                    handleCloneOpenDiscussion(e, cloneId, 'cloneDiscussion');
                                                                } else {
                                                                    switchDiscussionType();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-2 text-center font-bold">
                                                    <button
                                                        className="text-gold-500 inline-flex gap-2"
                                                        onClick={() => {
                                                            if (isAlreadyPremium) {
                                                                navigate('/dashboard/params/account');
                                                            } else {
                                                                setIsPlanOpen(true);
                                                            }
                                                        }}
                                                    >
                                                        <StarIcon className="w-5 h-5 mx-auto self-center" />
                                                        {isAlreadyPremium ? <span>Clone AI Infinite</span> : <span>Passez sur Clone AI Infinite</span>}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {discussionType === 'ai' && (
                                            <>
                                                <TabsMessagerie tabs={tabs} />
                                                {cloneId && (
                                                    <>
                                                        <div className={`px-5 mb-2`}>
                                                            <div
                                                                className={`${
                                                                    discussionRef.current === `${authState.me?.id}.${cloneId}`
                                                                        ? 'text-white bg-[#b235fe]'
                                                                        : 'hover:bg-[#b235fe] text-white bg-black'
                                                                } px-1 py-1 flex items-center justify-between rounded-full`}
                                                            >
                                                                <CardProfilClone
                                                                    isFocus={focused === `${authState.me?.id}.${cloneId}` && true}
                                                                    discussionId={`${authState.me?.id}.${cloneId}`}
                                                                    notifications={notifications}
                                                                    user={authState.me}
                                                                    clone={clone.data}
                                                                    onClick={(e) => handleCloneOpenDiscussion(e, cloneId, 'cloneDiscussion')}
                                                                />
                                                                <button
                                                                    className="w-36 p-2 bg-black font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        handleAiTrain();
                                                                    }}
                                                                >
                                                                    AI Train
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2 text-center font-bold">
                                                            <button
                                                                className="text-gold-500 inline-flex gap-2"
                                                                onClick={() => {
                                                                    if (isAlreadyPremium) {
                                                                        navigate('/dashboard/params/account');
                                                                    } else {
                                                                        setIsPlanOpen(true);
                                                                    }
                                                                }}
                                                            >
                                                                <StarIcon className="w-5 h-5 mx-auto self-center" />
                                                                {isAlreadyPremium ? <span>Clone AI Infinite</span> : <span>Passez sur Clone AI Infinite</span>}
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* { !cloneWorking && ( */}
                    <div className={`w-full h-full sm:h-auto sm:w-auto border-lightGraySkills sm:flex sm:flex-row sm:items-end  sm:gap-4`}>
                        {refs.current.map((discussion, i) => (
                            <ChatRoom
                                ref={discussion.ref}
                                chatType={discussion.chatType}
                                type={discussion.type}
                                key={i}
                                discussionId={discussion.id}
                                cloneDialogueState={dialogueStates[discussion.id] || false}
                                userFrom={discussion.userFrom}
                                open={discussion.open}
                                onFocus={onFocus}
                                onClose={onChatClose}
                                onCloseDiscussions={closeDiscussions}
                                onGoToHome={handleGoToHome}
                                onSubmit={chatAddMessage}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>,
        document.body
    );
}

export default Discussion;
