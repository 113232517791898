import { Fragment, useContext, useRef, useState } from 'react';

//API
import { postCounter } from 'api/counters';
import { archiveAllNotifications } from 'api/matchingNeeds';
//CONTEXTS
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
//HOOKS
import useHasCounter from 'hooks/useHasCounter';

import CardProfilRoom from 'containers/profils/CardProfilRoom';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import ImageSlider from '../rooms/ImageSlider';

import Modal from 'components/common/Modal';

import CardProfilSmall from 'containers/profils/CardProfilSmall';

import { searchUsers } from 'api/users';

import { XCircleIcon } from '@heroicons/react/solid';

import { ClipLoader } from 'react-spinners';

const { default: UseIntersectionObserver } = require('hooks/useIntersectionObserver');
const { useInfiniteQuery } = require('react-query');

function CardFrontNeed({ need, needRefetch, profile, userId, socket, listingQueryKeys, isNeedsReceived, sharedFrom }) {
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);

    const [openShareModal, setOpenShareModal] = useState(false);

    const isHiddenNeedForMe = useHasCounter(need.counters, 'hidden_need_forMe', need.id ? need.id : need._id);

    const needRef = useRef(null);

    const [start, setStart] = useState(false);
    const page = useRef(0);
    const hasNextPage = useRef(false);

    const [search, setSearch] = useState('');

    const { data, fetchNextPage, isFetchingNextPage, refetch, isFetching } = useInfiniteQuery('skillers', ({ pageParam = 0 }) => searchUsers(`?p=${pageParam}&l=5&u=${userId}&q=${search}`), {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.hasNextPage) {
                hasNextPage.current = lastPage.hasNextPage;

                return (page.current = lastPage.nextPage);
            } else {
                hasNextPage.current = false;
                page.current = 0;
            }
            return;
        },
        enabled: !!start,
    });

    //REFS
    const loadMoreButtonRef = useRef();
    //HOOKS
    UseIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: !!hasNextPage.current,
    });

    function hiddenNeedForMe() {
        if (authState.isLogged) {
            postCounter({ type: 'hidden_need_forMe', to: need._id ? need._id : need.id }).then(() => {
                needRef.current.classList.add('opacity-0', 'transform', '-translate-y-2'); // Add fade-out classes
                needRefetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    function openDiscussion() {
        store.set({ type: 'add', key: 'openChat', value: { open: true, userId: userId } });
    }

    function handleArchiveAll(e) {
        e?.preventDefault();
        e?.stopPropagation();
        archiveAllNotifications().then((data) => {
            needRefetch();
            socket.send('notificationsRefresh');
        });
    }
    
    function handleOpenShareModal() {
        page.current = 0;
        setStart(true);
        setOpenShareModal(!openShareModal);
    }

    function handleSearch(e) {
        setSearch(e.target.value);
        if (search.length > 3) {
            if (!isFetching) {
                setTimeout(() => {
                    refetch();
                }, 1000);
            }
        }
    }

    function handleResetSearch() {
        setSearch('');
        setTimeout(() => {
            refetch();
        }, 1000);
    }

    if (isHiddenNeedForMe === true) return null;

    return (
        <>
            <Modal
                title="Transférer à"
                open={openShareModal}
                noBorderNeed={true}
                noBorder={true}
                onClose={{
                    onClick: () => {
                        setOpenShareModal(false);
                    }
                }}
            >
                <>
                    <div className='relative'>
                        <input
                            type="text"
                            placeholder="Rechercher un utilisateur"
                            className="w-full border border-gray-200 rounded-lg p-2 pl-8"
                            value={search}
                            onChange={handleSearch}
                        />
                        <XCircleIcon className="absolute top-2 right-2 h-6 w-6 text-purpleSkills cursor-pointer" onClick={() => handleResetSearch()}/>
                    </div>
                    <div className="w-full h-[300px] overflow-scroll">
                        <>
                            {data?.pages && (
                                <div className="w-full flex flex-col p-2">
                                    {data?.pages?.map((pages, index) => (
                                        <Fragment key={index}>
                                            {pages.docs.map((user, j) => (
                                                <div
                                                    key={j}
                                                    className="w-full flex flex-col justify-between h-full sm:-mx-2"
                                                >
                                                    <div className="card_profile" key={j}>
                                                        <CardProfilSmall user={user} refetch={refetch} isSearch={true} need={need}/>
                                                    </div>
                                                </div>
                                            ))}
                                        </Fragment>
                                    ))}
                                </div>
                            )}
                            {isFetching && (
                                <div className="flex justify-center mt-4">
                                    <ClipLoader color={'#3f3d56'} loading={true} size={25} />
                                </div>
                            )}
                            {data?.pages && data.pages[0].docs.length === 0 && (
                                <div className="text-center mt-4">
                                    <p>Aucun utilisateur trouvé</p>
                                </div>
                            )}
                        </>
                        {hasNextPage.current && (
                            <div className="text-center mt-4">
                                <button
                                    ref={loadMoreButtonRef}
                                    className="button button-secondary"
                                    onClick={fetchNextPage}
                                    disabled={!hasNextPage.current || isFetchingNextPage}
                                >
                                    {isFetchingNextPage ? 'Chargement...' : 'Voir plus'}
                                </button>
                            </div>
                        )}
                    </div>
                </>
            </Modal>
            <div
                ref={needRef}
                id={`need${need._id ? need._id : need.id}`}
                className={`w-full flex flex-col justify-between h-full border-b border-l border-r rounded-xl border-white sm:hover:bg-white transition-opacity duration-500${
                    need.currentUserStatus?.unreaded === true ? 'bg-lightBlueSkills bg-opacity-50 rounded-xl cursor-pointer' : ''
                }`}
                onClick={need.currentUserStatus?.unreaded === true ? handleArchiveAll : undefined}
            >
                {isNeedsReceived && sharedFrom && (
                    <CardProfilSmall user={sharedFrom} refetch={refetch} isSharing={true}/>
                )}
                <div className={`card-header mb-1 relative w-full`}>
                    <div className="flex items-center justify-between">
                        <CardProfilRoom
                            user={need?.user}
                            room={need}
                            hiddenDate={true}
                            canFollow={true}
                            refetch={refetch}
                            listingQueryKeys={listingQueryKeys}
                        />
                    </div>

                    <div className="w-full" onClick={(e) => {}}>
                        <div className="w-full">
                            <div className="sm:flex px-4 items-center gap-2 w-full">
                                <div className={`flex-grow ${need?.medias?.length > 0 ? 'sm:w-7/12' : ''}`}>
                                    <div className="card-item-question break-word font-bold text-sm mt-3 text-gray-700 -mb-2">
                                        {/* <SeeMore
                                    className={'font-bold text-sm mt-3 text-gray-700 sm:text-purpleSkills -mb-2'}
                                    string={need?.title}
                                    linkActive={false}
                                    readMoreIsLink={true}
                                /> */}
                                        <p>{need?.title}</p>
                                    </div>
                                    <div className="text-xs text-graySkills flex items-center gap-1 mb-2">
                                        <LocationMarkerIcon className="w-4 h-4 mb-0.5" />
                                        <span>À {need?.location?.address}</span>
                                    </div>
                                </div>

                                {need?.medias?.length > 0 && (
                                    <div className="sm:w-4/12 h-full mb-2">
                                        <ImageSlider type={'need'} room={need} link={false} goToRoom={false} isCard={true}/>
                                    </div>
                                )}
                            </div>

                            <div className="flex items-center justify-between mb-3">
                                {need.user?._id !== authState.me?.id && (
                                    <div className="flex justify-start gap-2 ">
                                        <div>
                                            <button
                                                onClick={() => {
                                                    hiddenNeedForMe();
                                                }}
                                                className="text-graySkills rounded-3xl font-bold px-4 py-1 text-sm hover:text-blueMain"
                                            >
                                                Supprimer
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    openDiscussion();
                                                }}
                                                className="bg-white text-purpleSkills rounded-3xl font-bold px-4 py-1 text-sm hover:text-blueMain"
                                            >
                                                Répondre
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    handleOpenShareModal();
                                                }}
                                                className="bg-white text-purpleSkills rounded-3xl font-bold px-4 py-1 text-sm hover:text-blueMain"
                                            >
                                                Transférer à
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {need.currentUserStatus?.unreaded === true && (
                                    <div className="w-16 flex justify-center">
                                        <span
                                            className="relative block rounded-full h-2 w-2 bg-blueMain"
                                            style={{ boxShadow: '0px 0px 15px 2px rgba(17, 67, 244, 0.25)' }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardFrontNeed;
