import { useContext, useState, useRef, useCallback } from 'react';

import config from 'utils/constants';
//COMPONENTS
//import PlayerVideo from 'components/common/PlayerVideo';
import Button from 'components/common/Button';
import Share from 'components/share/Share';
//API
import { useQuery } from 'react-query';
import { postIncrement, getShoot } from 'api/shoots';
import { postCounter } from 'api/counters';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { SocketContext } from 'context/socketContext';

//UTILS
import { countAnswers } from 'utils/functions';

//HOOKS
import useHasCounter from 'hooks/useHasCounter';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useNavigate } from 'react-router-dom';
//CONTAINERS
import Modal from 'components/common/Modal';
import CardProfilShoot from 'containers/profils/CardProfilShoot';
import Answers from 'containers/answers/Answers';
import AnswerForm from 'containers/answers/AnswerForm';

//ICONS
import { HeartIcon, DotsVerticalIcon, EyeIcon, ChatIcon, XCircleIcon, BookmarkIcon, SearchIcon } from '@heroicons/react/solid';
import OptionsMenu from '../../components/common/OptionsMenu';
import Video from '../../components/common/Video';

function CardShootMobile(props) {
    const [playing, setPlaying] = useState(false);
    const [modalComment, setModalComment] = useState(false);

    const [muted, setMuted] = useState(false);

    const handleMuted = () => {
        setMuted(!muted);
        refetchShoot();
    };

    const shoot = useQuery(
        ['shoot', props?.shoot?.slug],
        (params) => {
            return getShoot(props?.shoot?.slug);
        },
        { enabled: props?.shoot?.slug ? true : false, staleTime: 0 }
    );

    //HOOKS
    const navigate = useNavigate();
    const hasCounter = useHasCounter(shoot?.data?.current?.counters, 'like_shoot', shoot?.data?.current?._id);
    const hasBookmark = useHasCounter(shoot?.data?.current?.counters, 'bookmark_shoot', shoot?.data?.current?._id);

    const likeCounterCount = shoot?.data?.current?.counters?.filter((counter) => counter.type === 'like_shoot').length;
    const bookmarkCounterCount = shoot?.data?.current?.counters?.filter((counter) => counter.type === 'bookmark_shoot').length;

    //CONTEXTS
    const socket = useContext(SocketContext);
    const [authState] = useContext(AuthContext);

    //REFS
    const shootRef = useRef(null);

    function handleSubscribe(shootId, shootData) {
        if (authState.isLogged) {
            let infos = {
                titleShoot: shootData?.title,
                slugShoot: shootData?.slug,
                poster: `/downloads/videos/${shootData?.user?.id}/screenshot_${shootData?.video._id}_${shootData?.selectedScreen}.jpg`,
            };

            postCounter({ userTo: shootData?.user.id, type: 'like_shoot', to: shootId, infos: infos }).then(() => {
                shoot.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    function handleBookmark(shootId) {
        if (authState.isLogged) {
            postCounter({ userTo: shoot?.data?.current.user.id, type: 'bookmark_shoot', to: shootId }).then(() => {
                shoot.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    function onStart() {
        postIncrement(props.shoot._id);
        window.history.replaceState(null, null, `/shoots/${props.shoot.slug}`);
        if (authState.isLogged) {
            let data = {
                id: authState?.me?.id,
                location: `/shoots/${props.shoot.slug}`,
            };
            document.title = `${props?.shoot?.user?.profile?.pseudo} - ${props?.shoot?.title}`;
            document.querySelector('meta[name="description"]').setAttribute('content', props?.shoot?.description);
            setTimeout(() => {
                socket.send('userActivity', data);
            }, 1000);
        }
    }

    function closeOptionsMenu(shoot) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${shoot._id}`) {
                el.classList.add('hidden');
            }
        });
    }

    function handleOptionsMenu(shoot) {
        closeOptionsMenu(shoot);
        document.getElementById(`optionsMenu_${shoot._id}`).classList.toggle('hidden');
    }

    const handleGoToSearch = useCallback(() => {
        navigate('/search');
    }, [navigate]);

    useIntersectionObserver({
        target: shootRef,
        onIntersect: (isVisible) => {
            setPlaying(isVisible);
            if (isVisible) {
                window.history.replaceState(null, null, `/shoots/${props.shoot.slug}`);
                let data = {
                    id: authState?.me?.id,
                    location: `/shoots/${props.shoot.slug}`,
                };
                document.title = `${props?.shoot?.user?.profile?.pseudo} - ${props?.shoot?.title}`;
                document.querySelector('meta[name="description"]').setAttribute('content', props?.shoot?.description);
                setTimeout(() => {
                    socket.send('userActivity', data);
                }, 1000);
            }
        },
        enabled: true,
    });

    function refetchShoot() {
        shoot.refetch();
    }

    return (
        <>
            <Modal modalWidth={'w-screen h-screen overflow-y-auto relative'} open={modalComment} title={false} fullScreen={true} noMaxWidth={true}>
                <div className={`relative sm:block sm:p-2 w-full lg:w-[600px] h-screen overflow-auto`}>
                    {shoot.status === 'success' && shoot.data.current && (
                        <div className="grid grid-auto-rows p-4">
                            <CardProfilShoot user={shoot.data.current.user} shoot={shoot.data.current} />
                            <div className="px-2 py-2 w-full bg-white md:bg-transparent bottom-2 z-10">
                                <AnswerForm item={shoot.data.current} type={'shoot'} refetch={refetchShoot} options={{ placeholder: null, isShoot: true }} />
                            </div>
                            <div>
                                {shoot.data.current?.answers && (
                                    <Answers item={shoot.data.current} counters={shoot.data.current?.counters} type={'shoot'} refetch={refetchShoot} />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="absolute top-2 right-2 flex flex-col gap-10">
                    <div className="flex flex-col gap-1 items-center">
                        <Button label={<XCircleIcon className="w-10 h-10 text-purpleSkills" />} justIcon={true} onClick={() => setModalComment(false)} />
                    </div>
                </div>
            </Modal>
            {/*  {shoot.status === 'success' && shoot.data.current && ( */}
            <div
                ref={shootRef}
                id={`shoot_${props.shoot._id}`}
                className="videoContainer relative w-full h-full"
                onClick={(e) => {
                    e.preventDefault();
                }}
                /* style={{ maxHeight: `100vh`, height: '100%' }} */
            >
                <Video
                    shoot={props.shoot}
                    /* nextShoot={props.nextShoot} */
                    muted={muted}
                    handleMuted={handleMuted}
                    playing={playing}
                    onStart={onStart}
                    videoFitCover={false}
                    width={'100%'}
                    poster={`${config.serverUrl}/downloads/videos/${props.shoot?.user?.id}/screenshot_${props.shoot?.video._id}_${props.shoot?.selectedScreen}.jpg`}
                />

                <div className="videoOverlay flex flex-col-reverse absolute bottom-0 left-0 top-0 right-0">
                    <div className="relative flex-grow flex-shrink flex-basis-auto">
                        <div
                            className={`absolute bottom-32 sm:bottom-10 right-4 flex flex-col gap-1`}
                            style={{ zIndex: 40, transform: 'translate3d(0, 0, 0)' }}
                        >
                            {shoot.status === 'success' && shoot.data.current && (
                                <>
                                    <div className="flex flex-col gap-0.5 items-center">
                                        <Button
                                            onClick={() => handleOptionsMenu(shoot?.data?.current)}
                                            label={<DotsVerticalIcon className="w-8 h-8 mx-auto" />}
                                            justIcon={true}
                                            css="w-10 h-10 rounded-full text-white"
                                        />
                                        <OptionsMenu shoot={shoot?.data?.current} user={shoot?.data?.current.user} refresh={props.refetch} type="shoot" />
                                    </div>
                                    <div className="flex flex-col gap-0.5 items-center mb-4">
                                        <Button
                                            onClick={() => handleGoToSearch()}
                                            label={<SearchIcon className="w-8 h-8 mx-auto" />}
                                            justIcon={true}
                                            css="w-10 h-10 rounded-full text-white"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-0.5 items-center">
                                        <Button label={<EyeIcon className="w-8 h-8 mx-auto" />} justIcon={true} css="w-10 h-10 rounded-full text-white" />
                                        <span className="text-xs text-white">{shoot?.data?.current.views}</span>
                                    </div>
                                    <div className="flex flex-col gap-0.5 items-center">
                                        <Button
                                            label={<ChatIcon className="w-8 h-8 mx-auto" />}
                                            justIcon={true}
                                            css="w-10 h-10 text-white"
                                            onClick={() => setModalComment(true)}
                                        />
                                        <span className="text-xs text-white">{countAnswers(shoot?.data?.current.answers)}</span>
                                    </div>
                                    <div className="flex flex-col gap-0.5 items-center">
                                        <Share className="text-white" type="outline" hrefUrl={window.location.href} />
                                    </div>
                                    <div className="flex flex-col gap-0.5 items-center">
                                        <Button
                                            label={<HeartIcon className="w-8 h-8 mx-auto" />}
                                            justIcon={true}
                                            css={`w-10 h-10 rounded-full ${hasCounter ? 'text-red' : 'text-white'}`}
                                            onClick={() => handleSubscribe(shoot?.data?.current._id, shoot?.data?.current)}
                                        />
                                        <span className={`text-xs text-white`}>{likeCounterCount}</span>
                                    </div>
                                    <div className="flex flex-col gap-0.5 items-center">
                                        <Button
                                            label={<BookmarkIcon className="w-8 h-8 mx-auto" />}
                                            justIcon={true}
                                            css={`w-10 h-10 rounded-full ${hasBookmark ? 'text-purpleSkills' : 'text-white'}`}
                                            onClick={() => handleBookmark(shoot?.data?.current._id)}
                                        />
                                        <span className={`text-xs text-white`}>{bookmarkCounterCount}</span>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="absolute px-1 bottom-2 left-0 w-[80%]" style={{ zIndex: 40, transform: 'translate3d(0, 0, 0)' }}>
                            <CardProfilShoot type="shootMobile" user={props.shoot.user} shoot={props.shoot} />
                        </div>
                    </div>
                </div>
            </div>
            {/*  )} */}
        </>
    );
}

export default CardShootMobile;
