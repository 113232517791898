import React, { useContext, useRef, useEffect, useCallback, useState } from 'react';

//API
import { putProfile } from 'api/users';
//COMPONENTS
import ProgressBar from 'components/common/ProgressBar';
import FieldExperiences from 'components/forms/fields/Experiences';
import FieldPermis from 'components/forms/fields/Permis';
import FieldQualifications from 'components/forms/fields/Qualifications';
import FielHobbies from 'components/forms/fields/Hobbies';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';

//HOOKS
import useCurrentForm from 'hooks/useForm';

//LIBS
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { XCircleIcon } from '@heroicons/react/outline';
import FieldLanguages from '../fields/Languages';
import { isMobile } from 'utils/functions';

function FormProfileStepXP(props) {
    const [readyToWatch, setReadyToWatch] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [experiences, setExperiences] = useState([]);
    const [permis, setPermis] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [hobbies, setHobbies] = useState([]);

    const [progressBar, setProgressBar] = useState({ active: false, value: 0 });

    //CONTEXTS
    const [authState, , refresh] = useContext(AuthContext);
    const [formContextState, formContextDispatch] = useContext(FormContext);

    //REFS
    const currentForm = useRef(null);
    const seeLink = useRef(null);

    //HOOKS
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm(/* {
        resolver: yupResolver(schema),
    } */);

    //DISPATCH
    const dispatchAction = useCallback(() => {
        switch (formContextState.action) {
            case 'save':
                currentForm.current.click();
                break;
            default:
        }
        // eslint-disable-next-line
    }, [formContextState.action]);

    //EFFECTS
    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
        }
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        dispatchAction();
    }, [dispatchAction]);

    useEffect(() => {
        const me = authState.me;

        if (me) {
            seeLink.current = '/profil/' + me.profile?.slug;
            formContextDispatch({ type: 'publish', status: true });

            if (me.profile?.experiences) {
                setExperiences(me.profile.experiences);
            }
            if (me.profile?.permis) {
                setPermis(me.profile.permis);
            }
            if (me.profile?.languages) {
                setLanguages(me.profile.languages);
            }
            if (me.profile?.qualifications) {
                setQualifications(me.profile.qualifications);
            }
            if (me.profile?.hobbies) {
                setHobbies(me.profile.hobbies);
            }
            const timeoutId = setTimeout(() => {
                setReadyToWatch(true);
            }, 1000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
        //eslint-disable-next-line
    }, [authState]);

    useCurrentForm(readyToWatch, watch);

    const onSubmitClose = () => {
        handleSubmit((data) => onSubmit(data, 'close'))();
    };

    const onSubmitNext = () => {
        handleSubmit((data) => onSubmit(data, 'next'))();
    };

    const onSubmit = (data, button) => {
        if (errors.length > 0) return false;

        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = null;
                item = data[k];
                if (k === 'experiences' || k === 'permis' || k === 'qualifications' || k === 'languages' || k === 'hobbies') {
                    item = JSON.stringify(data[k]);
                    fd.append(k, item);
                } else fd.append(k, item);
            }
        }

        const put = putProfile(fd, function (e) {
            let progress = (e.loaded / e.total) * 100;
            formContextDispatch({ type: 'progress', status: progress });

            setProgressBar({ ...progressBar, active: true, value: progress });
        });
        put.then((res) => {
            if (res.status === 200) {
                if (!isMobile()) {
                    toast('Profil mis à jour', { type: 'success' });
                }
                if (button === 'close') {
                    props.handleCloneCreate();
                    props.handleGoToProfile();
                    props.setPage(0);
                } else if (button === 'next') {
                    props.setPage(props.page + 1);
                }
                refresh();
                setTimeout(() => {
                    formContextDispatch({ type: 'change', status: false });
                    setTimeout(() => {
                        formContextDispatch({ type: 'progress', status: 0 });
                    }, 400);
                }, 100);
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    };

    function handleSkipPage() {
        props.setPage(props.page + 1);
    }

    /* function submit() {
        handleSubmit(onSubmit)();
    } */

    return (
        <div className="w-full flex flex-col gap-6 mt-6">
            {props.typeSteps === 'ai' ? (
                <div className="text-white">
                    <h1 className="font-bold text-l">Permisss, expériences et qualifications</h1>
                    <p className="text-sm">Ces informations aideront votre Clone AI à être plus efficace.</p>
                </div>
            ) : (
                <h1 className="font-bold text-xl text-purpleSkills">Permis, expériences, qualifications, langues et hobbies</h1>
            )}

            <form onSubmit={(e) => e.preventDefault()} className="form" autoComplete="off" encType="multipart/form-data">
                {props.typeSteps !== 'ai' && (
                    <div className="absolute top-2 right-2">
                        <XCircleIcon className={`w-10 h-10 text-graySkills hover:text-blueMain hover:cursor-pointer`} onClick={onSubmitClose} />
                    </div>
                )}
                <ProgressBar progress={progressBar.value} />
                <div className="w-full flex flex-col gap-8 sm:gap-24">
                    <div className={`flex flex-col sm:flex-row gap-3 ${isMobile() ? 'w-[90%]' : 'w-[60%]'} self-center`}>
                        {/* <div className="sm:w-1/2 flex flex-col sm:gap-3">
                            <button className="button-gray hover:button-border-gray hover:bg-white justify-end w-56 px-4 py-2">+ Ajouter une expérience</button>
                            <button className="button-gray hover:button-border-gray hover:bg-white justify-end w-56 px-4 py-2">+ Ajouter un permis</button>
                            <button className="button-gray hover:button-border-gray hover:bg-white justify-end w-56 px-4 py-2">+ Ajouter un diplôme</button>
                        </div> */}
                        <div className="flex flex-col w-full gap-1 py-2">
                            <FieldExperiences
                                className={`${props.typeSteps === 'ai' ? 'text-white' : ''} text-base font-bold px-8 py-2 w-full`}
                                iconClassName="h-5 w-5"
                                label="Intitulé du poste*"
                                name="experiences"
                                items={experiences}
                                errors={errors}
                                register={register}
                                onChange={(value) => {
                                    setValue('experiences', value);
                                }}
                            />
                            <FieldQualifications
                                className={`${props.typeSteps === 'ai' ? 'text-white' : ''} text-base font-bold px-8 py-2 w-full`}
                                iconClassName="h-5 w-5"
                                label="Intitulé du diplôme*"
                                name="qualifications"
                                items={qualifications}
                                errors={errors}
                                register={register}
                                onChange={(value) => {
                                    setValue('qualifications', value);
                                }}
                            />
                            <FieldPermis
                                className={`${props.typeSteps === 'ai' ? 'text-white' : ''} text-base font-bold px-8 py-2 w-full`}
                                iconClassName="h-5 w-5"
                                label="Type du permis*"
                                name="permis"
                                items={permis}
                                errors={errors}
                                register={register}
                                onChange={(value) => {
                                    setValue('permis', value);
                                }}
                            />
                            <FieldLanguages
                                className={`${props.typeSteps === 'ai' ? 'text-white' : ''} text-base font-bold px-8 py-2 w-full`}
                                iconClassName="h-5 w-5"
                                label="Langue*"
                                name="languages"
                                items={languages}
                                errors={errors}
                                register={register}
                                onChange={(value) => {
                                    setValue('languages', value);
                                }}
                            />
                            <FielHobbies
                                className={`${props.typeSteps === 'ai' ? 'text-white' : ''} text-base font-bold px-8 py-2 w-full`}
                                iconClassName="h-5 w-5"
                                label="Hobby*"
                                name="hobbies"
                                items={hobbies}
                                errors={errors}
                                register={register}
                                onChange={(value) => {
                                    setValue('hobbies', value);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <input ref={currentForm} type="submit" className="hidden" />
            </form>
            <div className="w-full flex gap-0 pb-7 justify-end">
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        handleSkipPage();
                    }}
                    className={`${props.typeSteps === 'ai' ? 'text-white' : 'text-graySkills'} w-auto px-8 py-2 font-bold`}
                >
                    Plus tard
                </button>
                <button
                    className={`${
                        props.typeSteps === 'ai'
                            ? 'bg-[#E348FF] rounded-full hover:text-blueMain'
                            : 'bg-purpleSkills rounded-full button-border-gray hover:text-graySkills'
                    } 
                                    button font-bold text-white hover:bg-white justify-end w-auto px-8 py-2`}
                    onClick={onSubmitNext}
                >
                    Suivant
                </button>
                {/*  <button className="button-gray hover:button-border-gray hover:bg-white w-56 px-4 py-2">Suivant</button> */}
            </div>
        </div>
    );
}

export default FormProfileStepXP;
