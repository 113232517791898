import { Link } from 'react-router-dom';

//UTILS
import config from 'utils/constants';

//COMPONENTS
import Image from 'components/common/Image';
import PingConnected from 'components/PingConnected';
//import Moment from 'components/common/Moment';

//CONTAINERS
import PointGamification from 'containers/profils/PointGamification';

const sizes = {
    avatar: '?w=120&h=120&c=true&q=80',
    media: '?h=250&q=90',
};

function CardProfilShoot({ user, shoot, type }) {
    let shootTitle = shoot?.title;
    if (shootTitle && shootTitle.length > 100) {
        shootTitle = shootTitle.substring(0, 100) + '...';
    }

    return (
        <>
            <div className="relative w-full my-2">
                <Link to={'/profil/' + user?.profile?.slug} title={`Profil de ${user?.profile?.pseudo}`}>
                    <div className="flex flex-row items-start w-full relative">
                        <div className="flex-none relative w-14 h-auto mr-2">
                            {user?.profile?.avatar?.url ? (
                                <Image
                                    src={user.profile?.avatar?.url + sizes.avatar}
                                    alt={user.profile?.pseudo}
                                    loading="false"
                                    className="w-12 bg-white rounded-full"
                                />
                            ) : (
                                <img className="rounded-full" src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar} alt={user?.profile?.pseudo} />
                            )}

                            <PointGamification gamification={user?.gamification?.profile} />
                            <PingConnected connexions={user?.connexions} type="global" />
                        </div>
                        {/* <div className="w-auto grow flex flex-wrap flex-col">
                            <span className={`text-sm md:text-xl font-bold ${type === 'shootMobile' ? 'text-white' : 'text-purpleSkills'} `}>
                                {user?.profile.pseudo}
                            </span>
                            <span className={`text-sm font-normal text-graySkills`}>{user?.profile.status}</span>
                        </div> */}
                    </div>
                </Link>
                <div className="mt-4">
                    <span className={`font-normal ${type === 'shootMobile' ? 'text-white' : 'text-main'} text-sm mt-4 h-max-2`}>{shootTitle}</span>
                    {shoot.tags && (
                        <div className="flex flex-wrap flex-row -mx-2">
                            {shoot.tags?.map((tag, index) => (
                                <Link to={`/search?q=${tag.name}`} key={index} className="link_basic cursor-pointer mb-1">
                                    {tag.name}
                                </Link>
                            ))}
                        </div>
                    )}
                    {shoot && <span className="text-graySkills font-normal text-xs">{/*<Moment date={shoot?.createdAt} fromNow />*/}</span>}
                </div>
            </div>
        </>
    );
}

export default CardProfilShoot;
