import { useEffect, useCallback, useState, useContext, useRef } from 'react';

//UTILS
import { countObj, autoresize } from 'utils/functions';
import config from 'utils/constants';
//LIBS
import _ from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//API
import { postAnswer as postAnswerRoom } from 'api/forum';
import { postAnswer as postAnswerShoot } from 'api/shoots';
import { getForumResponse } from 'api/clone';

//FORMS
import schema from 'components/forms/yup/answer';

//HOOKS
import useSpeechToText from 'react-hook-speech-to-text';

//ICONS
import { PhotographIcon, PaperAirplaneIcon } from '@heroicons/react/solid';
import { LoginIcon } from '@heroicons/react/outline';

//COMPONENTS
import Modal from 'components/common/Modal';
import FieldFiles from 'components/forms/fields/Files';
import BuyCloneAi from 'components/common/BuyCloneAi';

//CONTAINERS
import Avatar from 'containers/profils/Avatar';
import UserLink from 'containers/profils/UserLink';

function AnswerForm(props) {
    const [countMedias, setCountMedias] = useState(0);
    const [openConfirmSubmit, setOpenConfirmSubmit] = useState(false);
    const [isAnswerEmpty, setIsAnswerEmpty] = useState(true);
    const [answerMedias, setAnswerMedias] = useState([]);
    const [openModalPictures, setOpenModalPictures] = useState(false);
    const [openModalNotAllowed, setOpenModalNotAllowed] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [aiInstruction, setAiInstruction] = useState(false);
    const [aiInstructionText, setAiInstructionText] = useState('');

    const [isDisabled, setIsDisabled] = useState(false);

    const [openUserLink, setOpenUserLink] = useState(false);

    const answerRef = useRef(null);
    const userLinkRef = useRef(null);

    const [authState] = useContext(AuthContext);
    const location = useLocation();

    const [isMessageCloneExceeded, setMessageCloneExceeded] = useState(false);
    const [messageCloneData, setMessageCloneData] = useState(null);

    const speech = useSpeechToText({
        continuous: true,
        useLegacyResults: false,
    });

    //FORM
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { ref, ...rest } = register('answer');
    const answer = useWatch({ control, name: 'answer' });

    function onKeyDown(e) {
        if (e.key === '@') {
            setOpenUserLink(true);
        }
    }

    function onCloseUserLink(user) {
        if (user !== undefined) {
            let newAnswer = answer ?? '';
            newAnswer += '@' + user?.profile?.slug + ' ';
            setValue('answer', newAnswer);
        }
        answerRef.current.focus();
        setOpenUserLink(false);
    }

    useEffect(() => {
        if (openUserLink) {
            userLinkRef.current.focus();
        }
    }, [openUserLink]);

    useEffect(() => {
        if (answer !== undefined) handleChange();
        //eslint-disable-next-line
    }, [answer]);

    const handleOpenInscription = useCallback(() => {
        document.getElementById('button-login')?.click();
    }, []);

    function handleOpenPictures() {
        setAnswerMedias(getValues('medias'));
        setOpenModalPictures(true);
    }

    function handleChange() {
        if (authState.isLogged) {
            if (authState?.me?.gamification?.profile >= 5) {
                const answerValue = getValues('answer');
                const mediasValue = getValues('medias');

                setCountMedias(countObj(mediasValue));
                setIsAnswerEmpty(!countObj(answerValue));
            } else {
                setOpenModalNotAllowed(true);
            }
        }
    }

    function submitAnswer() {
        if (!openUserLink) handleSubmit(onSubmit)();
    }

    function onSubmit() {
        if (errors.length > 0) return false;

        setOpenConfirmSubmit(true);
    }

    function handleConfirm() {
        setIsDisabled(true);
        setOpenConfirmSubmit(false);
        setCountMedias(0);
        setIsAnswerEmpty(true);

        const data = getValues();
        data.itemId = props.item.id;
        data.type = props.item.type;

        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = null;
                item = data[k];
                if (k === 'medias') {
                    let files = data[k];
                    for (let i = 0; i < files.length; i++) {
                        fd.append(k, files[i]);
                    }
                } else fd.append(k, item);
            }
        }
        let post = null;
        if (props?.type === 'shoot') {
            post = postAnswerShoot(fd);
        } else if (props?.type === 'room') {
            post = postAnswerRoom(fd);
        } else {
            toast('Une erreur est survenue', { type: 'danger' });
        }

        post.then((res) => {
            if (res.status === 200) {
                toast('Votre réponse a bien été postée', { type: 'success' });
                reset();
                props.refetch();
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
            setIsDisabled(false);
        });
    }

    async function generateResponse() {
        setAiInstruction(false);
        const data = getValues();
        data.question = props.item.question;
        data.forumId = props.item.id;
        data.userId = authState?.me?.id;
        data.instructions = aiInstructionText;

        try {
            setWaiting(true);
            const getResponse = await getForumResponse(data);
            if (getResponse.status === 200) {
                let response = getResponse.data;
                setWaiting(false);
                setValue('answer', response);
                handleChange();
            } else if (getResponse.status === 201) {
                setWaiting(false);
                setMessageCloneData(getResponse.data);
                setMessageCloneExceeded(true);
            } else {
                toast('Une erreur est survenue réessayer plus tard', { type: 'danger' });
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast('Une erreur est survenue réessayer plus tard', { type: 'danger' });
        }
    }

    useEffect(() => {
        setValue('medias', []);

        //eslint-disable-next-line
    }, [, location]);

    const watchFields = watch('answer');
    useEffect(() => {
        const ta = document.getElementById('answer_textarea');
        if (ta) autoresize(ta);
    }, [watchFields]);

    useEffect(() => {
        const speechResult = _.last(speech.results);
        if (speechResult) {
            setIsAnswerEmpty(false);
            setValue('answer', speechResult.transcript);
            speech.stopSpeechToText();
        }
        //eslint-disable-next-line
    }, [speech.results]);

    return (
        <>
            <Modal
                open={openModalNotAllowed}
                onConfirm={{
                    label: "J'ai compris",
                    onClick: () => {
                        setValue('answer', '');
                        setOpenModalNotAllowed(false);
                    },
                }}
                onClose={{
                    onClick: () => {
                        setValue('answer', '');
                        setOpenModalNotAllowed(false);
                    },
                }}
            >
                <div className="flex flex-col">
                    <h2 className="font-bold">Vous devez avoir un minimum de 5 points pour pouvoir bénéficier de cette fonctionnalité</h2>
                    <p className="text-sm text-purpleSkills mt-2">Vous pouvez y parvenir en complétant votre profil !</p>
                </div>
            </Modal>
            <Modal
                title="Confirmer votre réponse"
                open={openConfirmSubmit}
                onClose={{
                    onClick: () => {
                        setOpenConfirmSubmit(false);
                    },
                }}
                onConfirm={{
                    label: 'Valider',
                    disabled: isDisabled,
                    onClick: handleConfirm,
                }}
            >
                <p>Voulez-vous vraiment poster cette réponse ?</p>
            </Modal>
            <Modal
                open={openModalPictures}
                onConfirm={{
                    label: 'Valider',
                    onClick: () => {
                        setOpenModalPictures(false);
                        handleChange();
                    },
                }}
                onClose={{
                    onClick: () => {
                        setOpenModalPictures(false);
                    },
                }}
            >
                <div className="flex flex-col">
                    {authState.isLogged && (
                        <div className="w-full">
                            <FieldFiles
                                name="medias"
                                label="Déposez ou cliquez ici pour uploader une image"
                                type="multiple"
                                multiple={true}
                                register={register}
                                initValue={answerMedias}
                                fromBlob={true}
                                readyToWatch={true}
                                onChange={(value) => {
                                    setValue('medias', value);
                                    handleChange();
                                }}
                                accepts={['image/png', 'image/jpeg', 'image/jpg']}
                            />
                        </div>
                    )}
                </div>
            </Modal>
            <Modal
                title="Votre clone AI répond pour vous :"
                open={aiInstruction}
                onClose={{
                    onClick: () => {
                        setAiInstruction(false);
                    },
                }}
                onConfirm={{
                    label: 'Générer',
                    onClick: generateResponse,
                }}
            >
                <textarea
                    rows="4"
                    className="w-full bg-lightGraySkills h-auto max-h-28 sm:text-sm card-item-question rounded-xl p-2"
                    style={{ height: 'auto' }}
                    placeholder={`Donnez lui vos instructions pour une réponse qui vous ressemble`}
                    onChange={(e) => {
                        setAiInstructionText(e.target.value);
                    }}
                ></textarea>
            </Modal>
            {isMessageCloneExceeded && <BuyCloneAi state={isMessageCloneExceeded} setState={setMessageCloneExceeded} data={messageCloneData} />}
            <>
                {errors && errors['answer'] && (
                    <div className="pb-2">
                        <span className="text-danger-400 text-bold">{errors['answer'].message}</span>
                    </div>
                )}
            </>

            <form onSubmit={(e) => e.preventDefault()}>
                <div className={`flex flex-row w-full ${isAnswerEmpty && !props?.options?.isShoot ? 'md:w-auto' : 'w-full'} gap-2`}>
                    <div className="flex-auto w-auto h-auto rounded-t-3xl rounded-b-3xl bg-white sm:pr-2">
                        <div className="flex flex-row h-full items-center">
                            <div className={`hidden sm:block h-auto my-auto mr-2`}>
                                {authState.isLogged && (
                                    <Avatar onClick={() => answerRef.current.focus()} user={authState?.me} className="w-10" gamification={false} />
                                )}
                            </div>
                            <div className="flex flex-col w-auto grow ml-2 sm:ml-0 p-2">
                                {waiting ? (
                                    <div className="w-full">
                                        <span className="waiting-animation text-purpleSkills"></span>
                                    </div>
                                ) : (
                                    <textarea
                                        ref={(e) => {
                                            ref(e);
                                            answerRef.current = e;
                                        }}
                                        autoresize=""
                                        rows="1"
                                        disabled={authState.isLogged ? false : true}
                                        errors={errors}
                                        {...rest}
                                        name="answer"
                                        id="answer_textarea"
                                        placeholder={authState.isLogged ? props.options.placeholder ?? 'Tapez votre réponse' : 'Connectez vous pour répondre'}
                                        className="w-full text-sm bg-white h-auto max-h-28 card-item-question pb-0"
                                        onKeyDown={onKeyDown}
                                    ></textarea>
                                )}
                                <UserLink ref={userLinkRef} open={openUserLink} onClose={(user) => onCloseUserLink(user)} />
                                {countMedias > 0 && <div className="card-item-question py-2 pb-0 text-xs">{countMedias} image(s)</div>}
                            </div>
                            {authState.isLogged && !props.options?.isShoot && (
                                <>
                                    <div className="flex flex-row items-center gap-2">
                                        <div id="medias" onClick={handleOpenPictures} className="cursor-pointer m-0">
                                            <PhotographIcon className="relative h-9 w-9 text-graySkills hover:text-blueMain" />
                                        </div>
                                        {authState?.me?.clone && (
                                            <>
                                                <div className="hidden md:flex min-h-[2rem]">
                                                    <button
                                                        type="submit"
                                                        onClick={() => setAiInstruction(true)}
                                                        className="w-auto h-auto p-2 bg-black text-white font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]"
                                                    >
                                                        Réponse AI
                                                    </button>
                                                </div>
                                                <div className="md:hidden flex">
                                                    <button
                                                        type="submit"
                                                        onClick={() => setAiInstruction(true)}
                                                        className="w-auto text-purpleSkills rounded-full"
                                                    >
                                                        <img className="w-7 h-7" src={`${config.publicDir}logo_ai_switch2.svg`} alt="ai_logo" />
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="w-fit h-auto self-center">
                        <div className="text-center w-fit h-auto">
                            <div className={`hidden relative md:flex flex-row h-10 ${authState.isLogged && 'gap-2 w-full'}`}>
                                <button
                                    type="submit"
                                    onClick={authState.isLogged ? submitAnswer : handleOpenInscription}
                                    className="h-auto border border-blueMain text-white bg-blueMain px-4 flex justify-center items-center rounded-full hover:bg-white hover:text-blueMain transition-colors duration-400"
                                >
                                    {authState.isLogged ? 'Envoyer' : 'Connexion'}
                                </button>
                            </div>
                            <div className="w-fit relative md:hidden">
                                <button
                                    type="submit"
                                    onClick={authState.isLogged ? submitAnswer : handleOpenInscription}
                                    className="w-auto border border-blueMain bg-blueMain text-white p-2 rounded-full hover:border-gray-400 hover:bg-graySkills hover:text-white transition-colors duration-400"
                                >
                                    {authState.isLogged ? <PaperAirplaneIcon className="w-5 h-5" /> : <LoginIcon className="w-5 h-5" />}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default AnswerForm;
