import React, { useContext } from 'react';
import { AuthContext } from '../context/authContext';
import Listing from '../pages/Listing';
import Home from '../pages/Home';

function HomeWelcome() {
    const [authState] = useContext(AuthContext);

    if (authState?.isLogged)
        return <Home />;
    else
        return <Listing/>;
}
export default HomeWelcome;
