import { useEffect, useState } from 'react';

// LIBS
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// FORMS
import schema from 'components/forms/yup/hobby';

// ICONS
import { PlusIcon, XCircleIcon } from '@heroicons/react/solid';

// COMPONENTS
import FieldInput from 'components/forms/fields/input';
import useSafeState from 'hooks/useSafeState';
import Modal from 'components/common/Modal';

function Hobbies(props) {
    const [items, setItems] = useState(props.items);
    const [modalHobby, setModalHobby] = useSafeState(false);
    const [readyToWatch, setReadyToWatch] = useState(false);

    // HOOKS
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    function handleDelete(index, _id) {
        let newHobbies;
        if (_id) {
            newHobbies = items.filter((hobby) => hobby._id !== _id);
        } else {
            newHobbies = [...items];
            newHobbies.splice(index, 1);
        }
        setItems(newHobbies);
        props.onChange(newHobbies);
    }

    function submit() {
        handleSubmit(onSubmit)();
    }

    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
        }
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setReadyToWatch(true);
        }, 1000);

        return () => clearTimeout(timer);
        //eslint-disable-next-line
    }, []);

    const onSubmit = (data) => {
        if (errors.length > 0) return false;
        setModalHobby(false);

        let newHobbies = [
            ...items,
            {
                title: data.title,
                description: data.description,
            },
        ];

        setItems(newHobbies);
        props.onChange(newHobbies);

        setValue('title', data.title);
        setValue('description', data.description);

        const timer = setTimeout(() => {
            setReadyToWatch(true);
        }, 1000);

        return () => clearTimeout(timer);
    };

    useEffect(() => {
        setItems(props.items);
    }, [props.items]);

    function handleHobby(e) {
        e.preventDefault();
        setModalHobby(true);
    }

    return (
        <>
            <Modal
                open={modalHobby}
                title="Ajouter un Hobby"
                onClose={{
                    onClick: () => setModalHobby(false),
                }}
                onConfirm={{
                    label: 'Valider',
                    onClick: submit,
                }}
            >
                <div>
                    <div className="flex gap-3 w-full flex-wrap flex-col">
                        <FieldInput
                            label={`${props.label ? props.label : 'Titre*'}`}
                            inputForm="floating"
                            name="title"
                            size="w-full"
                            errors={errors}
                            register={register}
                        />
                        <FieldInput label="Description*" name="description" inputForm="floating" size="w-full" errors={errors} register={register} />
                    </div>
                </div>
            </Modal>
            <div className="w-full">
                <button className={`flex ${props.className ? props.className : ''} linkCard text-purpleSkills gap-2`} type="button" onClick={handleHobby}>
                    <PlusIcon className={`${props.iconClassName ? props.iconClassName : 'w-4 h-4'}`} />
                    <span>Ajouter un Hobby</span>
                </button>

                <div className="flex flex-col mt-2 gap-2">
                    {items?.map((hobby, k) => (
                        <div key={k} className="p-2 flex flex-col bg-blancSkills rounded">
                            <div className="flex justify-between w-full">
                                <div className="w-full flex flex-col">
                                    <p className="text-purpleSkills font-bold">{hobby.title}</p>
                                    <p className="text-purpleSkills text-sm">{hobby.description}</p>
                                </div>
                                <div className="cursor-pointer">
                                    <XCircleIcon onClick={() => handleDelete(k, hobby._id)} className="m-2 w-5 h-5 text-graySkills" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Hobbies;