import { useCallback, useContext, useEffect, useRef, useState } from 'react';

//STYLES
import './ForumSearchBar.css';

//API
import { getTags } from 'api/search';
import { postForum, putForum } from 'api/forum';

//COMPONENTS
import FieldInput from 'components/forms/fields/input';
import FieldSelect from 'components/forms/fields/Select';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import TextareaCustom from 'components/forms/fields/TextareaCustom';

//CONTAINERS
import Avatar from 'containers/profils/Avatar';
import SearchFilterPlace from 'containers/search/SearchFilterPlace';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//LIBS
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

//FORMS
import FieldFiles from 'components/forms/fields/Files';
import schema from 'components/forms/yup/forum';
// import AddressOption from "components/forms/options/address/AddressOption";

//ICONS
import { BriefcaseIcon, PhotographIcon, PlayIcon, RssIcon } from '@heroicons/react/solid';
import { QuestionMarkCircleIcon, XCircleIcon, PlusCircleIcon } from '@heroicons/react/outline';

//UTILS
import { isMobile } from 'utils/functions';

function ForumSearchBarV2(props) {
    //REFS
    const currentForm = useRef(null);
    const gamificationPoints = useRef(0);
    const inputRef = useRef(null);

    //CONTEXTS
    const [authState] = useContext(AuthContext);

    const [openModal, setOpenModal] = useState(false);
    const [openModalNotAllowed, setOpenModalNotAllowed] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [foundTags, setFoundTags] = useState([]);
    const [isPublic, setIsPublic] = useState(authState.isLogged ? true : false);
    const [typeForum, setTypeForum] = useState(props.typeForum ? props.typeForum : 'question');
    const [tags, setTags] = useState([]);
    const [address, setAddress] = useState({
        label: props?.roomData?.address || '',
        value: props?.roomData?.address || '',
    });
    //HOOKS
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        getValues,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            handleChange({ name, value: value[name] });
        });
        return () => subscription.unsubscribe();

        //eslint-disable-next-line
    }, [authState.isLogged, watch]);

    useEffect(() => {
        if (authState?.me) gamificationPoints.current = authState?.me?.gamification?.profile;
    }, [authState.me]);

    const getSuggestedTags = useCallback(
        async (value) => {
            getTags(value).then((res) => {
                if (props?.isEdit) {
                    //remove same tags from roomData.tags
                    let newTags = [];
                    res.forEach((t) => {
                        let isFound = false;
                        props?.roomData?.tags.forEach((rt) => {
                            if (rt.name === t.name) isFound = true;
                        });
                        if (!isFound) newTags.push(t);
                    });
                    setFoundTags(newTags);
                } else setFoundTags(res);
            });
        },
        //eslint-disable-next-line
        []
    );

    function handleFindTags(value) {
        if (value && value.length >= 3) {
            getSuggestedTags(value);
        } else {
            setFoundTags([]);
        }
    }

    function handleAddTag(tag) {
        let newTags = [];

        var res = {};
        foundTags.forEach(function (v) {
            res[v.selected] = (res[v.selected] || 0) + 1;
        });

        foundTags.forEach((t) => {
            if (tag.name === t.name) {
                if (res.false === foundTags.length || res.true < 3) tag.selected = !t.selected;
                else tag.selected = false;
                newTags.push(tag);
            } else {
                newTags.push(t);
            }
        });
        setFoundTags(newTags);

        if (props?.isEdit) {
            foundTags.forEach((t) => {
                let _tags = tags;
                //if tags is not in _tags, add it
                if (!_.find(_tags, { name: t.name })) {
                    if (t.selected) _tags.push(t);
                }
                setTags(_tags);
                setValue('tags', _tags);
            });
        }
    }

    function handleAddSelfTag() {
        const value = getValues('addTags');
        if (value.length >= 3) {
            const newTag = [{ name: value, selected: true }];
            const newTags = _.unionBy(foundTags, newTag, 'name');
            setFoundTags(newTags);
            setValue('addTags', null);
            if (props?.isEdit) {
                let _tags = tags;
                _tags.push(newTag[0]);
                setTags(_tags);
                setValue('tags', _tags);
            }
        }
    }

    useEffect(() => {
        setValue(
            'tags',
            foundTags.filter((t) => t.selected === true)
        );
        //eslint-disable-next-line
    }, [foundTags]);

    useEffect(() => {
        if (props?.isEdit) {
            setOpenModal(true);
        }
        //eslint-disable-next-line
    }, [props?.isEdit]);

    useEffect(() => {
        if (props?.isCreatePost) {
            setOpenModal(true);
        }
        //eslint-disable-next-line
    }, [props?.isCreatePost]);

    useEffect(() => {
        if (props?.isEdit && props?.roomData) {
            setTypeForum(props?.roomData?.type);
            setIsPublic(props?.roomData?.type === 'job' ? true : !props?.roomData?.isPublic);
            setValue('tags', props?.roomData?.tags);
            setTags(props?.roomData?.tags);
            setValue('address', props?.roomData?.address);
            setValue('question', props?.roomData?.question);
            setValue('news', props?.roomData?.news);
            setValue('description', props?.roomData?.description);
            setIsDisabled(false);
        }
        //eslint-disable-next-line
    }, [props?.roomData, props?.isEdit]);

    function handleChangeType(data) {
        setTypeForum(data.target.value);
    }

    function handleChangePlace(latlng, value) {
        setValue('address', value);
        setValue('point', latlng);
        setAddress(value);
    }

    function handleChange(input) {
        if (input.name === 'forum') {
            if (authState.isLogged) {
                if (gamificationPoints.current >= 5) {
                    setValue('question', input.value);
                    setOpenModal(true);
                    document.getElementById('input_question')?.focus();
                } else {
                    setOpenModalNotAllowed(true);
                }
            } else {
                document.getElementById('button-login')?.click();
            }
        }
        if (input.name === 'question' || input.name === 'job') {
            handleFindTags(input.value);
        }
    }

    useEffect(() => {
        if (openModal) {
            setValue('tags', []);
            // setValue('address', '');
        }
        //eslint-disable-next-line
    }, [openModal]);

    const handleSubmitOnClick = useCallback(() => {
        if (isDisabled) return;

        if (errors.length === 0) {
            setIsDisabled(true);
        }

        currentForm.current.click();

        if (props?.isEdit || props?.isCreatePost) {
            setOpenModal(false);
            props?.handleClose();
        }
    }, [errors, isDisabled, props]);

    function handleIsPublic(e) {
        e.preventDefault();

        setValue('isPublic', !isPublic);
        setIsPublic(!isPublic);
    }

    function handleNewsChange(e) {
        setValue('news', e.target.value);
        setValue('description', e.target.value);
    }

    function removeTags(str) {
        if (str === null || str === '') return false;
        else str = str.toString();
        var div = document.createElement('div');
        div.innerHTML = str;
        var text = div.textContent || div.innerText || '';
        return text;
    }

    function onSubmit(data) {
        if (errors.length > 0) return false;

        if (props.userTo) data.userTo = props.userTo;
        if (typeForum === 'job') data.isPublic = true;
        if (typeForum === 'news') data.question = removeTags(data.description);

        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = null;
                item = data[k];
                if (k === 'tags') {
                    if (props?.isEdit) {
                        item = JSON.stringify(tags);
                    } else {
                        item = JSON.stringify(data[k]);
                    }
                }
                if (k === 'medias') {
                    let files = data[k];

                    if (props?.isEdit) {
                        let existingMediasArray = []; // Pour stocker les médias existants

                        for (let i = 0; i < files.length; i++) {
                            if (files[i] instanceof File) {
                                fd.append(k, files[i]);
                            } else {
                                existingMediasArray.push(files[i]); // Ajoutez à l'array au lieu de JSON.stringify ici
                            }
                        }
                        // Convertir le tableau de médias existants en une chaîne JSON et l'ajouter à FormData
                        if (existingMediasArray.length > 0) {
                            fd.append('existingMedias', JSON.stringify(existingMediasArray));
                        }
                    } else {
                        for (let i = 0; i < files.length; i++) {
                            fd.append(k, files[i]);
                        }
                    }
                } else fd.append(k, item);
            }
        }

        if (props?.isEdit) {
            fd.append('id', props?.roomData?.id);

            const put = putForum(fd, authState.isLogged, props?.roomData?.id);
            put.then((res) => {
                if (res.status === 200) {
                    if (props.refetchRooms) props.refetchRooms();
                    toast('Vous avez modifié votre Room !', { type: 'success' });
                    setOpenModal(false);
                    reset();
                    navigate('/room/' + res.data.slug);
                } else {
                    toast('Une erreur est survenue', { type: 'danger' });
                }
            });
            return;
        } else {
            const post = postForum(fd, authState.isLogged);
            post.then(async (res) => {
                if (res.status === 200) {
                    if (props.refetchRooms) props.refetchRooms();
                    toast('Vous avez créé une Room !', { type: 'success' });
                    setOpenModal(false);
                    reset();
                    navigate('/room/' + res.data.slug, { state: { shareModal: true } });
                } else {
                    toast('Une erreur est survenue', { type: 'danger' });
                }
            });
        }
    }

    const buttonUpload = (
        <div className="flex gap-2 text-graySkills my-2">
            <PhotographIcon className="w-6 h-6" />
            <PlayIcon className="w-6 h-6" />
        </div>
    );

    function onFocus() {
        // console.log(inputRef);
        // inputRef.current.focus();
    }

    const handleOpenInscription = useCallback(() => {
        document.getElementById('button-login')?.click();
    }, []);

    function editRemoveTags(name) {
        //remove tag from setValue('tags')
        let _tags = tags;
        _tags = _tags.filter((t) => t.name !== name);
        setValue('tags', _tags);
        setTags(_tags);
    }

    function handleStoryOpen() {
        toast('Cette fonctionnalité arrive bientôt !', { type: 'info' });
        props?.isClose();
    }

    return (
        <>
            <Modal
                open={openModalNotAllowed}
                onConfirm={{
                    label: "J'ai compris",
                    onClick: () => {
                        setValue('forum', '');
                        setOpenModalNotAllowed(false);
                    },
                }}
                onClose={{
                    onClick: () => {
                        setValue('forum', '');
                        setOpenModalNotAllowed(false);
                    },
                }}
            >
                <div className="flex flex-col">
                    <h2 className="font-bold">Vous devez avoir un minimum de 5 points pour pouvoir bénéficier de cette fonctionnalité</h2>
                    <p className="text-sm text-purpleSkills mt-2">Vous pouvez y parvenir en complétant votre profil !</p>
                </div>
            </Modal>
            <Modal
                title={`${props.title === false ? '' : "Posez votre question, offre d'emploi, demande de devis, ..."}`}
                overflow={true}
                open={openModal}
                onConfirm={{
                    label: props?.isEdit ? 'Modifier' : 'Publier',
                    disabled: isDisabled,
                    onClick: () => {
                        if (props.userTo) {
                            setValue('tags', [{ name: 'profil', selected: true }]);
                        }
                        handleSubmitOnClick();
                    },
                }}
                onClose={{
                    onClick: () => {
                        setOpenModal(false);
                        if (props?.isEdit || props?.isCreatePost) {
                            props?.handleClose();
                        }
                    },
                }}
            >
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col">
                        {authState.isLogged && (
                            <>
                                <div className="relative mt-0 mb-6">
                                    <div className="w-full flex justify-between items-end gap-2">
                                        {!props?.isEdit && (
                                            <div className={`${props.isButton ? 'hidden' : ''} w-1/2 text-sm`}>
                                                <label className="text-sm">Votre demande est une :</label>
                                                <FieldSelect
                                                    inputForm="rounded"
                                                    name="typeForum"
                                                    label={false}
                                                    options={[
                                                        { label: 'Question', value: 'question' },
                                                        { label: "Offre d'emploi", value: 'job' },
                                                        { label: 'News', value: 'news' },
                                                        // { label: 'Demande de devis', value: 'devis' },
                                                    ]}
                                                    defaultValue={typeForum}
                                                    onChange={handleChangeType}
                                                    register={register}
                                                    errors={errors}
                                                />
                                            </div>
                                        )}
                                        {typeForum === 'job' && (
                                            <div className="w-1/2 text-sm">
                                                <label className="text-sm">Type de contrat</label>
                                                <FieldSelect
                                                    inputForm="rounded"
                                                    name="job_type_contrat"
                                                    defaultValue={props?.roomData?.jobTypeContrat || 'full'}
                                                    label={false}
                                                    options={[
                                                        { label: 'Temps plein', value: 'full' },
                                                        { label: 'Temps partiel', value: 'partial' },
                                                        { label: 'Contrat', value: 'contrat' },
                                                        { label: 'Travail temporaire', value: 'tmp' },
                                                        { label: 'Bénévolat', value: 'benevolat' },
                                                        { label: 'Stage / Alternance', value: 'stage' },
                                                    ]}
                                                    register={register}
                                                    errors={errors}
                                                />
                                            </div>
                                        )}
                                        {typeForum !== 'job' && (
                                            <div className="w-1/2 h-10 flex gap-2 justify-start">
                                                <Avatar onClick={handleIsPublic} user={authState?.me} className="card_profile_images_cover w-10 h-10" />
                                                <button onClick={handleIsPublic} className="px-4 py-1 text-mainText bg-lightGraySkills rounded-3xl">
                                                    {isPublic ? 'Public' : 'Anonyme'}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {/* <div className="w-full">
                                        <label className="text-sm mt-4 inline-block">Intitulé du poste:</label>
                                        <FieldInput className="px-0 inline-block" name="job_name" label={false} register={register} errors={errors} />
                                    </div> */}
                                </div>
                            </>
                        )}
                        {/* <p className="text-sm">Précisez bien votre besoin pour des réponses de qualité</p> */}
                        {typeForum === 'news' ? (
                            <TextareaCustom
                                label={false}
                                id="textarea_news"
                                placeholder="Publiez votre news ici"
                                name="news"
                                initValue={props?.roomData?.question}
                                onChange={(e) => handleNewsChange(e)}
                                errors={errors}
                                register={register}
                            />
                        ) : (
                            <FieldInput
                                onChange={handleFindTags}
                                id="input_question"
                                className="px-0 inline-block "
                                name={'question'}
                                initValue={props?.roomData?.question}
                                label={false}
                                placeholder={`${typeForum === 'job' ? 'Intitulé du poste' : 'Bien détailler votre besoin pour de meilleures réponses'}`}
                                register={register}
                                errors={errors}
                            />
                        )}
                        {typeForum === 'job' && (
                            <>
                                <div className="mt-2 z-50">
                                    {/* <label className="text-sm">Lieu:</label> */}
                                    <SearchFilterPlace defaultValue={address} refetch={(latlng, value) => handleChangePlace(latlng, value)} />
                                </div>
                                <div className="flex gap-2 mt-2">
                                    <div className="w-1/2 self-center">
                                        <FieldInput
                                            onChange={handleFindTags}
                                            className="px-0 inline-block "
                                            name="job_price"
                                            maxLength={80}
                                            initValue={props?.roomData?.jobPrice}
                                            label={false}
                                            placeholder={`Salaire brut`}
                                            register={register}
                                            errors={errors}
                                        />
                                        {/*
                                        <FieldSelect
                                            inputForm="rounded"
                                            className="px-0 inline-block "
                                            name="job_price"
                                            defaultValue={props?.roomData?.jobPrice || '10k-20k'}
                                            label={false}
                                            options={[
                                                { label: 'Entre 10k et 20k', value: '10k-20k' },
                                                { label: 'Entre 20k et 30k', value: '20k-30k' },
                                                { label: 'Entre 30k et 40k', value: '30k-40k' },
                                                { label: 'Entre 40k et 50k', value: '40k-50k' },
                                                { label: 'Supérieur à 50k', value: '>50k' },
                                            ]}
                                            register={register}
                                            errors={errors}
                                        />
                                        */}
                                    </div>
                                    <div className="w-1/2">
                                        <label className="text-sm">Type du lieu de travail</label>
                                        <FieldSelect
                                            inputForm="rounded"
                                            className="px-0 inline-block "
                                            name="job_typePlace"
                                            defaultValue={props?.roomData?.jobTypePlace || 'presential'}
                                            label={false}
                                            options={[
                                                { label: 'Présentiel', value: 'presential' },
                                                { label: 'Télétravail', value: 'remote' },
                                                { label: 'Présentiel + Télétravail', value: 'presential_remote' },
                                            ]}
                                            register={register}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <TextareaCustom
                                        label={false}
                                        id="textarea_job"
                                        placeholder="Décrivez votre offre d'emploi ici"
                                        name="description"
                                        initValue={props?.roomData?.description}
                                        onChange={(e) => setValue('description', e.target.value)}
                                        errors={errors}
                                        register={register}
                                    />
                                </div>
                            </>
                        )}
                        {props?.isEdit && typeForum !== 'news' && (
                            <>
                                <label className="text-blueMain px-0 mt-4 font-bold">Vos tags</label>
                                {errors && errors['tags'] && <span className={`mt-0 text-sm px-0 text-danger-400`}>{errors['tags'].message}</span>}
                            </>
                        )}
                        {props?.isEdit && typeForum !== 'news' && (
                            <>
                                <div className="flex flex-wrap flex-row gap-1 mt-2 px-0 relative max-h-24 overflow-auto">
                                    {tags.map((tag, index) => (
                                        <button key={index} type="button" title={`${tag.name}`} className={`text-graySkills rounded-lg p-1 text-sm`}>
                                            <span className="flex flex-row items-center px-1 gap-2">
                                                <span onClick={() => handleAddTag(tag)}>
                                                    <>{tag.name}</>
                                                </span>
                                                <span className="h-10 w-10" onClick={(e) => editRemoveTags(tag.name)}>
                                                    <XCircleIcon className="h-6 w-6 block text-red" />
                                                </span>
                                            </span>
                                        </button>
                                    ))}
                                </div>
                            </>
                        )}
                        {typeForum !== 'news' && (
                            <>
                                {props?.isEdit && typeForum === 'question' ? (
                                    <label className="text-blueMain px-0 mt-4 font-bold">Suggestion de tags</label>
                                ) : (
                                    !props?.isEdit && <label className="text-blueMain px-0 mt-4 font-bold">Choisir au moins un tag</label>
                                )}
                                {errors && errors['tags'] && <span className={`mt-0 text-sm px-0 text-danger-400`}>{errors['tags'].message}</span>}
                            </>
                        )}
                        {foundTags.length > 0 && !props.userTo && typeForum !== 'news' && (
                            <>
                                <div className="flex flex-wrap flex-row gap-1 mt-2 px-0 relative max-h-24 overflow-auto">
                                    {foundTags.map((tag, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            title={`${tag.name}`}
                                            className={`${tag.selected ? 'bg-graySkills text-blue-900' : 'text-graySkills'} rounded-lg p-1 text-sm`}
                                        >
                                            <span className="flex flex-row items-center px-1">
                                                <span onClick={() => handleAddTag(tag)}>
                                                    <>{tag.name}</>
                                                </span>
                                            </span>
                                        </button>
                                    ))}
                                </div>
                                {/* <label className="text-blueMain px-0 mt-4 font-bold">Insérer votre propre tag</label> */}
                            </>
                        )}
                        {!props.userTo && typeForum !== 'news' && (
                            <>
                                <div className="flex gap-2 mt-4">
                                    <input
                                        type="text"
                                        {...register('addTags')}
                                        className="bg-lightGraySkills px-3 py-1 rounded-3xl w-7/12 text-sm"
                                        placeholder="Insérer votre propre tag"
                                    />
                                    <button type="button" title="Ajouter" className="linkCard" onClick={handleAddSelfTag}>
                                        Ajouter
                                    </button>
                                </div>
                            </>
                        )}

                        {authState.isLogged && (
                            <div className="w-full mt-4">
                                {typeForum === 'job' && <label className="text-sm">Essayez l'offre d'emploi en vidéo ou illustrez avec une image</label>}
                                {typeForum !== 'job' && <label className="text-sm">Illustrez votre room avec une vidéo ou un image</label>}
                                <FieldFiles
                                    name="medias"
                                    label="Déposez ou cliquez ici pour uploader une image"
                                    type="multiple"
                                    multiple={true}
                                    register={register}
                                    isEdit={props?.isEdit}
                                    oldFiles={props?.roomData?.medias}
                                    initValue={[]}
                                    readyToWatch={true}
                                    onChange={(value) => {
                                        setValue('medias', value);
                                    }}
                                    button={buttonUpload}
                                    accepts={['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'video/mp4', 'video/quicktime']}
                                />
                            </div>
                        )}
                    </div>
                </form>
            </Modal>
            {props.isButton ? (
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    {isMobile() ? (
                        <li
                            className="my-4 w-full flex"
                            onClick={() => {
                                if (props.typeForum !== 'story') setOpenModal(true);
                                else handleStoryOpen();
                                /* handleChangeTypeSelection('job') */
                            }}
                        >
                            <Button
                                label={
                                    (props.typeForum === 'question' && <QuestionMarkCircleIcon className={props.iconClassName} />) ||
                                    (props.typeForum === 'job' && <BriefcaseIcon className={props.iconClassName} />) ||
                                    (props.typeForum === 'news' && <RssIcon className={props.iconClassName} />) ||
                                    (props.typeForum === 'story' && <PlusCircleIcon className={props.iconClassName} />)
                                }
                                justIcon={true}
                                css={props.className}
                            />
                            <span className={props.mobileLabelClassName}>{props.label}</span>
                        </li>
                    ) : (
                        <>
                            <button
                                type="button"
                                className={props.className}
                                onClick={() => {
                                    if (props.typeForum !== 'story') setOpenModal(true);
                                    else handleStoryOpen();
                                    /* handleChangeTypeSelection('job') */
                                }}
                            >
                                {props.typeForum === 'question' && <QuestionMarkCircleIcon className={props.iconClassName} />}
                                {props.typeForum === 'job' && <BriefcaseIcon className={props.iconClassName} />}
                                {props.typeForum === 'news' && <RssIcon className={props.iconClassName} />}
                                {props.typeForum === 'story' && <PlusCircleIcon className={props.iconClassName} />}
                                <span>{props.label}</span>
                            </button>
                            <input ref={currentForm} type="submit" className="hidden" />
                        </>
                    )}
                    <input ref={currentForm} type="submit" className="hidden" />
                </form>
            ) : (
                <div className="backdrop-blur-sm rounded-full -mx-2">
                    <form
                        className={`forumSearchBar ${authState.isLogged && 'flex items-center sm:p-0 gap-2'} px-2 pb-1 z-10`}
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                    >
                        {authState.isLogged && <Avatar user={authState?.me} className="w-10 h-auto" onClick={onFocus} />}
                        <div className="forumSearchBar__suggest w-full">
                            <FieldInput
                                currentRef={inputRef}
                                className={`w-full ${authState.isLogged ? 'pl-5 px-2' : 'pl-12 pr-4'} bg-blueSelect`}
                                inputForm={authState.isLogged ? 'bubble' : 'rounded'}
                                name="forum"
                                label={false}
                                placeholder={`${props.label ?? 'Posez vos questions, vos besoins ici'}`}
                                register={register}
                                errors={errors}
                                option={{}}
                                onFocus={() => {
                                    if (authState.isLogged) {
                                        setOpenModal(true);
                                    } else {
                                        handleOpenInscription();
                                    }
                                }}
                                onClickOption={(input) => {
                                    handleChange({
                                        name: 'forum',
                                        value: input.target.value,
                                    });
                                }}
                            />
                        </div>
                        <input ref={currentForm} type="submit" className="hidden" />
                    </form>
                </div>
            )}
        </>
    );
}

export default ForumSearchBarV2;
