import { useCallback, useContext, useState, useEffect } from 'react';

//COMPONENTS
import Button from 'components/common/Button';
import Reaction from 'components/Reaction';
import LaughIcon from 'components/svgs/Laugh';
import InterestingIcon from 'components/svgs/Interesting';

//HOOKS
import { useGetReactions } from 'hooks/useHasCounter';

//CONTEXT
import { AuthContext } from 'context/authContext';

//ICONS
import { ThumbUpIcon, ThumbDownIcon, HeartIcon } from '@heroicons/react/solid';
import { useHasCurrentReaction } from '../../hooks/useHasCounter';

function CountersReaction(props) {
    //CONTEXT
    const [authState] = useContext(AuthContext);
    /* //USESTATE
    const [reactionIcon, setReactionIcon] = useState(<ThumbUpIcon className={`${props.btnSize ? props.btnSize : 'h-5 w-5 sm:h-7 sm:w-7'}`} />); */
    const currentReaction = useHasCurrentReaction(props.counters, 'reactions', props.to);

    const [selectedReaction, setSelectedReaction] = useState(null);

    const [reactionIcon, setReactionIcon] = useState(<ThumbUpIcon className={`${props.btnSize || 'h-5 w-5 sm:h-7 sm:w-7'}`} />);

    //HOOKS
    const reactionsAnswers = useGetReactions(props.counters, props.to, props.type, 'reactions');

    /* function closeReactionsMenu(params) {
        const els = document.getElementsByClassName('reactionsMenu');

        if (params && params.all === true) {
            [].forEach.call(els, function (el) {
                el.classList.add('hidden');
            });
        } else {
            [].forEach.call(els, function (el) {
                if (el.id !== `reactionsMenu_${props.onModal === true ? `&` + props.to : props.to}`) {
                    el.classList.add('hidden');
                }
            });
        }
    } */
    const closeReactionsMenu = useCallback(
        (params = {}) => {
            const els = document.getElementsByClassName('reactionsMenu');
            Array.from(els).forEach((el) => {
                const shouldBeHidden = params.all || el.id !== `reactionsMenu_${props.onModal ? `&${props.to}` : props.to}`;
                if (shouldBeHidden) {
                    el.classList.add('hidden');
                }
            });
        },
        [props.to, props.onModal]
    );

    const handleReactionsMenu = useCallback(() => {
        closeReactionsMenu();
        document.getElementById(`reactionsMenu_${props.onModal === true ? `&` + props.to : props.to}`).classList.toggle('hidden');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onModal, props.to]);

    const handleOpenInscription = useCallback(() => {
        document.getElementById('button-login')?.click();
    }, []);

    const setReactionToDefaultValues = useCallback(() => {
        setReactionIcon(<ThumbUpIcon className={`${props.btnSize || 'h-5 w-5 sm:h-7 sm:w-7'}`} />);
    }, [props.btnSize]);

    const setReactionIconByValue = useCallback(
        (value) => {
            if (value === selectedReaction) {
                // console.log('value === selectedreaction ');
                setReactionToDefaultValues();
                setSelectedReaction(null);
            } else {
                switch (value) {
                    case 'like':
                        setReactionIcon(<ThumbUpIcon className={`${props.btnSize || 'h-5 w-5 sm:h-7 sm:w-7'} text-purpleSkills`} />);
                        break;
                    case 'likeMuch':
                        setReactionIcon(<HeartIcon className={`${props.btnSize || 'h-5 w-5 sm:h-7 sm:w-7'} text-[#E06C83]`} />);
                        break;
                    case 'mdr':
                        setReactionIcon(
                            <span className={`${props.btnSize || 'h-5 w-5 sm:h-7 sm:w-7'} text-xl text-center`}>
                                <LaughIcon />
                            </span>
                        );
                        break;
                    case 'interesting':
                        setReactionIcon(
                            <span className={`${props.btnSize || 'h-5 w-5 sm:h-7 sm:w-7'} text-xl text-center`}>
                                <InterestingIcon />
                            </span>
                        );
                        break;
                    case 'dislike':
                        setReactionIcon(<ThumbDownIcon className={`${props.btnSize || 'h-5 w-5 sm:h-7 sm:w-7'} text-[#075985]`} />);
                        break;
                    default:
                        setReactionIcon(<ThumbUpIcon className={`${props.btnSize || 'h-5 w-5 sm:h-7 sm:w-7'}`} />);
                        break;
                    /* }
                        setSelectedReaction(value);
                     } */
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.btnSize, selectedReaction]
    );

    useEffect(() => {
        if (currentReaction && currentReaction.value !== selectedReaction) {
            // console.log('currentReaction : ', currentReaction.value);
            setReactionIconByValue(currentReaction.value);
        } else if (!currentReaction) {
            setReactionToDefaultValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentReaction]);

    /* function getUserReaction(counters, to, type, user) {
        if (counters && to && type && user) {
            if (type === 'room') {
                for (let i = 0; i < counters.length; i++) {
                    if (counters[i].to === to && counters[i].type === 'reactions' && counters[i].user.id === user) {
                        setReactionIconByValue(counters[i].value);
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (props.type === 'room') {
            getUserReaction(props.counters, props.to, props.type, authState?.me?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); */

    return (
        <>
            <div className={`relative flex`}>
                <Button
                    onClick={() => {
                        if (!authState.isLogged) {
                            handleOpenInscription();
                        } else {
                            handleReactionsMenu();
                        }
                    }}
                    label={
                        props?.type === 'room' ? (
                            <div className="flex flex-row place-items-center">
                                {reactionIcon}
                                {/*   <span className={`counter-icon_number text-graySkills text-lg font-light`}>{reactionsAnswers.reactionsNb}</span> */}
                            </div>
                        ) : (
                            "j'aime"
                        )
                    }
                    justIcon={true}
                    css={`
                        ${props.cssButton ? props.cssButton : props?.type !== 'room' ? 'text-purpleSkills hover:text-blueMain font-bold text-sm' : ''}
                    `}
                />
                <Reaction
                    type={props?.type}
                    refetch={props.refetch}
                    listingQueryKeys={props.listingQueryKeys}
                    to={props.to}
                    userTo={props.userTo}
                    onModal={props.onModal}
                    counters={props.item?.counters_answers}
                    reactions={reactionsAnswers}
                    onClick={(e, value) => {
                        setSelectedReaction(value);
                        setReactionIconByValue(value);
                        closeReactionsMenu({ all: true });
                    }}
                />
            </div>
        </>
    );
}

export default CountersReaction;
