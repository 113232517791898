import { useContext, useState, useRef } from 'react';
import { useQuery } from 'react-query';

//UTILS
import config from 'utils/constants';

//API
import { postIncrement, getShoot } from 'api/shoots';
import { postCounter } from 'api/counters';

//CONTEXTS
import { SocketContext } from 'context/socketContext';
import { AuthContext } from 'context/authContext';

//HOOKS
import useHasCounter from 'hooks/useHasCounter';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

//CONTAINERS
import CardProfilShoot from 'containers/profils/CardProfilShoot';
import AnswersShootList from 'containers/answers/AnswersShootList';

//ICONS
// eslint-disable-next-line no-unused-vars
import { HeartIcon, EyeIcon, DotsVerticalIcon, BookmarkIcon } from '@heroicons/react/solid';
import OptionsMenu from 'components/common/OptionsMenu';

//COMPONENTS
//import PlayerVideo from 'components/common/PlayerVideo';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import Video from '../../components/common/Video';
import Share from 'components/share/Share';

function CardShoot(props) {
    const [playing, setPlaying] = useState(false);
    const [modalComment, setModalComment] = useState(false);

    const shoot = useQuery(
        ['shoot', props.shoot?.slug],
        (params) => {
            return getShoot(props.shoot?.slug);
        },
        { enabled: props.shoot?.slug ? true : false, staleTime: 0 }
    );

    //HOOKS
    const hasCounter = useHasCounter(shoot?.data?.current?.counters, 'like_shoot', shoot?.data?.current?._id);
    const hasBookmark = useHasCounter(shoot?.data?.current?.counters, 'bookmark_shoot', shoot?.data?.current?._id);

    const likeCounterCount = shoot?.data?.current?.counters?.filter((counter) => counter.type === 'like_shoot').length;
    const bookmarkCounterCount = shoot?.data?.current?.counters?.filter((counter) => counter.type === 'bookmark_shoot').length;

    //CONTEXTS
    const socket = useContext(SocketContext);
    const [authState] = useContext(AuthContext);

    //REFS
    const shootRef = useRef(null);
    const reviewRef = useRef(null);

    function handleSubscribe(shootId, shootData) {
        if (authState.isLogged) {
            let infos = {
                titleShoot: shootData?.title,
                slugShoot: shootData?.slug,
                poster: `/downloads/videos/${shootData?.user?.id}/screenshot_${shootData?.video._id}_${shootData?.selectedScreen}.jpg`,
            };

            postCounter({ userTo: shootData?.user.id, type: 'like_shoot', to: shootId, infos: infos }).then(() => {
                shoot.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    function handleBookmark(shootId) {
        if (authState.isLogged) {
            postCounter({ userTo: shoot?.data?.current?.user.id, type: 'bookmark_shoot', to: shootId }).then(() => {
                shoot.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    function onStart() {
        postIncrement(props.shoot._id);
        if (!props?.isSearch) window.history.replaceState(null, null, `/shoots/${props.shoot.slug}`);
    }

    function closeOptionsMenu(shoot) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${shoot?._id}`) {
                el.classList.add('hidden');
            }
        });
    }

    function handleOptionsMenu(shoot) {
        closeOptionsMenu(shoot);
        document.getElementById(`optionsMenu_${shoot?._id}`).classList.toggle('hidden');
    }

    function handlePostComment() {
        reviewRef.current.openForm();
    }

    useIntersectionObserver({
        target: shootRef,
        onIntersect: (isVisible) => {
            setPlaying(isVisible);
            if (isVisible && !props?.isSearch) {
                window.history.replaceState(null, null, `/shoots/${props.shoot.slug}`);
                let data = {
                    id: authState?.me?.id,
                    location: `/shoots/${props.shoot.slug}`,
                };
                document.title = `${props?.shoot?.user?.profile?.pseudo} - ${props?.shoot?.title}`;
                document.querySelector('meta[name="description"]').setAttribute('content', props?.shoot?.description);
                setTimeout(() => {
                    socket.send('userActivity', data);
                    //every 3s
                }, 1000);
            }
        },
        enabled: true,
    });

    return (
        <>
            <Modal
                title="Commentaires"
                open={modalComment}
                onClose={{ label: 'Fermer', onClick: () => setModalComment(false) }}
                onConfirm={{ label: 'Ecrire un commentaire', onClick: handlePostComment }}
            >
                <AnswersShootList parent={shoot?.data?.current?._id} ref={reviewRef} />
            </Modal>
            <div
                ref={shootRef}
                id={`shoot_${props.shoot._id}`}
                className={`flex flex-col items-center justify-center ${props?.isSearch ? 'mb-8' : 'my-8'} heightWrapPlayer`}
            >
                <div className="relative m-auto w-full">
                    <CardProfilShoot user={props.shoot.user} shoot={props.shoot} />
                    <div className="videoWrapper flex">
                        <div
                            className={`videoContainer bg-black overflow-hidden rounded-lg relative mr-5 h-[65vh] ${
                                props.shoot?.video?.position === 'landscape' ? 'w-full' : 'w-auto max-w-[22rem]'
                            }`}
                        >
                            <Video
                                shoot={props.shoot}
                                /* nextShoot={props.nextShoot} */
                                muted={props.muted}
                                handleMuted={props.handleMuted}
                                playing={playing}
                                onStart={onStart}
                                videoFitCover={false}
                                width={'100%'}
                                poster={`${config.serverUrl}/downloads/videos/${props.shoot?.user?.id}/screenshot_${props.shoot?.video._id}_${props.shoot?.selectedScreen}.jpg`}
                            />
                        </div>

                        {shoot.status === 'success' && shoot.data.current && (
                            <div className="flex flex-col justify-between">
                                <div className="flex flex-col gap-1 items-center relative">
                                    <Button
                                        onClick={() => handleOptionsMenu(shoot?.data?.current)}
                                        label={<DotsVerticalIcon className="w-7 h-7 hover:text-blueMain" />}
                                        justIcon={true}
                                        css="text-graySkills"
                                    />
                                    <OptionsMenu shoot={shoot?.data?.current} user={shoot?.data?.current?.user} refresh={shoot?.refetch} type="shoot" />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button
                                            label={<EyeIcon className="w-6 h-6 mx-auto" />}
                                            justIcon={true}
                                            css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                        />
                                        <span className="text-xs text-graySkills">{shoot?.data?.current?.views}</span>
                                    </div>
                                    <div className="flex flex-col gap-1 items-center">
                                        <Share
                                            className=" bg-white border border-purpleSkills rounded-full"
                                            size="w-12 h-12"
                                            type="outline"
                                            hrefUrl={window.location.href}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button
                                            label={<HeartIcon className="w-6 h-6 mx-auto" />}
                                            justIcon={true}
                                            css={`w-12 h-12 border rounded-full ${
                                                hasCounter ? 'text-white border-red bg-red' : 'text-graySkills border-graySkills bg-white'
                                            }`}
                                            // css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                            onClick={() => handleSubscribe(shoot?.data?.current?._id, shoot?.data?.current)}
                                        />
                                        <span className={`text-graySkills`}>{likeCounterCount}</span>
                                    </div>
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button
                                            label={<BookmarkIcon className="w-6 h-6 mx-auto" />}
                                            justIcon={true}
                                            css={`w-12 h-12 border rounded-full ${
                                                hasBookmark ? 'text-white border-purpleSkills bg-purpleSkills' : 'text-graySkills border-graySkills bg-white'
                                            }`}
                                            onClick={() => handleBookmark(shoot?.data?.current?._id)}
                                        />
                                        <span className={`text-graySkills`}>{bookmarkCounterCount}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardShoot;
