import { useContext, useEffect, useState, useRef, Fragment } from 'react';

//API
import { useQuery } from 'react-query';
import { postComment, getComments } from 'api/comments';
import { postCounter } from 'api/counters';
import { postMessage } from 'api/users';

//STYLES
import 'containers/cards.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//LIBS
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import { isFoundByKey, countByKey } from 'utils/functions';

//COMPONENTS
import Button from 'components/common/Button';
import Image from 'components/common/Image';
import Rating from 'components/Rating';
import Reservation from 'components/visio/Reservation';
import PingConnected from 'components/PingConnected';
import Modal from 'components/common/Modal';
import Nl2br from 'components/common/Nl2br';
import Moment from 'components/common/Moment';

//CONTAINERS
import PointGamification from 'containers/profils/PointGamification';

//ICONS
import { SvgLinkedin, SvgTwitter, SvgInstagram, SvgYoutube, SvgTiktok, SvgSnapchat } from 'components/common/Svg';
import {
    StarIcon,
    BellIcon,
    LocationMarkerIcon,
    UsersIcon,
    CakeIcon,
    PhoneIcon,
    AnnotationIcon,
    GlobeIcon,
} from '@heroicons/react/solid';

const sizes = {
    avatar: '?w=130&h=130&c=true&q=80',
    cover: '?w=300&h=130&c=true&q=80',
};

function SingleCard({ user, refetch }) {
    //CONTEXTS
    //eslint-disable-next-line
    const [authState, setAuthState, refresh] = useContext(AuthContext);
    //REFS
    const refReservation = useRef(null);
    const refMessageComment = useRef(null);
    const refMessageProfil = useRef(null);

    const [modalMessage, setModalMessage] = useState(false);
    const [modalCalendar, setModalCalendar] = useState(false);
    const [modalComment, setModalComment] = useState(false);
    const [messageComment, setMessageComment] = useState('');
    const [rateComment, setRateComment] = useState(5);

    const [messageProfil, setMessageProfil] = useState('');

    //API
    const comments = useQuery(['users', user.id], () => getComments(user.id), {
        exposed: user.id ? true : false,
    });

    useEffect(() => {
        refetch?.();
        // eslint-disable-next-line
    }, []);

    //FUNCTIONS
    function refetchAll() {
        refresh();
        refetch();
    }

    function handleCalendar() {
        if (authState.isLogged) setModalCalendar(true);
        else document.getElementById('button-login')?.click();
    }

    function handleReservation() {
        refReservation.current.submit();
    }

    function handleComment() {
        setMessageComment(
            messageComment.replace(
                /<script(?:(?!\/\/)(?!\/\*)[^'"]|"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\/\/.*(?:\n)|\/\*(?:(?:.|\s))*?\*\/)*?<\/script>/ims,
                ' '
            )
        );
        if (rateComment >= 0 && rateComment <= 5 && messageComment !== '') {
            postComment({
                user: user.id,
                note: rateComment,
                message: messageComment,
            }).then((res) => {
                // console.log(rateComment, messageComment);
                setModalComment(false);
                toast('Votre commentaire a bien été envoyé', {
                    type: 'success',
                });
                comments.refetch();
                refetch();
            });
        }
    }

    function handleMessage() {
        if (messageProfil !== '') {
            postMessage({
                user: user.id,
                message: messageProfil,
            }).then((res) => {
                setModalMessage(false);
                setMessageProfil('');
                toast('Votre message a bien été envoyé', {
                    type: 'success',
                });
            });
        }
    }

    function handleSubscribe() {
        postCounter({ type: 'subscription', to: user.id }).then((res) => {
            refetchAll();
        });
    }

    return (
        <>
            <Modal
                open={modalMessage}
                title={'Ecrire un message'}
                onClose={{
                    onClick: () => {
                        setModalMessage(false);
                        setMessageProfil('');
                    },
                }}
                onConfirm={{
                    label: 'Envoyer',
                    onClick: () => handleMessage(),
                }}
            >
                <textarea
                    ref={refMessageProfil}
                    name="sendMessageProfil"
                    className="input py-4 w-96"
                    rows="4"
                    style={{ height: 'auto' }}
                    placeholder={`Envoyer un message à ${user.profile?.pseudo}`}
                    onChange={() => {
                        setMessageProfil(refMessageProfil.current.value);
                    }}
                    value={messageProfil}
                >
                    {messageProfil}
                </textarea>
            </Modal>
            <Modal
                open={modalCalendar}
                title="Réserver un créneau de visio-consultation"
                onClose={{
                    onClick: () => setModalCalendar(false),
                }}
                onConfirm={{
                    label: 'Réserver',
                    onClick: () => handleReservation(),
                }}
            >
                <Reservation
                    ref={refReservation}
                    user={user}
                    onClose={() => setModalCalendar(false)}
                />
            </Modal>
            <Modal
                open={modalComment}
                title={`Laissez un avis sur ${user.profile?.pseudo}`}
                onClose={{
                    onClick: () => setModalComment(false),
                }}
                onConfirm={{
                    label: 'Valider',
                    onClick: () => handleComment(),
                }}
            >
                <Rating
                    value={rateComment}
                    character={<StarIcon className="h-6 w-6" />}
                    onChange={(value) => setRateComment(value)}
                />
                <textarea
                    ref={refMessageComment}
                    name="messageReservation"
                    className="input py-4"
                    rows="4"
                    style={{ height: 'auto' }}
                    placeholder={`Laissez un avis sur ${user.profile?.pseudo}`}
                    onChange={() => {
                        setMessageComment(refMessageComment.current.value);
                    }}
                    value={messageComment}
                >
                    {messageComment}
                </textarea>
            </Modal>
            <div className="card card_profile w-full mb-4">
                <div className="card_profile_images">
                    <div className="card_profile_images_cover">
                        <Image
                            className="w-full rounded-t-xl"
                            src={user.profile?.cover?.url + sizes.cover}
                            alt={user.name}
                        />
                        <div className="shape"></div>
                    </div>
                </div>
                <div className="px-4 py-2 pt-1 w-full">
                    <div className="card_content">
                        <div className="flex flex-row justify-start w-full m-auto">
                            <div className="m-2 mt-0 pr-2 min-w-max">
                                <Link
                                    to={'/profil/' + user.profile?.slug}
                                    title={`Profil de ${user.profile?.slug}`}
                                >
                                    <div className="relative w-24 h-24 m-auto">
                                        <Image
                                            src={user.profile?.avatar?.url + sizes.avatar}
                                            alt={user.name}
                                            loading="false"
                                            className="w-24 h-24 rounded-full shadow-xl"
                                        />
                                        <PointGamification gamification={user.totalGamification} />
                                        <PingConnected
                                            connexions={user?.connexions}
                                            type="global"
                                        />
                                    </div>
                                </Link>
                            </div>
                            <div className="flex flex-col justify-start text-left">
                                <h3 className="text-sm font-semibold text-blue-800 w-full">
                                    {user.profile?.pseudo}
                                </h3>
                                <p className="text-graySkills text-xs my-1 mb-0">
                                    {user.profile?.status}
                                </p>
                                <div className="p-0 m-0">
                                    <Rating
                                        disabled={true}
                                        value={user.social?.notation}
                                        character={<StarIcon className="h-4 w-4" />}
                                    />
                                </div>
                                {authState.isLogged && user.profile?.address && (
                                    <div className="text-teal-500 font-semibold text-xs my-0">
                                        <p className="flex justify-start">
                                            <span>
                                                <LocationMarkerIcon className="w-3.5 h-3.5 mr-1" />
                                            </span>
                                            <span>
                                                {user.profile?.address.split(',')[1]
                                                    ? user.profile?.address.split(',')[1]
                                                    : user.profile?.address}
                                            </span>
                                        </p>
                                    </div>
                                )}
                                <div className="flex flex-col lg:flex-row gap-2 text-graySkills text-xs my-1">
                                    <p className="flex justify-start">
                                        <span>
                                            <UsersIcon className="w-3.5 h-3.5 mr-1" />
                                        </span>
                                        <span>
                                            {countByKey(user.counters, 'type', 'subscription')}{' '}
                                            abonné(s)
                                        </span>
                                    </p>
                                    <p className="flex justify-start">
                                        <span>
                                            <CakeIcon className="w-3.5 h-3.5 mr-1" />
                                        </span>
                                        Inscrit <Moment date={user.createdAt} fromNow={true} />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-center mt-4 gap-2">
                            {authState.isLogged && authState?.me?.id !== user.id && (
                                <button
                                    onClick={() => handleSubscribe()}
                                    className={`link link_blue flex flex-row gap-1 ${
                                        isFoundByKey(user.counters, 'user', authState?.me?.id) &&
                                        isFoundByKey(user.counters, 'type', 'subscription') &&
                                        authState.isLogged
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    <BellIcon className="w-3 h-3 relative top-0.5" />
                                    <span>
                                        {isFoundByKey(user.counters, 'user', authState?.me?.id) &&
                                        isFoundByKey(user.counters, 'type', 'subscription') &&
                                        authState.isLogged
                                            ? 'abonné'
                                            : "S'abonner"}
                                    </span>
                                </button>
                            )}
                            {authState.isLogged && authState?.me?.id !== user.id && (
                                <button
                                    onClick={() => setModalMessage(true)}
                                    className="link link_blue flex flex-row gap-1"
                                >
                                    <AnnotationIcon className="w-3 h-3 relative top-0.5" />
                                    <span>Message</span>
                                </button>
                            )}
                            {authState.isLogged && user.profile?.phone && (
                                <a
                                    href={'tel:' + user.profile?.phone}
                                    className="link link_blue flex flex-row gap-1"
                                >
                                    <PhoneIcon className="w-3 h-3 relative top-0.5" />
                                    <span>Appeler</span>
                                </a>
                            )}
                        </div>
                        {user.visio.price > 0 && (
                            <>
                                <div className="flex flex-row justify-center mt-4 gap-2">
                                    <p className="text-sm text-blue-800">
                                        Réservez une visio-consultation pour{' '}
                                        <span className="text-blueMain font-bold">
                                            {user.visio.price} €
                                        </span>
                                    </p>
                                </div>
                                <div className="flex flex-row justify-center mt-2">
                                    <Button
                                        label={'Je réserve un créneau'}
                                        css="button-small button-secondary"
                                        onClick={() => handleCalendar()}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    {user.profile?.video && (
                        <div className="pt-4">
                            <div className="flex flex-col justify-start">
                                <h3 className="text-graySkills uppercase">
                                    Ma vidéo de présentation
                                </h3>
                                <div
                                    className="py-2 aspect-video relative mt-1"
                                    style={{
                                        paddingTop: '56.25%',
                                    }}
                                >
                                    <ReactPlayer
                                        url={user.profile?.video}
                                        width="100%"
                                        height="100%"
                                        origin={window.location.origin}
                                        className="absolute top-0 left-0 w-full h-full rounded-lg overflow-hidden aspect-video"
                                        pip
                                        controls
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {user.profile?.presentation && (
                        <div className="pt-4">
                            <div className="flex flex-col justify-start">
                                <h3 className="text-graySkills uppercase">Ma biographie</h3>
                                <div className="content py-2 text-sm">
                                    {parse(user.profile?.presentation)}
                                </div>
                            </div>
                        </div>
                    )}
                    {(user.profile?.linkLinkedin ||
                        user.profile?.linkTwitter ||
                        user.profile?.linkInstagram ||
                        user.profile?.linkSnapchat ||
                        user.profile?.linkTiktok ||
                        user.profile?.linkYoutube) && (
                        <div className="py-2">
                            <div className="flex flex-row justify-between">
                                <h3 className="text-graySkills uppercase">Mes réseaux</h3>
                                <div className="flex flex-row gap-1">
                                    {user.profile?.linkWebsite && (
                                        <a
                                            href={user.profile?.linkWebsite}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <GlobeIcon className="w-6 h-6 text-white bg-gray-400 rounded hover:bg-blueMain transition" />
                                        </a>
                                    )}
                                    {user.profile?.linkLinkedin && (
                                        <a
                                            href={user.profile?.linkLinkedin}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <SvgLinkedin className="text-graySkills hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.profile?.linkTwitter && (
                                        <a
                                            href={user.profile?.linkTwitter}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <SvgTwitter className="text-graySkills hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.profile?.linkInstagram && (
                                        <a
                                            href={user.profile?.linkInstagram}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <SvgInstagram className="text-graySkills hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.profile?.linkYoutube && (
                                        <a
                                            href={user.profile?.linkYoutube}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <SvgYoutube className="text-graySkills hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.profile?.linkTiktok && (
                                        <a
                                            href={user.profile?.linkTiktok}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <SvgTiktok className="text-graySkills hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.profile?.linkSnapchat && (
                                        <a
                                            href={user.profile?.linkSnapchat}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <SvgSnapchat className="text-graySkills hover:text-blueMain transition" />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="pt-2">
                        <div className="flex flex-row justify-between">
                            <h3 className="text-graySkills uppercase">Avis</h3>
                            {authState.isLogged && authState?.me?.id !== user.id && (
                                <div className="py-2">
                                    <button
                                        onClick={() => setModalComment(true)}
                                        className="link flex flex-row gap-1"
                                    >
                                        <AnnotationIcon className="w-3 h-3 relative top-0.5" />
                                        <span>Laisser un avis</span>
                                    </button>
                                </div>
                            )}
                        </div>
                        {comments.isSuccess && (
                            <div className="mb-2">
                                <Slider
                                    infinite
                                    autoplay
                                    arrows={false}
                                    autoplaySpeed={5000}
                                    adaptiveHeight
                                    speed={800}
                                    slidesToShow={1}
                                    slidesToScroll={1}
                                >
                                    {comments.data.map((comment, index) => (
                                        <div
                                            key={index}
                                            className="px-2 py-1 pb-2 border border-gray-100 rounded-lg"
                                        >
                                            <div className="flex flex-col justify-start gap-2">
                                                <div className="mr-2 mb-2">
                                                    <div className="flex flex-row items-center">
                                                        <Image
                                                            src={
                                                                comment.userFrom?.profile?.avatar
                                                                    ?.url + sizes.avatar
                                                            }
                                                            loading="false"
                                                            className="w-10 h-10 rounded-full shadow-xl mr-2"
                                                        />
                                                        <div className="flex flex-col gap-1">
                                                            <span className="text-sm text-blue-800 font-bold pt-2">
                                                                {comment.userFrom?.profile?.pseudo}
                                                            </span>
                                                            <Rating
                                                                className="relative -top-2"
                                                                disabled={true}
                                                                value={comment.note}
                                                                character={
                                                                    <StarIcon className="h-3.5 w-3.5" />
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-sm text-blue-800">
                                                    <Nl2br str={comment.message} />
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className="flex flex-row justify-center">
                                    {comments.data.length <= 0 && (
                                        <p className="text-sm text-blue-800">
                                            Aucun avis pour le moment
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {user.profile?.tags && (
                        <div className="py-2">
                            <div className="flex flex-col justify-start">
                                <h3 className="text-graySkills uppercase">Mes skills</h3>
                                <div className="flex flex-row flex-wrap gap-2 mt-2">
                                    {user.profile?.tags.map((skill, index) => (
                                        <Link
                                            to={`/search?q=${skill.name}`}
                                            key={index}
                                            className={`link link_blue flex flex-row gap-1 ${
                                                skill.selected && 'active'
                                            }`}
                                        >
                                            {skill.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default SingleCard;
