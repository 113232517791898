import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
//LIBS
import _ from 'lodash';
//API
//import { getNotifications } from 'api/discussions';
import { getMatchingClone, getCloneByMe, toggleAiRound } from 'api/clone';
import { getConversationsClone, getOpportunities } from 'api/cloneDiscussions';
//COMPONENTS
import TabsMessagerie from '../../components/tabs/TabsMessagerie';
import CardProfilClone from '../discussions/CardProfilClone';
import BuyCloneAi from 'components/common/BuyCloneAi';
import FormAiTrainSteps from '../../components/forms/formAiSteps/aiTrainSteps';
//CONTEXTS
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
import { SocketContext } from 'context/socketContext';
import { CloneContext } from 'context/cloneContext';
import { StarIcon } from '@heroicons/react/solid';
//CONTAINERS
//import CardDiscussion from 'containers/discussions/CardDiscussion';
//HOOKS
//import useNotifications from 'hooks/useNotifications';

function ListMessages(props) {
    //const socket = useContext(SocketContext);
    const [authState] = useContext(AuthContext);

    const store = useContext(StoreContext);
    const socket = useContext(SocketContext);
    const [cloneState, cloneDispatch] = useContext(CloneContext);

    const navigate = useNavigate();

    //const discussionNotifications = useNotifications(getNotifications);
    const [cloneId, setCloneId] = useState(null);
    const [openAiTrain, setOpenAiTrain] = useState(false);
    const [isAlreadyPremium, setIsAlreadyPremium] = useState(false);
    const [isPlanOpen, setIsPlanOpen] = useState(false);

    const clone = useQuery(['clone'], getCloneByMe, { enabled: authState.isLogged });

    const cloneConversations = useQuery(['cloneConversations'], getConversationsClone, { enabled: true });
    const myOpportunities = useQuery(['opportunities'], getOpportunities, { enabled: true });

    useEffect(() => {
        if (authState.isLogged && clone.data) {
            setCloneId(clone.data.id);
            //if user as plan premium
            if (authState?.me?.plans && authState?.me?.plans.length > 0) {
                if (authState?.me?.plans.some((plan) => plan.type === 'chatAi' && plan.status === 'succeeded')) {
                    setIsAlreadyPremium(true);
                }
            }
        } else {
            setCloneId(false);
        }
    }, [clone.data, authState]);

    useEffect(() => {
        store.set({ type: 'add', key: 'discussionType', value: 'ai' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleCloneOpenDiscussion(e, cloneId, type) {
        if (e) e.preventDefault();
        e?.stopPropagation();
        store.set({ type: 'add', key: 'openChat', value: { open: true, cloneId: cloneId, chatType: 'ai', type: type } });
    }

    function createCloneDiscussion(cloneFrom, type) {
        const clones = [clone.data?.id, cloneFrom];
        const discussionId = _.join(clones, '.');
        socket.send('createCloneDiscussion', { id: discussionId, userId: authState.me?.id, type: type });
    }

    const handleGoToCloneInscription = useCallback(() => {
        if (authState.me) {
            navigate(`/inscription-clone-ai`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, navigate]);

    function handleAiTrain() {
        setOpenAiTrain(true);
    }

    useEffect(() => {
        //console.log("test")
        socket.on('startCloneCreationProcess', () => {
            cloneDispatch({ type: 'setCloneCreationState', cloneCreationState: true });
        });
        socket.on('cloneCreated', (msg) => {
            cloneDispatch({ type: 'setCloneCreationState', cloneCreationState: false });
            toast('Votre clone a été créée', { type: 'success' });
            clone.refetch();
        });

        socket.on('cloneExists', (msg) => {
            cloneDispatch({ type: 'setCloneCreationState', cloneCreationState: false });
            toast('Votre clone existe déjà', { type: 'error' });
            clone.refetch();
        });

        socket.on('errorCloneCreated', (msg) => {
            cloneDispatch({ type: 'setCloneCreationState', cloneCreationState: false });
            toast('Une erreur est survenue', { type: 'error' });
        });

        // Assurez-vous de vous désinscrire des événements lorsque le composant est démonté
        return () => {
            socket.off('startCloneCreationProcess');
            socket.off('cloneCreated');
            socket.off('cloneExists');
            socket.off('error');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const handleMatch = async () => {
        try {
            const responseMatching = await getMatchingClone();
            console.log(responseMatching);
            switch (responseMatching.status) {
                case 200:
                    //console.log('Received matching data', responseMatching.data);
                    // Mettez à jour votre UI ici avec les données correspondantes
                    responseMatching.data.forEach((clone, index) => {
                        if (clone.id !== cloneId) {
                            //createCloneDiscussion(clone.id, false)
                            createCloneDiscussion(clone.id, 'cloneConversation');
                            //openCloneDiscussion(clone.id, "cloneConversation", false)
                        }
                    });

                    //AI ROUND CLONE ENABLE
                    const responseToggle = await toggleAiRound();

                    if (responseToggle.status === 200) {
                        clone.refetch();
                    } else {
                        console.error('Failed to change AI Round state', responseToggle);
                    }

                    break;
                case 400:
                    console.error('Content cannot be empty');
                    break;
                case 401:
                    console.error('No users or clones found or User not found', responseMatching.error);
                    break;
                case 500:
                    console.error('An error occurred while retrieving the chat', responseMatching.message);
                    break;
                default:
                    console.error('Unexpected status code', responseMatching);
                    break;
            }
        } catch (error) {
            console.log(error);
            console.error('An error occurred while changing AI Round state or fetching matching clones', error);
        }
    };

    const cloneDialogue = (id, userId) => {
        socket.send('aiRoundLaunch', { id: id, userId: userId });
    };

    const tabMatchs = (
        <div className="discussions_list flex flex-col w-full px-0 h-full overflow-y-auto">
            {cloneId && (
                <>
                    {cloneConversations.isSuccess && cloneConversations.data?.length !== 0 ? (
                        <div className="grow overflow-hidden pb-12">
                            <div className="discussions_list w-full px-0 overflow-auto">
                                {cloneConversations.data?.map((conversation, index) => (
                                    <div key={index} className={`text-white py-2 border-b-[1px] border-white`}>
                                        <div className="px-2 flex items-center justify-between">
                                            <CardProfilClone
                                                discussionId={conversation.discussionId}
                                                user={conversation.user}
                                                clone={conversation.clone}
                                                clone2={conversation.clone2}
                                                onClick={(e) => handleCloneOpenDiscussion(e, conversation.clone2.id, 'cloneConversation')}
                                            />
                                            {conversation.isAiRound && (
                                                <button
                                                    className="w-36 p-2 bg-black font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]"
                                                    onClick={(e) => {
                                                        handleCloneOpenDiscussion(e, conversation.clone2.id, 'cloneConversation');
                                                        cloneDialogue(conversation.discussionId, conversation.user.id);
                                                    }}
                                                >
                                                    AI Round
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <>
                            {clone?.data?.isAiRoundEnabled !== true && (
                                <div className="grow justify-center flex flex-col text-center p-2 ">
                                    <div className="text-white font-extrabold text-3xl">
                                        <h1 className="drop-shadow-2xl">Activez "AI Round"</h1>
                                    </div>
                                    <div className="flex flex-col text-white">
                                        <span>Laissez votre clone négocier pour</span>
                                        <span>vous 24/7 auprès des clones de</span>
                                        <span>vos cibles</span>
                                    </div>
                                    <div className="w-full flex justify-center py-2">
                                        <button
                                            className={`w-80 border-y-2 border-x-8 border-[#BB7EFF] bg-black hover:bg-[#b235fe] button font-bold text-white text-3xl py-3 rounded-full`}
                                            onClick={() => {
                                                handleMatch();
                                            }}
                                        >
                                            Activer
                                        </button>
                                    </div>
                                    <div className="mt-4">
                                        <button className="text-white font-bold" role="link" onClick={() => openInNewTab('https://clone.ai.skillsmarket.fr/')}>
                                            En savoir plus
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            {cloneId === false && (
                <>
                    {cloneState.cloneCreationState !== true ? (
                        <>
                            <div className="w-full flex justify-center py-2 mt-12">
                                <button
                                    className={`w-80 border-y-2 border-x-8 border-[#BB7EFF] bg-black hover:bg-[#b235fe] button font-bold text-white text-3xl py-3 rounded-full`}
                                    onClick={() => handleGoToCloneInscription()}
                                >
                                    Créer mon clone
                                </button>
                            </div>
                            <div className="text-white text-center mt-6">
                                <span>
                                    Découvrez la puissance de votre<br></br>double numérique à votre service, qui<br></br>négocie et défend vos intérêts pour
                                    <br></br>atteindre vos objectifs 24/7
                                </span>
                                {/* <span>vous 24/7 auprès des clones de</span>
                                <span>vos cibles</span> */}
                            </div>
                            <div className="mt-4 text-center">
                                <button className="text-white font-bold" role="link" onClick={() => openInNewTab('https://clone.ai.skillsmarket.fr/')}>
                                    En savoir plus
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className={`hover:bg-black text-white py-2 border-b font-bold text-xl border-white cursor-pointer`}>
                            <div className="px-2">
                                <span>Nous fabriquons votre Clone AI...</span>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );

    const tabOpportunities = (
        <div className="discussions_list flex flex-col w-full px-0 h-full overflow-y-auto">
            {myOpportunities.isSuccess && myOpportunities.data?.length !== 0 ? (
                <div className="grow overflow-hidden pb-12">
                    <div className="discussions_list w-full px-0 overflow-auto">
                        {myOpportunities.data?.map((opportunity, index) => (
                            <div key={index} className={`text-white py-2 border-b border-white`}>
                                <div className="px-2 flex items-center justify-between">
                                    <CardProfilClone
                                        discussionId={`${opportunity.clone2}.${opportunity.clone}.cloneOpportunities`}
                                        /* notifications={notifications} */
                                        user={opportunity.userOffensif}
                                        onClick={(e) => {
                                            handleCloneOpenDiscussion(e, opportunity.clone2, 'cloneOpportunities');
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="flex flex-col justify-center text-center p-2 h-full pb-12">
                    <div className="text-white font-extrabold text-3xl">
                        <h1>
                            Vous n'avez pas encore<br></br>reçu d'opportunités
                        </h1>
                    </div>
                    <div className="text-white  mt-4">
                        <span>
                            Votre clone défend vos intérêts, filtre<br></br>les demandes et ne vous présente<br></br>ici que les meilleures opportunités
                            <br></br>négociés pour vous.
                        </span>
                    </div>
                </div>
            )}
        </div>
    );

    const labelTabs = (label) => {
        return (
            <>
                <span>{label}</span>
                {/* {label === 'Matchs' && discussionNotifications && (
                    <span className="flex justify-center items-center absolute top-1 right-4 sm:right-1/4 bg-red h-5 w-5 rounded-full text-white text-xs">
                        {discussionNotifications}
                    </span>
                )}    
                {label === 'Opportunités reçues' && discussionNotifications && (
                    <span className="flex justify-center items-center absolute top-1 right-4 sm:right-1/4 bg-red h-5 w-5 rounded-full text-white text-xs">
                        {discussionNotifications}
                    </span>
                )}     */}
            </>
        );
    };

    const tabs = [
        { label: labelTabs('Matchs'), key: 'tab_matchs', content: tabMatchs },
        { label: labelTabs('Opportunités reçues'), key: 'tab_opportunities', content: tabOpportunities },
    ];

    return (
        <>
            <FormAiTrainSteps openAiTrain={openAiTrain} setOpenAiTrain={setOpenAiTrain} />
            {isPlanOpen && <BuyCloneAi state={isPlanOpen} setState={setIsPlanOpen} />}
            <div className="">
                <TabsMessagerie
                    type="dashboard"
                    tabs={tabs}
                    cloneId={cloneId}
                    clone={clone}
                    handleCloneOpenDiscussion={handleCloneOpenDiscussion}
                    handleAiTrain={handleAiTrain}
                />
            </div>
            <div className="sm:hidden inline-block sticky w-full bottom-16 z-10 cursor-pointer">
                {cloneId && (
                    <div className="">
                        <div className="bg-black text-white px-1 py-1 flex items-center justify-between rounded-full">
                            <CardProfilClone
                                discussionId={`${authState.me?.id}.${cloneId}`}
                                user={authState.me}
                                clone={clone.data}
                                onClick={(e) => handleCloneOpenDiscussion(e, cloneId, 'cloneDiscussion')}
                            />
                            <button
                                className="w-36 p-2 bg-black font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleAiTrain();
                                }}
                            >
                                AI Train
                            </button>
                        </div>
                        <div className="text-center font-bold mt-2">
                            <button
                                className="text-gold-500 inline-flex gap-2"
                                onClick={() => {
                                    if (isAlreadyPremium) {
                                        navigate('/dashboard/params/account');
                                    } else {
                                        setIsPlanOpen(true);
                                    }
                                }}
                            >
                                <StarIcon className="w-5 h-5 mx-auto self-center" />
                                {isAlreadyPremium ? <span>Clone AI Infinite</span> : <span>Passez sur Clone AI Infinite</span>}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default ListMessages;
