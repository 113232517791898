function uniqUserTags(users) {
    const allTags = [];
    users?.map((user) => {
        return user.profile?.tags?.map((tag, i) => {
            if (i <= 1 && tag?.name) allTags.push(tag.name);
            return allTags;
        });
    });

    return [...new Set(allTags)];
}

function uniqEntityTags(entities) {
    const allTags = [];
    entities.map((entity) => {
        return entity.tags?.map((tag) => allTags.push(tag.name));
    });

    return [...new Set(allTags)];
}

function isFoundByKey(arr, key, search) {
    if (!arr) return false;
    return arr.find((i) => {
        return i && key in i && i[key] === search;
    })
        ? true
        : false;
}

function countByKey(arr, key, search) {
    let count = 0;
    if (!arr) return count;

    arr.forEach((i) => {
        if (i.deleted === true) return; // Ne pas compter si "deleted" est vrai
        if (search !== undefined) {
            if (i[key] === search) count++;
        } else if (i[key]) count++;
    });
    return count;
}

function countAnswers(arr) {
    let count = 0;
    if (arr === undefined || !Array.isArray(arr) || arr?.length <= 0) return count;

    arr.forEach((a) => {
        if (a.deleted === true) return; // Ne pas compter si "deleted" est vrai
        count++;
        count += countByKey(a.answers, 'answer');
    });
    return count;
}

function hasTag(tags, search) {
    // console.log("search", search);
    const has = tags.find((tag) => tag.name === search.name && search.selected === true);
    return has ? true : false;
}

function hasTagInArray(tags, arraySearch) {
    let has = false;
    if (!tags.length && !arraySearch.length) return false;
    // console.log(tags.length, arraySearch);
    arraySearch.forEach((search) => {
        if (hasTag(tags, search)) has = true;
        // console.log(has);
    });

    return has;
}

function uuid4() {
    const ho = (n, p) => n.toString(16).padStart(p, 0); /// Return the hexadecimal text representation of number `n`, padded with zeroes to be of length `p`
    const data = crypto.getRandomValues(new Uint8Array(16)); /// Fill the buffer with random data
    data[6] = (data[6] & 0xf) | 0x40; /// Patch the 6th byte to reflect a version 4 UUID
    data[8] = (data[8] & 0x3f) | 0x80; /// Patch the 8th byte to reflect a variant 1 UUID (version 4 UUIDs are)
    const view = new DataView(data.buffer); /// Create a view backed by a 16-byte buffer
    return `${ho(view.getUint32(0), 8)}-${ho(view.getUint16(4), 4)}-${ho(view.getUint16(6), 4)}-${ho(view.getUint16(8), 4)}-${ho(view.getUint32(10), 8)}${ho(
        view.getUint16(14),
        4
    )}`; /// Compile the canonical textual form from the array data
}

function autoresize(textarea) {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
    textarea.scrollTop = textarea.scrollHeight;
    /*  textarea.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
    }); */
    // window.scrollTo(window.scrollLeft, (textarea.scrollTop + textarea.scrollHeight));
}

function has(str, search) {
    let exp = new RegExp(`(?:${search})([a-zA-Z0-9]*)`, 'gim');
    return exp.test(str) ? true : false;
}

function convertUrl(text, link_attributes_obj = {}) {
    //link_attributes_obj (optional) object {target:'_blank', class:'myLink'}

    //html to text
    text = text.replace(/</g, '&lt;');
    text = text.replace(/>/g, '&gt;');

    let attr = [];
    for (let k in link_attributes_obj) {
        if (k !== 'href') {
            attr.push(k + '="' + link_attributes_obj[k].replaceAll(/"/g, "'") + '"');
        }
    }

    //URLs starting with http://, https://, or ftp://
    //eslint-disable-next-line
    let exp = /((?:https?|ftp):\/\/[a-zA-Z0-9][\w+\d+&@\-#\/%?=~_|!:,.;+]*)/gim;
    text = text.replace(exp, '<a target="_blank" classname="text-blue-400" href="$1" ' + attr.join(' ') + '>$1</a>');

    //eslint-disable-next-line
    let exp2 = /(?:#)([a-zA-Z0-9]*)/gim;
    text = text.replace(exp2, '<a classname="text-blue-400" href="/search?&q=$1" ' + attr.join(' ') + '>#$1</a>');

    //eslint-disable-next-line
    let exp3 = /(?:@)([-a-zA-Z0-9]*)/gim;
    text = text.replace(exp3, '<a classname="text-blue-400" href="/profil/$1" title="Voir le profil" ' + attr.join(' ') + '>@$1</a>');

    //URLs starting with www.
    //eslint-disable-next-line
    // let reg_ww = /(?<!\/)(www\.[a-zA-Z0-9][\w+\d+&@\-#\/%?=~_|!:,.;+]*)/gim;
    // text = text.replace(reg_ww, '<a classname="text-blue-400" href="https://$1" ' + attr.join(' ') + '>$1</a>');

    return text;
}

function countObj(array) {
    let mediasLength = 0;

    if (array?.length > 0) mediasLength = array.length;
    return mediasLength;
    // if ((answerValue !== undefined && answerValue.length > 0) || (mediasValue !== undefined && mediasValue.length > 0)) {
    //     if (mediasValue !== undefined && mediasValue.length > 0) {
    //         setCountMedias(mediasValue.length);
    //     } else {
    //         setCountMedias(0);
    //     }

    //     setIsAnswerEmpty(false);
    // } else {
    //     if (mediasValue !== undefined && mediasValue.length === 0) {
    //         setCountMedias(0);
    //     }
    //     setIsAnswerEmpty(true);
    // }
}

function isMobile() {
    const toMatch = [/Android/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

function isSmartphone() {
    const toMatch = [/Android/i, /iPhone/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

function formatNumber(num) {
    if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(1) + 'K';
    } else if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000000000) {
        return (num / 1000000).toFixed(1) + 'G';
    } else if (num >= 1000000000000) {
        return (num / 1000000).toFixed(1) + 'T';
    }
    return num;
}

function isImage(data) {
    if (data.startsWith('image/')) return true;
    else return false;
}

function isVideo(data) {
    if (data.startsWith('video/')) return true;
    else return false;
}

function cutString(string, length) {
    if (string?.length > length) {
        return string.substring(0, length) + '...';
    } else {
        return string;
    }
}

function formatTitle(string) {
    if (string?.length > 50) {
        let newString = string.substring(0, 50);
        let lastSpace = newString.lastIndexOf(' ');
        return newString.substring(0, lastSpace) + '...';
    } else {
        return string;
    }

}

export {
    uniqUserTags,
    uniqEntityTags,
    formatNumber,
    isFoundByKey,
    countByKey,
    countAnswers,
    hasTag,
    hasTagInArray,
    uuid4,
    autoresize,
    has,
    convertUrl,
    countObj,
    isMobile,
    isSmartphone,
    isImage,
    isVideo,
    cutString,
    formatTitle,
};
